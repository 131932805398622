enum SendoutsTypes {
    START_GET_SEND_OUTS_ADMIN = 'START_GET_SEND_OUTS_ADMIN',
    SUCCESS_GET_SEND_OUTS_ADMIN = 'SUCCESS_GET_SEND_OUTS_ADMIN',
    FINISH_GET_SEND_OUTS_ADMIN = 'FINISH_GET_SEND_OUTS_ADMIN',
    ERROR_GET_SEND_OUTS_ADMIN = 'ERROR_GET_SEND_OUTS_ADMIN',

    START_MAKE_AS_SEND = 'START_MAKE_AS_SEND',
    SUCCESS_MAKE_AS_SEND = 'SUCCESS_MAKE_AS_SEND',
    FINISH_MAKE_AS_SEND = 'FINISH_MAKE_AS_SEND',
    ERROR_MAKE_AS_SEND = 'ERROR_MAKE_AS_SEND',
}
export default SendoutsTypes
