import { alpha, InputBaseComponentProps, TextField, TextFieldProps, useTheme } from '@mui/material'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

type StripeElement = typeof CardCvcElement | typeof CardExpiryElement | typeof CardNumberElement

interface StripeTextFieldProps<T extends StripeElement> extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
    inputProps?: React.ComponentProps<T>
    labelErrorMessage?: string
    onChange?: React.ComponentProps<T>['onChange']
    stripeElement?: T
}

export function StripeTextFieldNumber(props: StripeTextFieldProps<typeof CardNumberElement>) {
    return <StripeTextField label="Card Number" stripeElement={CardNumberElement} {...props} />
}

export function StripeTextFieldExpiry(props: StripeTextFieldProps<typeof CardExpiryElement>) {
    return <StripeTextField label="Expiration date" stripeElement={CardExpiryElement} {...props} />
}

export function StripeTextFieldCVC(props: StripeTextFieldProps<typeof CardCvcElement>) {
    return <StripeTextField label="CVV security code" stripeElement={CardCvcElement} {...props} />
}

const StripeTextField = <T extends StripeElement>(props: StripeTextFieldProps<T>) => {
    const { helperText, InputLabelProps, InputProps = {}, inputProps, error, labelErrorMessage, stripeElement, ...other } = props

    return (
        <TextField
            InputLabelProps={{
                ...InputLabelProps,
                shrink: true,
            }}
            error={error}
            InputProps={{
                ...InputProps,
                inputProps: {
                    ...inputProps,
                    ...InputProps.inputProps,
                    component: stripeElement,
                },
                inputComponent: StripeInput,
            }}
            helperText={error ? labelErrorMessage : helperText}
            {...(other as any)}
        />
    )
}

const StripeInput = forwardRef<any, InputBaseComponentProps>((props, ref) => {
    const { component: Component, options, ...other } = props
    const theme = useTheme()
    const [mountNode, setMountNode] = useState<any | null>(null)

    useImperativeHandle(
        ref,
        () => ({
            focus: () => mountNode.focus(),
        }),
        [mountNode]
    )

    return (
        <Component
            onReady={setMountNode}
            options={{
                ...options,
                style: {
                    base: {
                        color: theme.palette.text.primary,
                        fontSize: '16px',
                        lineHeight: '1.4375em',
                        fontFamily: theme.typography.fontFamily,
                        '::placeholder': {
                            color: alpha(theme.palette.text.primary, 0.42),
                        },
                    },
                    invalid: {
                        color: theme.palette.text.primary,
                    },
                },
            }}
            {...other}
        />
    )
})

//STRIPE PAYLOAD REFERENCE

//paymentIntent: {
//     amount: 2420
//     canceled_at: null
//     cancellation_reason: null
//     capture_method: "automatic"
//     client_secret: "pi_3JnJBhKs7yucCwQ02sJ4coiK_secret_6hDnN5y7reEsf7iGQpEX4imi6"
//     confirmation_method: "automatic"
//     created: 1634891597
//     currency: "eur"
//     description: null
//     id: "pi_3JnJBhKs7yucCwQ02sJ4coiK"
//     last_payment_error: null
//     livemode: false
//     next_action: null
//     object: "payment_intent"
//     payment_method: "pm_1JnJBzKs7yucCwQ0jlpzIvZX"
//     payment_method_types: ['card']
//     receipt_email: null
//     setup_future_usage: null
//     shipping: null
//     source: null
//     status: "succeeded"
// }

// error:
//     code: "payment_intent_unexpected_state"
//     doc_url: "https://stripe.com/docs/error-codes/payment-intent-unexpected-state"
//     message: "A processing error occurred."
//     payment_intent: {id: 'pi_3JnJDdKs7yucCwQ02RgZxrOi', object: 'payment_intent', amount: 2420, canceled_at: null, cancellation_reason: null, …}
//     type: "invalid_request_error"
