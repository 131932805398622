import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import UtilityBillsTypes from './utilityBills-types'

export interface IReducer {
    type: UtilityBillsTypes
    utilityBills?: IUtilityBills
    utilityBillTypes?: Array<IUtilityBillType>
}

interface IState {
    utilityBills?: IUtilityBills
    utilityBillTypes?: Array<IUtilityBillType>
    loading: { GET_UTILITY_BILLS: Boolean; GET_UTILITY_BILLS_TYPES: Boolean }
    error?: { GET_UTILITY_BILLS?: Boolean; GET_UTILITY_BILLS_TYPES?: Boolean }
}

const initialState = {
    utilityBills: { items: [], count: 0 },
    utilityBillTypes: [],
    loading: { GET_UTILITY_BILLS: false, GET_UTILITY_BILLS_TYPES: false },
    error: { GET_UTILITY_BILLS: false, GET_UTILITY_BILLS_TYPES: false },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case UtilityBillsTypes.START_GET_UTILITY_BILLS:
            return {
                ...state,
                loading: { ...state.loading, GET_UTILITY_BILLS: true },
            }
        case UtilityBillsTypes.SUCCESS_GET_UTILITY_BILLS:
            return {
                ...state,
                utilityBills: action.utilityBills,
                loading: { ...state.loading, GET_UTILITY_BILLS: false },
            }
        case UtilityBillsTypes.ERROR_GET_UTILITY_BILLS:
            return {
                ...state,
                loading: { ...state.loading, GET_UTILITY_BILLS: false },
                error: { ...state.error, GET_UTILITY_BILLS: true },
            }
        case UtilityBillsTypes.FINISH_GET_UTILITY_BILLS:
            return {
                ...state,
                utilityBills: { items: [], count: 0 },
                loading: { ...state.loading, GET_UTILITY_BILLS: false },
                error: { ...state.error, GET_UTILITY_BILLS: false },
            }

        case UtilityBillsTypes.START_GET_UTILITY_BILLS_TYPES:
            return {
                ...state,
                loading: { ...state.loading, GET_UTILITY_BILLS_TYPES: true },
            }
        case UtilityBillsTypes.SUCCESS_GET_UTILITY_BILLS_TYPES:
            return {
                ...state,
                utilityBillTypes: action.utilityBillTypes,
                loading: { ...state.loading, GET_UTILITY_BILLS_TYPES: false },
            }
        case UtilityBillsTypes.ERROR_GET_UTILITY_BILLS_TYPES:
            return {
                ...state,
                loading: { ...state.loading, GET_UTILITY_BILLS_TYPES: false },
                error: { ...state.error, GET_UTILITY_BILLS_TYPES: true },
            }
        case UtilityBillsTypes.FINISH_GET_UTILITY_BILLS_TYPES:
            return {
                ...state,
                loading: { ...state.loading, GET_UTILITY_BILLS_TYPES: false },
                error: { ...state.error, GET_UTILITY_BILLS_TYPES: false },
            }
        default:
            return state
    }
}

export const UtilityBillsTypesContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const UtilityBillsTypesContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <UtilityBillsTypesContext.Provider value={[state, dispatch]}>{props.children}</UtilityBillsTypesContext.Provider>
}
