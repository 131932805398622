import styled from 'styled-components'

interface IRoot {
    content?: 'center' | 'left' | 'right'
    align?: 'start' | 'end' | 'center'
    mContent?: 'center' | 'left' | 'right'
    mAlign?: 'start' | 'end' | 'center'
}

export const Root = styled.div<IRoot>`
    display: flex;
    justify-content: ${({ content }) => content || 'left'};
    align-items: ${({ align }) => align || 'start'};
    flex-direction: column;

    @media ${({ theme }) => theme.breakpoints.max.md} {
        justify-content: ${({ content, mContent }) => mContent || content || 'left'};
        align-items: ${({ align, mAlign }) => mAlign || align || 'start'};
    }
`
