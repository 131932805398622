import React, { FC } from 'react'
import { Text } from 'components/shared'
import { Gutter } from 'styles'
import { camelToText } from 'utils'
import { Root, TableHeader, TableBody, TableData } from './styles'

interface IProps {
    title: string
    items: Array<IItem>
}

const TableWithHorizontalDisplay: FC<IProps> = props => {
    return (
        <Root>
            <TableHeader>
                <Text el={'h4'} align="center">
                    {props.title}
                </Text>
            </TableHeader>
            <TableBody>
                {props.items.map(({ name, value }, i) => (
                    <TableData key={i}>
                        <Gutter content="space-between" key={`horizontalDisplay-${i}`}>
                            <Text el="h4">{camelToText(name)}</Text>
                            <Text el="subtitle">{value}</Text>
                        </Gutter>
                    </TableData>
                ))}
            </TableBody>
        </Root>
    )
}

export default TableWithHorizontalDisplay
