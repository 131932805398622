import React, { FC } from 'react'
import { Root } from './styles'

interface IProps {
    color?: string
}

const Spinner: FC<IProps> = props => {
    return <Root colorprops={props.color}></Root>
}

export default Spinner
