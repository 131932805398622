import { Table, Text } from 'components/shared'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from 'styles'
import { Content } from '../Content'
import { Details } from '../Details'
import { parseTableData } from './VirtualAddress-state'

interface IProps extends IUserSubscription {}

const VirtualAddress: FC<IProps> = props => {
    const { t } = useTranslation()
    const tableData = useMemo(() => parseTableData(props.linkedServices), [props])

    const tableItems = {
        data: tableData,
    }

    return (
        <div>
            <Space bottom={30} />
            <Text el="h2">{props.subscriptionName}</Text>
            <Space bottom={20} />
            <Content {...props} />
            <Space bottom={30} />
            <Details {...props} />
            <Space bottom={30} />
            <Text el="h3">{t('virtualAddress.linkedServices')}</Text>
            <Space bottom={20} />
            <Table items={tableItems} />
            <Space bottom={50} />
        </div>
    )
}

export default VirtualAddress
