import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import UtilityBillsTypes from './utilityBills-types'

export interface IReducer {
    utilityBills?: IUtilityBillsAdmin
    type: UtilityBillsTypes
}

export interface IState {
    utilityBills?: IUtilityBillsAdmin
    loading: { GET_UTILITY_BILLS: boolean }
    error?: { GET_UTILITY_BILLS: boolean }
}

const initialState = {
    utilityBills: { count: 0, items: [] },
    loading: { GET_UTILITY_BILLS: false },
    error: { GET_UTILITY_BILLS: false },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case UtilityBillsTypes.START_GET_UTILITY_BILLS:
            return {
                ...state,
                loading: { ...state.loading, GET_UTILITY_BILLS: true },
            }
        case UtilityBillsTypes.SUCCESS_GET_UTILITY_BILLS:
            return {
                ...state,
                utilityBills: action.utilityBills,
                loading: { ...state.loading, GET_UTILITY_BILLS: false },
            }
        case UtilityBillsTypes.FINISH_GET_UTILITY_BILLS:
            return {
                ...state,
                utilityBills: { count: 0, items: [] },
                loading: { ...state.loading, GET_UTILITY_BILLS: false },
                error: { ...state.error, GET_UTILITY_BILLS: false },
            }
        case UtilityBillsTypes.ERROR_GET_UTILITY_BILLS:
            return {
                ...state,
                loading: { ...state.loading, GET_UTILITY_BILLS: false },
                error: { ...state.error, GET_UTILITY_BILLS: true },
            }
        default:
            return state
    }
}

export const AdminUtilityBillsContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const AdminUtilityBillsContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <AdminUtilityBillsContext.Provider value={[state, dispatch]}>{props.children}</AdminUtilityBillsContext.Provider>
}
