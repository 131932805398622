import PaymentsTypes from './payments-types'

export const startGetPayments = () => ({
    type: PaymentsTypes.START_GET_PAYMENTS,
})
export const successGetPayments = (data: IPayments) => ({
    type: PaymentsTypes.SUCCESS_GET_PAYMENTS,
    payments: data,
})
export const errorGetPayments = () => ({
    type: PaymentsTypes.ERROR_GET_PAYMENTS,
})
export const finishGetPayments = () => ({
    type: PaymentsTypes.FINISH_GET_PAYMENTS,
})

export const startGetPaymentsStatuses = () => ({
    type: PaymentsTypes.START_GET_PAYMENTS_STATUSES,
})
export const successGetPaymentsStatuses = (data: Array<IPaymentStatus>) => ({
    type: PaymentsTypes.SUCCESS_GET_PAYMENTS_STATUSES,
    paymentsStatuses: data,
})
export const errorGetPaymentsStatuses = () => ({
    type: PaymentsTypes.ERROR_GET_PAYMENTS_STATUSES,
})
export const finishGetPaymentsStatuses = () => ({
    type: PaymentsTypes.FINISH_GET_PAYMENTS_STATUSES,
})

export const startGetPaymentsBalance = () => ({
    type: PaymentsTypes.START_GET_PAYMENTS_BALANCE,
})
export const successGetPaymentsBalance = (data: Array<IPaymentBalance>) => ({
    type: PaymentsTypes.SUCCESS_GET_PAYMENTS_BALANCE,
    paymentsBalances: data,
})
export const errorGetPaymentsBalance = () => ({
    type: PaymentsTypes.ERROR_GET_PAYMENTS_BALANCE,
})
export const finishGetPaymentsBalance = () => ({
    type: PaymentsTypes.FINISH_GET_PAYMENTS_BALANCE,
})

export const startGetPaymentsClientSecret = () => ({
    type: PaymentsTypes.START_GET_PAYMENTS_CLIENT_SECRET,
})
export const successGetPaymentsClientSecret = (data: IPaymentClientSecret) => ({
    type: PaymentsTypes.SUCCESS_GET_PAYMENTS_CLIENT_SECRET,
    clientSecret: data,
})
export const errorGetPaymentsClientSecret = (errors: Array<IError>) => ({
    type: PaymentsTypes.ERROR_GET_PAYMENTS_CLIENT_SECRET,
    errors,
})
export const finishGetPaymentsClientSecret = () => ({
    type: PaymentsTypes.FINISH_GET_PAYMENTS_CLIENT_SECRET,
})

export const startCancelPayment = () => ({
    type: PaymentsTypes.START_CANCEL_PAYMENT,
})
export const successCancelPayment = (id?: string) => ({
    type: PaymentsTypes.SUCCESS_CANCEL_PAYMENT,
    id,
})
export const errorCancelPayment = (errors: Array<IError>) => ({
    type: PaymentsTypes.FINISH_CANCEL_PAYMENT,
    errors,
})
export const finishCancelPayment = () => ({
    type: PaymentsTypes.ERROR_CANCEL_PAYMENT,
})
