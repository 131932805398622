enum SubscriptionsTypes {
    START_SET_SUBSCRIPTIONS_ID = 'START_SET_SUBSCRIPTIONS_ID',
    SUCCESS_SET_SUBSCRIPTIONS_ID = 'SUCCESS_SET_SUBSCRIPTIONS_ID',
    FINISH_SET_SUBSCRIPTIONS_ID = 'FINISH_SET_SUBSCRIPTIONS_ID',
    ERROR_SET_SUBSCRIPTIONS_ID = 'ERROR_SET_SUBSCRIPTIONS_ID',

    START_GET_SUBSCRIPTIONS = 'START_GET_SUBSCRIPTIONS',
    SUCCESS_GET_SUBSCRIPTIONS = 'SUCCESS_GET_SUBSCRIPTIONS',
    FINISH_GET_SUBSCRIPTIONS = 'FINISH_GET_SUBSCRIPTIONS',
    ERROR_GET_SUBSCRIPTIONS = 'ERROR_GET_SUBSCRIPTIONS',

    START_INCREASE_CORRESPONDENCE_COUNT = 'START_INCREASE_CORRESPONDENCE_COUNT',
    SUCCESS_INCREASE_CORRESPONDENCE_COUNT = 'SUCCESS_INCREASE_CORRESPONDENCE_COUNT',
    FINISH_INCREASE_CORRESPONDENCE_COUNT = 'FINISH_INCREASE_CORRESPONDENCE_COUNT',
    ERROR_INCREASE_CORRESPONDENCE_COUNT = 'ERROR_INCREASE_CORRESPONDENCE_COUNT',
}
export default SubscriptionsTypes
