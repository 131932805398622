import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import { parseAddress, parseAddressWithSubscriptionId } from './virtualAddresses-state'
import VirtualAddressesTypes from './virtualAddresses-types'

export interface IReducer {
    type: VirtualAddressesTypes
    subscriptions?: Array<IUserSubscription>
    loading?: boolean
    error?: boolean
}

export interface IState {
    subscriptions?: Array<IUserSubscription>
    addresses?: Array<IAddress>
    addressedWithSubscriptionId?: Array<IAddress>
    loading: boolean
    error?: boolean
}

const initialState = {
    subscriptions: [],
    addresses: [],
    addressedWithSubscriptionId: [],
    loading: false,
    error: false,
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case VirtualAddressesTypes.START_GET_SUBSCRIPTIONS:
            return {
                ...state,
                loading: true,
            }
        case VirtualAddressesTypes.SUCCESS_GET_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.subscriptions,
                addresses: parseAddress(action.subscriptions || []),
                addressedWithSubscriptionId: parseAddressWithSubscriptionId(action.subscriptions || []),
                loading: false,
            }
        case VirtualAddressesTypes.ERROR_GET_SUBSCRIPTIONS:
            return {
                ...state,
                loading: false,
                error: true,
            }
        case VirtualAddressesTypes.FINISH_GET_SUBSCRIPTIONS:
            return {
                ...state,
                loading: false,
                error: false,
                subscriptions: [],
                addresses: [],
                addressedWithSubscriptionId: [],
            }
        default:
            return state
    }
}

export const VirtualAddressesContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const VirtualAddressesContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <VirtualAddressesContext.Provider value={[state, dispatch]}>{props.children}</VirtualAddressesContext.Provider>
}
