import styled from 'styled-components'

interface IRoot {
    height: number
    width: number
}

export const Root = styled.div<IRoot>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${({ height }) => height || 0}px;
    min-width: ${({ width }) => width || 0}px;
`
