import { Card, Image, Text } from 'components/shared'
import React, { FC } from 'react'
import { Gutter, Space } from 'styles'

import homeCircleImg from 'assets/images/houseCircleGrey.svg'

interface IProps {
    durationData: ISubscription
}

const DurationChoose: FC<IProps> = props => {
    return (
        <Card border maxWidth={233} height={117} content={'center'} padding={15}>
            <Gutter size={10} align={'center'} equalChildSize>
                <Image height={32} width={32} image={homeCircleImg} />
                <Text el={'h4'}> {`Duration: ${props.durationData.duration} months`} </Text>
            </Gutter>
            <Space bottom={10} />
            <Text el={'subtitle'}>{`Total address price: ${props.durationData.price} Additional fees may apply.`}</Text>
        </Card>
    )
}

export default DurationChoose
