import styled, { css } from 'styled-components'

interface IRoot {
    height: number
    width: number
    minHeight?: number
    minWidth?: number
}

export const Root = styled.div<IRoot>`
    max-height: ${props => props.height}px;
    max-width: ${props => props.width}px;

    ${({ minWidth }) =>
        !!minWidth &&
        css`
            min-width: ${minWidth}px;
        `}
    ${({ minHeight }) =>
        !!minHeight &&
        css`
            min-height: ${minHeight}px;
        `}
`

interface IImage {}

export const Image = styled.img<IImage>`
    height: 100%;
    width: 100%;
`
