import { Dispatch, SetStateAction } from 'react'
import * as adminCorrespondences from 'reducers/admin/correspondences'
import { CorrespondencesAdmin, UserSubscriptionsAdmin, FilesAdmin } from 'services'
import * as download from 'reducers/download'
import { localStorageHelper } from 'utils'

export const getCorrespondences = async (dispatch: Dispatch<adminCorrespondences.IReducer>, id?: string, query?: string) => {
    try {
        dispatch(adminCorrespondences.startGetCorrespondences())
        const { data }: { data: ICorrespondencesAdmin } = await UserSubscriptionsAdmin.correspondences(id, query)
        dispatch(adminCorrespondences.successGetCorrespondences(data))
    } catch (e) {
        dispatch(adminCorrespondences.errorGetCorrespondences())
    }
}

export const editCorrespondence = async (dispatch: Dispatch<adminCorrespondences.IReducer>, data: FormData, id: string) => {
    const subscriptionId = localStorageHelper('subscriptionId')

    try {
        dispatch(adminCorrespondences.startEditCorrespondences())
        const res = await CorrespondencesAdmin.editCorrespondence(id, data)
        if (res?.status === 204) {
            dispatch(adminCorrespondences.successEditCorrespondences())
            subscriptionId && getCorrespondences(dispatch, subscriptionId)
        }
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data?.errors
        dispatch(adminCorrespondences.errorEditCorrespondences(errors))
    }
}
export const deleteCorrespondence = async (dispatch: Dispatch<adminCorrespondences.IReducer>, id: string) => {
    const subscriptionId = localStorageHelper('subscriptionId')

    try {
        dispatch(adminCorrespondences.startDeleteCorrespondences())
        const res = await CorrespondencesAdmin.deleteCorrespondence(id)
        if (res?.status === 204) {
            dispatch(adminCorrespondences.successDeleteCorrespondences())
            subscriptionId && getCorrespondences(dispatch, subscriptionId)
        }
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data?.errors
        dispatch(adminCorrespondences.errorDeleteCorrespondences(errors))
    }
}

export const setUserFiles = async (dispatch: Dispatch<download.IReducer>, fileItems: Array<IFile> | undefined = [], setCorrespondences: Dispatch<SetStateAction<ICorrespondencesState>>) => {
    const files: Array<IBlob> = []
    try {
        dispatch(download.startFileDownload())
        for (const file of fileItems) {
            const { data } = await FilesAdmin.download(file.id)
            files.push(data)
        }
        setCorrespondences(prevValues => ({ ...prevValues, files }))
        dispatch(download.successFileDownload())
    } catch (e) {
        dispatch(download.errorFileDownload())
    }
}

export const initialState = (item: ITableDataBody | null) => {
    return {
        senderName: item?.firstTableData?.top ? item?.firstTableData?.top : '',
        type: item?.type ? item?.type : '',
        status: item?.status ? item?.status : '',
        files: [],
    }
}
