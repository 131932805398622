interface Pairs {
    name: string
    value: string | IBlob
}

class MyFormData {
    formData: FormData

    constructor() {
        this.formData = new FormData()
    }

    append(name: string, value: string | IBlob) {
        this.formData.append(name, value)
    }

    appendArray(name: string, values: Array<string> | Array<IBlob>) {
        if (values.length) {
            values.forEach(value => this.formData.append(name, value))
        }
    }

    appendArrayOfObject(values: Array<Pairs>) {
        values.forEach(({ name, value }: Pairs) => {
            this.formData.append(name, value)
        })
    }
}

export default MyFormData
