import { useState, useMemo } from 'react'

function useHover(initState: boolean = false) {
    const [isHovered, changeHoveredState] = useState<boolean>(initState)

    const hoverEvents = useMemo(
        () => ({
            onMouseEnter: () => changeHoveredState(true),
            onMouseLeave: () => changeHoveredState(false),
        }),
        []
    )

    return { isHovered, hoverEvents }
}

export default useHover
