import styled from 'styled-components'

interface IProps {
    width: number
    height: number
    color?: string
}

export const Root = styled.div<IProps>`
    background-color: ${props => props.color || props.theme.palette.primary.main};
    min-width: ${props => props.width}px;
    min-height: ${props => props.height}px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50%;
    color: ${props => props.theme.palette.primary.white};
`
