import { Dispatch } from 'react'
import * as countries from 'reducers/countries'
import * as sendouts from 'reducers/sendouts'
import { Countries, SendOuts } from 'services'
import documentImg from 'assets/images/documentCircle.svg'
import { dateAndTimeToLocaleString } from 'utils'

export const getCountriesList = async (dispatch: Dispatch<countries.IReducer>) => {
    try {
        dispatch(countries.startGetCountries())
        const { data }: { data: Array<ICountry> } = await Countries.list()
        dispatch(countries.successGetCountries(data))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(countries.errorGetCountries(errors))
    }
}

export const getSendOuts = async (dispatch: Dispatch<sendouts.IReducer>, query?: string) => {
    try {
        dispatch(sendouts.startGetSendouts())
        const { data }: { data: ISendouts } = await SendOuts.getSendouts(query)
        dispatch(sendouts.successGetSendouts(data))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(sendouts.errorGetSendouts(errors))
    }
}

export const getSendOutStatuses = async (dispatch: Dispatch<sendouts.IReducer>) => {
    try {
        dispatch(sendouts.startGetSendoutStatuses())
        const { data }: { data: ISendoutStatuses } = await SendOuts.getSendoutStatuses()
        dispatch(sendouts.successGetSendoutStatuses(data))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(sendouts.errorGetSendoutStatuses(errors))
    }
}

export const cancelSendOut = async (dispatch: Dispatch<sendouts.IReducer>, id?: string) => {
    try {
        dispatch(sendouts.startCancelSendout())
        await SendOuts.cancelSendOut(id)
        dispatch(sendouts.successCancelSendout(id))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(sendouts.errorGetSendoutStatuses(errors))
    }
}

export const parseSendouts = (data: Array<ISendout> = [], header: Array<string>): ITableData => {
    return {
        header,
        body: body(data),
    }
}

const body = (data: Array<ISendout> = []): Array<ITableDataBody> => {
    return data.reduce((acc, cur) => {
        return [
            ...acc,
            {
                id: cur.id,
                originalData: cur,
                files: cur.files,
                firstTableData: {
                    image: documentImg,
                    top: cur.displayName,
                    bottom: dateAndTimeToLocaleString(cur?.createdAt),
                },
                mainData: [cur?.receiverName, `${cur?.address.street} ${cur?.address.countryCode} ${cur?.address.city} ${cur?.address.countryCode}`],
                lastTableData: [
                    { type: 'status', value: cur.status },
                    { type: 'buttonWithColorClick', value: 'Cancel send out', hide: cur.status !== 'Pending' },
                ],
                status: cur.status,
            },
        ]
    }, [] as Array<ITableDataBody<ISendout>>)
}
