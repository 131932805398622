import axios, { AxiosResponse } from 'axios'
import { localStorageHelper } from 'utils'
import './interceptor'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorageHelper<ISignInResponse>('session').accessToken}`

const responseBody = (response: AxiosResponse) => response

const config = {
    validateStatus: function (status: number) {
        return status >= 200 && status < 300
    },
}

const requests = {
    get: (url: string) => axios.get(url, config).then(responseBody),
    blobGet: (url: string) => axios.get(url, { responseType: 'blob', ...config }).then(responseBody),
    post: (url: string, body?: {}) => axios.post(url, body, config).then(responseBody),
    blobPost: (url: string, body?: {}) => axios.post(url, body, { responseType: 'blob', ...config }).then(responseBody),
    put: (url: string, body?: {}) => axios.put(url, body, config).then(responseBody),
    del: (url: string) => axios.delete(url, config).then(responseBody),
}

export const User = {
    signUp: (user: ISignUpUser) => requests.post('/v1/users/signUp', user),
    signIn: (token: ISingInToken) => requests.post('/v1/users/signIn', token),
    magicLinks: (user: ISignInUser) => requests.post('/v1/users/magicLinks', user),
    refresh: (token: IRefreshToken) => requests.post('/v1/users/refresh', token),
    signOut: () => requests.post('/v1/users/signOut'),
}

export const UserSubscriptions = {
    subscriptions: () => requests.get('/v1/user/subscriptions'),
    clientSecret: (id: ISubscriptionId) => requests.post('/v1/user/subscriptions', id),
}

export const UtilityBills = {
    utilityBills: (query?: string) => requests.get(`/v1/user/utilityBills${!!query ? query : ''}`),
    utilityBillTypes: () => requests.get('/v1/user/utilityBills/types'),
}

export const SendOuts = {
    addSendout: (data: FormData) => requests.post(`/v1/user/sendouts`, data),
    getSendouts: (query?: string) => requests.get(`/v1/user/sendouts${!!query ? query : ''}`),
    getSendoutStatuses: () => requests.get(`/v1/user/sendouts/statuses`),
    getSendoutsFee: ({ userSubscriptionId, countryCode }: ISendOutFeePathInputs) => requests.get(`/v1/user/userSubscription/${userSubscriptionId}/sendouts/${countryCode}/price`),
    cancelSendOut: (id?: string) => requests.post(`/v1/user/sendouts/${id}/cancel`),
}

export const Files = {
    download: (id: string) => requests.blobPost(`/v1/user/files/${id}/download`),
    info: (id: string) => requests.get(`/v1/user/files/${id}/info`),
}

export const FilesAdmin = {
    download: (id: string) => requests.blobGet(`/v1/admin/files/${id}/download`),
}

export const Countries = {
    list: () => requests.get(`/v1/countries`),
}

export const Correspondences = {
    correspondences: (query?: string) => requests.get(`/v1/user/correspondences${!!query ? query : ''}`),
    correspondencesStatuses: () => requests.get('/v1/user/correspondences/statuses'),
    correspondencesTypes: () => requests.get('/v1/user/correspondences/types'),
}

export const Subscriptions = {
    availableSubscriptions: () => requests.get('/v1/subscriptions/available'),
}

export const AddressesAdmin = {
    addresses: (query?: string) => requests.get(`/v1/admin/addresses${!!query ? query : ''}`),
}

export const UserSubscriptionsAdmin = {
    userSubscriptions: (id?: string) => requests.get(`/v1/admin/userSubscriptions/${!!id ? id : ''}`),
    correspondences: (id?: string, query?: string) => requests.get(`/v1/admin/userSubscriptions/${!!id ? id : ''}/correspondences${!!query ? query : ''}`),
    utilityBills: (id?: string, query?: string) => requests.get(`/v1/admin/userSubscriptions/${!!id ? id : ''}/utilityBills${!!query ? query : ''}`),
    sendOuts: (id?: string, query?: string) => requests.get(`/v1/admin/userSubscriptions/${!!id ? id : ''}/sendOuts${!!query ? query : ''}`),
    payments: (id?: string, query?: string) => requests.get(`/v1/admin/userSubscriptions/${!!id ? id : ''}/payments${!!query ? query : ''}`),
}

export const SendOutsAdmin = {
    sendSendout: (id: string, data: ISendOutAdmin) => requests.put(`/v1/admin/sendOuts/${id}`, data),
}

export const CorrespondencesAdmin = {
    uploadCorrespondence: (data: FormData) => requests.post('/v1/admin/correspondences', data),
    editCorrespondence: (id: string, data: FormData) => requests.put(`/v1/admin/correspondences/${id}`, data),
    deleteCorrespondence: (id: string) => requests.del(`/v1/admin/correspondences/${id}`),
}

export const Payments = {
    getPayments: (query?: string) => requests.get(`/v1/user/payments${!!query ? query : ''}`),
    getPaymentsStatuses: () => requests.get(`/v1/user/payments/statuses`),
    getPaymentsBalances: () => requests.get(`/v1/user/payments/balance`),
    getPaymentsClientSecret: (data?: IPaymentIds) => requests.post(`/v1/user/payments`, { paymentIds: data }),
    cancelPayment: (id?: string) => requests.post(`/v1/user/payments/${id}/cancel`),
}
