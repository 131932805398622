import { FC } from 'react'
import { Root, Image as ImageStl } from './styles'

interface IProps {
    height: number
    width: number
    minHeight?: number
    minWidth?: number
    image?: string
}

const Image: FC<IProps> = props => {
    return (
        <Root height={props.height} width={props.width} minHeight={props.minHeight} minWidth={props.minWidth}>
            <ImageStl src={props.image} />
        </Root>
    )
}

export default Image
