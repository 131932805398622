import styled, { css } from 'styled-components'

export const Root = styled.div`
 
    background-color: ${({theme}) => theme.palette.primary.white};
    margin: 0 -15px;
    padding: 30px 30px;
`


interface INavLink {
    selected: boolean
}

export const NavLink = styled.div<INavLink>`
    color: ${({theme}) => theme.palette.primary.secondary};
    font-weight: 600;
    cursor: pointer;
    padding: 10px 0;

    ${(props) => props.selected && css`
        color: ${({theme}) => theme.palette.primary.main};
        font-weight: 700;
    `}

`