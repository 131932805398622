import { FC } from 'react'

import { Header, Footer, Progress } from 'components/shared'
import { PageContainer } from 'styles'
import { Root } from './styles'

interface IProps {
    color?: string
    page?: string
    loading?: boolean
}

const HeaderFooter: FC<IProps> = props => {
    return (
        <>
            {props.loading && <Progress />}
            <PageContainer color={props.color}>
                <Header page={props.page} color={props.color} />
                <Root>{props.children}</Root>
                <Footer color={props.color} />
            </PageContainer>
        </>
    )
}

export default HeaderFooter
