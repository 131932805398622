import React, { FC } from 'react'
import { VirtualAddress } from './VirtualAddress'

interface IProps {
    subscriptions?: Array<IUserSubscription>
}

const VirtualAddresses: FC<IProps> = props => {
    return (
        <>
            {props.subscriptions?.map((subscription: IUserSubscription, i: number) => (
                <VirtualAddress key={i} {...subscription} />
            ))}
        </>
    )
}

export default VirtualAddresses
