import React, { FC } from 'react'
import { Gutter, Space } from 'styles'
import { Root } from './styles'
import { Image, Text } from 'components/shared'

interface IProps {
    mainTitle: string
    firstSubtitle: string
    secondSubtitle: string
    image: string
    imgWidth: number
    imgHeight: number
}

const Details: FC<IProps> = props => {
    return (
        <Root>
            <Gutter content="space-between" align="center">
                <div>
                    <Text el={'h4'}>{props.mainTitle}</Text>
                    <Space bottom={15} />
                    <Text el={'subtitle'}>{props.firstSubtitle}</Text>
                    <Text el={'subtitle'}>{props.secondSubtitle}</Text>
                </div>
                <Image width={props.imgWidth} height={props.imgHeight} image={props.image} />
            </Gutter>
        </Root>
    )
}

export default Details
