import styled from 'styled-components'

export const Root = styled.div`
    background-color: ${props => props.theme.palette.secondary.box};
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 8px;

    @media ${({ theme }) => theme.breakpoints.max.md} {
        flex-direction: column;
        flex: 1;
    }
`
