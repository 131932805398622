import styled, { css } from 'styled-components'

interface IProps {
    cardShadow?: boolean
    cardPadding?: number
}

export const Root = styled.div<IProps>`
    width: 100%;
    background-color: ${({ theme }) => theme.palette.primary.white};
    border-radius: 8px;
    box-sizing: border-box;

    ${({ cardPadding }) =>
        !!cardPadding &&
        css`
            padding: 0 ${cardPadding}px;
        `}

    ${({ cardShadow }) =>
        cardShadow &&
        css`
            box-shadow: 0px 0px 8px 0px #9a9a9a1b;
        `}

    @media ${props => props.theme.breakpoints.max.md} {
        padding: 0 15px;
    }
`
