import SendoutsTypes from './sendouts-types'

export const startAddSendouts = () => ({
    type: SendoutsTypes.START_ADD_SENDOUT,
})
export const successAddSendouts = () => ({
    type: SendoutsTypes.SUCCESS_ADD_SENDOUT,
})
export const errorAddSendouts = (errors: Array<IError>) => ({
    type: SendoutsTypes.ERROR_ADD_SENDOUT,
    errors,
})
export const finishAddSendouts = () => ({
    type: SendoutsTypes.FINISH_ADD_SENDOUT,
})

export const startGetSendouts = () => ({
    type: SendoutsTypes.START_GET_SENDOUTS,
})
export const successGetSendouts = (sendouts: ISendouts) => ({
    type: SendoutsTypes.SUCCESS_GET_SENDOUTS,
    sendouts,
})
export const errorGetSendouts = (errors: Array<IError>) => ({
    type: SendoutsTypes.FINISH_GET_SENDOUTS,
    errors,
})
export const finishGetSendouts = () => ({
    type: SendoutsTypes.ERROR_GET_SENDOUTS,
})

export const startGetSendoutsFee = () => ({
    type: SendoutsTypes.START_GET_SENDOUTS_FEE,
})
export const successGetSendoutsFee = (fee: ISendOutsFee) => ({
    type: SendoutsTypes.SUCCESS_GET_SENDOUTS_FEE,
    fee,
})
export const errorGetSendoutsFee = (errors: Array<IError>) => ({
    type: SendoutsTypes.ERROR_GET_SENDOUTS_FEE,
    errors,
})
export const finishGetSendoutsFee = () => ({
    type: SendoutsTypes.FINISH_GET_SENDOUTS_FEE,
})

export const startGetSendoutStatuses = () => ({
    type: SendoutsTypes.START_GET_SENDOUT_STATUSES,
})
export const successGetSendoutStatuses = (statuses: ISendoutStatuses) => ({
    type: SendoutsTypes.SUCCESS_GET_SENDOUT_STATUSES,
    statuses,
})
export const errorGetSendoutStatuses = (errors: Array<IError>) => ({
    type: SendoutsTypes.ERROR_GET_SENDOUT_STATUSES,
    errors,
})
export const finishGetSendoutStatuses = () => ({
    type: SendoutsTypes.FINISH_GET_SENDOUT_STATUSES,
})

export const startCancelSendout = () => ({
    type: SendoutsTypes.START_CANCEL_SENDOUT,
})
export const successCancelSendout = (id?: string) => ({
    type: SendoutsTypes.SUCCESS_CANCEL_SENDOUT,
    id,
})
export const errorCancelSendout = (errors: Array<IError>) => ({
    type: SendoutsTypes.ERROR_CANCEL_SENDOUT,
    errors,
})
export const finishCancelSendout = () => ({
    type: SendoutsTypes.FINISH_CANCEL_SENDOUT,
})
