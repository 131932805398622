import CountriesTypes from './countries-types'

export const startGetCountries = () => ({
    type: CountriesTypes.START_GET_COUNTRIES,
})
export const successGetCountries = (countries: Array<ICountry>) => ({
    type: CountriesTypes.SUCCESS_GET_COUNTRIES,
    countries,
})
export const errorGetCountries = (errors: Array<IError>) => ({
    type: CountriesTypes.FINISH_GET_COUNTRIES,
    errors,
})
export const finishGetCountries = () => ({
    type: CountriesTypes.ERROR_GET_COUNTRIES,
})
