import styled from 'styled-components'

export const Root = styled.td`
    display: flex;
    align-items: center;
    column-gap: 15px;
`
export const New = styled.div`
    height: 18px;
    width: 37px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.white};
    font-size: 12px;
    font-weight: 700;
`
