import { Theme, useMediaQuery } from '@mui/material'
import { MainTabs } from 'components/MainTabs'
import { tabComponents } from 'components/MainTabs/TabComponents'
import { Text } from 'components/shared'
import { useMobilePageTitle } from 'hooks/useMobilePageTitle'
import { HeaderFooter } from 'layouts'
import { Space } from 'styles'

const User = () => {
    const title = useMobilePageTitle(tabComponents)
    const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'))

    return (
        <HeaderFooter page={'user'}>
            <Text el="h2">{title}</Text>
            <Space bottom={50} mBottom={20} />
            <MainTabs showTabs={matches} tabComponents={tabComponents} page={'user'} options={{ cardShadow: true, cardPadding: 30 }} />
        </HeaderFooter>
    )
}

export default User
