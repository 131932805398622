import { FC } from 'react'
import { Root } from './styles'

interface IProp {
    content?: 'center' | 'left' | 'right'
    align?: 'start' | 'end' | 'center'
    onClick?: () => void
}

const Row: FC<IProp> = props => {
    return <Root {...props}>{props.children}</Root>
}

export default Row
