import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import AddressesTypes from './addresses-types'

export interface IReducer {
    addresses?: IAdminAddresses
    type: AddressesTypes
}

export interface IState {
    addresses?: IAdminAddresses
    loading: { GET_ADDRESSES: boolean }
    error?: { GET_ADDRESSES: boolean }
}

const initialState = {
    addresses: { count: 0, items: [] },
    loading: { GET_ADDRESSES: false },
    error: { GET_ADDRESSES: false },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case AddressesTypes.START_GET_ADDRESSES:
            return {
                ...state,
                loading: { ...state.loading, GET_ADDRESSES: true },
            }
        case AddressesTypes.SUCCESS_GET_ADDRESSES:
            return {
                ...state,
                addresses: action.addresses,
                loading: { ...state.loading, GET_ADDRESSES: false },
            }
        case AddressesTypes.FINISH_GET_ADDRESSES:
            return {
                ...state,
                addresses: { count: 0, items: [] },
                loading: { ...state.loading, GET_ADDRESSES: false },
                error: { ...state.error, GET_ADDRESSES: false },
            }
        case AddressesTypes.ERROR_GET_ADDRESSES:
            return {
                ...state,
                loading: { ...state.loading, GET_ADDRESSES: false },
                error: { ...state.error, GET_ADDRESSES: true },
            }
        default:
            return state
    }
}

export const AddressesContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const AddressesContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    // console.log('Addresses state', state)

    return <AddressesContext.Provider value={[state, dispatch]}>{props.children}</AddressesContext.Provider>
}
