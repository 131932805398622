import { Correspondences } from './Correspondences'
import { SubscriptionOverview } from './SubscriptionOverview'
import { UtilityBills } from './UtilityBills'
import { Sendouts } from './Sendouts'
import { Payments } from './Payments'

export const adminSubscriptionsTabComponents = [
    {
        name: 'Overview',
        subName: '',
        component: <SubscriptionOverview />,
    },
    {
        name: 'Payments',
        subName: '',
        component: <Payments />,
    },
    {
        name: 'Correspondence',
        subName: '',
        component: <Correspondences />,
    },
    {
        name: 'Utility bills',
        subName: '',
        component: <UtilityBills />,
    },
    {
        name: 'Sendouts',
        subName: '',
        component: <Sendouts />,
    },
] as Array<ITab>
