import { Dialog } from '@mui/material'
import { FailureMessage } from 'components/shared/FailureMessage'
import React, { FC } from 'react'
import { CloseButton } from 'styles'

export interface IPros {
    isOpen: boolean
    close: () => void
    errors?: Array<IError>
}

const FailureModal: FC<IPros> = props => {
    const handleClose = () => {
        props.close()
    }

    return (
        <Dialog onClose={handleClose} open={props.isOpen} fullWidth>
            <CloseButton onClick={handleClose} />
            <FailureMessage errors={props.errors} handleClose={handleClose} />
        </Dialog>
    )
}

export default FailureModal
