import Dialog from '@mui/material/Dialog'
import React, { FC, useContext } from 'react'
import { CloseButton } from 'styles'
import { SendOutFiles } from '.'
import { SendoutsContext, finishAddSendouts } from 'reducers/sendouts'
import { getSendOuts } from 'components/MainTabs/TabComponents/Sendouts/Sendouts-state'

export interface SimpleDialogProps {}

const SendOutFilesModal: FC<SimpleDialogProps> = props => {
    const [sendoutsState, sendoutsDispatch] = useContext(SendoutsContext)
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => setOpen(true)

    const handleClose = () => {
        setOpen(false)

        if (sendoutsState.success.ADD_SENDOUT || !!sendoutsState.error?.ADD_SENDOUT?.some(Boolean)) {
            sendoutsDispatch(finishAddSendouts())
            getSendOuts(sendoutsDispatch)
        }
    }

    return (
        <>
            <div onClick={handleOpen}>{props.children}</div>
            <Dialog onClose={handleClose} open={open} fullWidth>
                <CloseButton onClick={handleClose} />
                <SendOutFiles handleClose={handleClose} />
            </Dialog>
        </>
    )
}

export default SendOutFilesModal
