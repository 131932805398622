import styled from 'styled-components'

export const Root = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 77px;
    background-color: ${props => props.theme.palette.primary.background};
    position: relative;
    border-bottom: 1px solid ${props => props.theme.palette.primary.light};
`

export const GoBack = styled.div`
    position: absolute;
    left: 0;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
`

export const Logo = styled.div`
    font-size: 37px;
    font-weight: bold;
    cursor: pointer;
`
