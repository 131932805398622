import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import MainTabsTypes from './mainTabs-types'

export interface IReducer {
    type: MainTabsTypes
    tabParam: string
}

export type State = typeof initialState

export const initialState = {
    tabParam: 'home',
}

const reducer: Reducer<State, IReducer> = (state, action) => {
    switch (action.type) {
        case MainTabsTypes.START_SWITCH_TAB:
            return {
                tabParam: action.tabParam,
            }
        default:
            return state
    }
}

export const MainTabsContext = createContext<[State, Dispatch<IReducer>]>([initialState, () => {}])

export const MainTabsContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <MainTabsContext.Provider value={[state, dispatch]}>{props.children}</MainTabsContext.Provider>
}
