import { HeaderWithGoBack, Progress } from 'components/shared'
import { FC } from 'react'
import { PageContainer } from 'styles'
interface IProps {
    loading?: boolean
    goBackRoute?: string
}

const HeaderCenter: FC<IProps> = props => {
    return (
        <>
            <HeaderWithGoBack goBackRoute={props.goBackRoute} />
            {props.loading && <Progress />}
            <PageContainer>{props.children}</PageContainer>
        </>
    )
}

export default HeaderCenter
