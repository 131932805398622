import { Text } from 'components/shared'
import { FC } from 'react'
import { Root } from './styles'
import { useParams, useHistory } from 'react-router'
import { trimAndLowercase } from 'utils'
import { Space } from 'styles'
import { navigationRoutes } from 'routes/Routes'

interface IProps {
    name: string
    subName: string
    page: 'user' | 'admin' | 'adminSubscription'
    underscore?: boolean
    cardPadding?: number
}

interface IParams {
    tabParam: string
}

const TabSwitch: FC<IProps> = props => {
    const { tabParam } = useParams<IParams>()
    const history = useHistory()

    const tabName = trimAndLowercase(props.name)
    const isSelected = tabParam === tabName

    const handleTabSwitch = () => history.push(`${navigationRoutes[props.page].base}/${tabName}`)

    return (
        <Root selected={isSelected} onClick={handleTabSwitch} underscore={props.underscore}>
            <Text el="h4">{props.name}</Text>
            <Space bottom={5} />
            <Text el="subtitle">{props.subName}</Text>
        </Root>
    )
}

export default TabSwitch
