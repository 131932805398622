import { AutocompleteValid, Button, Card, DropZone, ErrorAlert, FileAttachments, Input, SuccessMessage, Text } from 'components/shared'
import React, { FC, useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AdminCorrespondencesContext } from 'reducers/admin/correspondences'
import * as correspondencesReducers from 'reducers/correspondence'
import { Grid, Gutter, Space } from 'styles'
import { themeProperties } from 'theme'
import { combineArrays } from 'utils'
import MyFormData from 'utils/formData'
import { deleteCorrespondence, editCorrespondence, initialState } from './EditDeleteCorrespondence-state'

interface IProps {
    item: ITableDataBody | null
    handleClose: () => void
}

const EditDeleteCorrespondence: FC<IProps> = props => {
    const { handleSubmit, control } = useForm({ defaultValues: { ...initialState(props.item) }, mode: 'onChange' })
    const [correspondenceState] = useContext(correspondencesReducers.CorrespondenceTypesContext)
    const [state, dispatch] = useContext(AdminCorrespondencesContext)
    const correspondencesState = useState<ICorrespondencesState>(initialState(props.item))
    const [deleteConfirmed, setDeleteConfirm] = useState<boolean>(false)
    const [correspondences, setCorrespondences] = correspondencesState
    const { t } = useTranslation()

    const onDrop = useCallback(
        acceptedFiles => {
            acceptedFiles.forEach((file: IBlob) => {
                setCorrespondences({ ...correspondences, files: [file] })
            })
        },
        [correspondences, setCorrespondences]
    )

    const handleSelect = (name: string, value: string | number) => {
        setCorrespondences({ ...correspondences, [name]: value })
    }

    const onSubmit = useCallback(
        data => {
            const myFormData = new MyFormData()
            myFormData.appendArray('files', correspondences.files)
            myFormData.appendArrayOfObject([
                { name: 'senderName', value: data.senderName },
                { name: 'type', value: data.type },
                { name: 'status', value: data.status || '' },
            ])
            editCorrespondence(dispatch, myFormData.formData, props.item?.id || '')
        },
        [correspondences, dispatch, props.item?.id]
    )

    const handleDelete = () => {
        deleteCorrespondence(dispatch, props.item?.id || '')
    }

    const loading = state.loading.EDIT_CORRESPONDENCES || state.loading.DELETE_CORRESPONDENCES

    const determineError = (
        <>
            <Space bottom={20} />
            <Text el={'h4'} align="center" color={themeProperties.palette.alert.red}>
                <ErrorAlert errors={combineArrays(state.errors?.EDIT_CORRESPONDENCES, state.errors?.DELETE_CORRESPONDENCES)} />
            </Text>
        </>
    )

    const confirmationButtons = (
        <Gutter size={20} equalChildSize mobile>
            {deleteConfirmed ? (
                <>
                    <Button type="button" onClick={handleDelete} disabled={loading} loading={loading} bgColor={themeProperties.palette.alert.red}>
                        {t('editDeleteCorrespondence.deleteConfirm')}
                    </Button>
                    <Button type="button" onClick={() => setDeleteConfirm(!deleteConfirmed)} bordered disabled={loading} loading={loading}>
                        {t('editDeleteCorrespondence.Back')}
                    </Button>
                </>
            ) : (
                <>
                    <Button type="submit" disabled={loading} loading={loading}>
                        {t('editDeleteCorrespondence.Save')}
                    </Button>
                    <Button type="button" onClick={() => setDeleteConfirm(!deleteConfirmed)} bordered disabled={loading} loading={loading}>
                        {t('editDeleteCorrespondence.Delete')}
                    </Button>
                </>
            )}
        </Gutter>
    )

    if (state.success.EDIT_CORRESPONDENCES) return <SuccessMessage subtitle={t('editDeleteCorrespondence.correspondenceSuccessfullyEdited')} handleClose={props.handleClose} />
    if (state.success.DELETE_CORRESPONDENCES) return <SuccessMessage subtitle={t('editDeleteCorrespondence.correspondenceSuccessfullyDeleted')} handleClose={props.handleClose} />

    return (
        <Card padding={30}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text el={'h2'}>{t('editDeleteCorrespondence.editDeleteCorrespondence')}</Text>
                <Space bottom={20} />
                <Text el={'subtitle'}>{t('editDeleteCorrespondence.enterCorrespondenceDetails')}</Text>
                <Space bottom={30} />
                <Grid elInRow={2} gap={30}>
                    <Input name="senderName" type="text" label={t('editDeleteCorrespondence.senderName')} rules={{ required: t('editDeleteCorrespondence.senderNameIsRequired') }} control={control} />
                    <AutocompleteValid
                        handleChange={handleSelect}
                        value={correspondences.type}
                        label={t('editDeleteCorrespondence.Type')}
                        name="type"
                        fields={correspondenceState.correspondencesTypes}
                        selector="name"
                        rules={{ required: t('editDeleteCorrespondence.typeIsRequired') }}
                        control={control}
                        autoComplete="off"
                    />

                    <DropZone onDrop={onDrop} />
                </Grid>
                <FileAttachments item={props.item} parentState={correspondencesState} />
                <Space bottom={55} />
                {confirmationButtons}
                {determineError}
                <Space bottom={30} />
            </form>
        </Card>
    )
}

export default EditDeleteCorrespondence
