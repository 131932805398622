import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import DownloadTypes from './download-types'

export interface IReducer {
    type: DownloadTypes
}

interface IState {
    loading?: { FILE_DOWNLOAD: boolean }
    success?: { FILE_DOWNLOAD: boolean }
    error?: { FILE_DOWNLOAD: boolean }
}

const initialState = {
    loading: { FILE_DOWNLOAD: false },
    success: { FILE_DOWNLOAD: false },
    error: { FILE_DOWNLOAD: false },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case DownloadTypes.START_FILE_DOWNLOAD:
            return {
                ...state,
                loading: { ...state.loading, FILE_DOWNLOAD: true },
            }
        case DownloadTypes.SUCCESS_FILE_DOWNLOAD:
            return {
                ...state,
                loading: { ...state.loading, FILE_DOWNLOAD: false },
            }
        case DownloadTypes.ERROR_FILE_DOWNLOAD:
            return {
                ...state,
                loading: { ...state.loading, FILE_DOWNLOAD: false },
                error: { ...state.error, FILE_DOWNLOAD: true },
            }
        case DownloadTypes.FINISH_FILE_DOWNLOAD:
            return {
                ...state,
                loading: { ...state.loading, FILE_DOWNLOAD: false },
                error: { ...state.error, FILE_DOWNLOAD: false },
            }
        default:
            return state
    }
}

export const DownloadContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const DownloadContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <DownloadContext.Provider value={[state, dispatch]}>{props.children}</DownloadContext.Provider>
}
