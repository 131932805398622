import styled from 'styled-components'

interface IProps {
    gap?: number
    elInRow?: number
}

export const Grid = styled.div<IProps>`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ gap }) => gap || 0}px;

    & > * {
        width: calc(${({ elInRow }) => (elInRow ? 100 / elInRow : 100)}% - ${({ gap, elInRow }) => (gap && elInRow ? gap / elInRow : 0)}px);
    }

    @media ${({ theme }) => theme.breakpoints.max.md} {
        flex-direction: column;

        & > * {
            width: initial;
        }
    }
`
