import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import SignUpTypes from './signUp-types'

export interface IReducer {
    type: SignUpTypes
    errors?: Array<IError>
}

interface IState {
    loading?: { SIGN_UP?: boolean }
    success?: { SIGN_UP?: boolean }
    errors?: { SIGN_UP?: Array<IError> }
}

const initialState = {
    loading: { SIGN_UP: false },
    success: { SIGN_UP: false },
    errors: { SIGN_UP: [] },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case SignUpTypes.START_SIGN_UP:
            return {
                ...state,
                loading: { ...state.loading, SIGN_UP: true },
                success: { ...state.success, SIGN_UP: false },
            }
        case SignUpTypes.SUCCESS_SIGN_UP:
            return {
                ...state,
                loading: { ...state.loading, SIGN_UP: false },
                success: { ...state.success, SIGN_UP: true },
                errors: { ...state.errors, SIGN_UP: [] },
            }
        case SignUpTypes.ERROR_SIGN_UP:
            return {
                ...state,
                loading: { ...state.loading, SIGN_UP: false },
                success: { ...state.success, SIGN_UP: false },
                errors: { ...state.errors, SIGN_UP: action.errors },
            }
        case SignUpTypes.FINISH_SIGN_UP:
            return {
                ...state,
                loading: { ...state.loading, SIGN_UP: false },
                success: { ...state.success, SIGN_UP: false },
                errors: { ...state.errors, SIGN_UP: [] },
            }
        default:
            return state
    }
}

export const SignUpContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const SignUpContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <SignUpContext.Provider value={[state, dispatch]}>{props.children}</SignUpContext.Provider>
}
