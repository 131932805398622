import React, { FC } from 'react'
import { Root } from './styles'

interface IProps {
    hamburgerState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const HamBurger: FC<IProps> = props => {
    const [isClicked, onClick] = props.hamburgerState

    const handleHamburgerClick = () => {
        onClick(!isClicked)
    }

    return (
        <Root onClick={handleHamburgerClick} isClicked={isClicked}>
            <div className="hamburger"></div>
        </Root>
    )
}

export default HamBurger
