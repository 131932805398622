import Autocomplete from '@mui/material/Autocomplete';
import styled from "styled-components";
import { TextField } from "@mui/material";

export const Root = styled(Autocomplete)`
`

export const TextFieldStyle = styled(TextField)`
    #combo-box{
        padding: 5px 4px 5px 6px;
    }

`