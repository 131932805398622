import { Children, FC } from 'react'
import { Gutter } from 'styles'
import { Root } from './styles'

const ContentBox: FC = props => {
    let children = Children.toArray(props.children)

    return (
        <Root>
            <div>
                <Gutter size={20} align={'center'} mAlign={'start'} mobile>
                    <div> {children[0]} </div>
                    <div> {children[1]} </div>
                </Gutter>
            </div>
            <div>
                <Gutter size={30} mSize={20} mobile>
                    <div> {children[2]} </div>
                    <div> {children[3]} </div>
                </Gutter>
            </div>
        </Root>
    )
}

export default ContentBox
