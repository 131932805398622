import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import reportWebVitals from './reportWebVitals'

import GlobalStyle from 'GlobalStyles'
import { ThemeProvider } from 'styled-components'
import { StylesProvider } from '@mui/styles'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

import './styles.css'
import './i18n'

import { muiTheme, themeProperties } from './theme'

export const history = createBrowserHistory()

ReactDOM.render(
    <StrictMode>
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={themeProperties}>
                    <Router history={history}>
                        <GlobalStyle />
                        <App />
                    </Router>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    </StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
