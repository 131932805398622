import { Dispatch } from 'react'
import * as payments from 'reducers/payments'
import { Payments } from 'services'
import documentImg from 'assets/images/documentCircle.svg'
import { dateAndTimeToLocaleString, toLocaleStringWithCurrency } from 'utils'

export const startGetPayments = async (dispatch: Dispatch<payments.IReducer>, query?: string) => {
    try {
        dispatch(payments.startGetPayments())
        const { data }: { data: IPayments } = await Payments.getPayments(query)
        dispatch(payments.successGetPayments(data))
    } catch (e: any) {
        dispatch(payments.errorGetPayments())
    }
}

export const startGetPaymentsStatuses = async (dispatch: Dispatch<payments.IReducer>) => {
    try {
        dispatch(payments.startGetPaymentsStatuses())
        const { data }: { data: Array<IPaymentStatus> } = await Payments.getPaymentsStatuses()
        dispatch(payments.successGetPaymentsStatuses(data))
    } catch (e: any) {
        dispatch(payments.errorGetPaymentsStatuses())
    }
}

export const startGetPaymentsBalances = async (dispatch: Dispatch<payments.IReducer>) => {
    try {
        dispatch(payments.startGetPaymentsBalance())
        const { data }: { data: Array<IPaymentBalance> } = await Payments.getPaymentsBalances()
        dispatch(payments.successGetPaymentsBalance(data))
    } catch (e: any) {
        dispatch(payments.errorGetPaymentsBalance())
    }
}

export const startCancelPayment = async (dispatch: Dispatch<payments.IReducer>, id?: string) => {
    try {
        dispatch(payments.startCancelPayment())
        await Payments.cancelPayment(id)
        dispatch(payments.successCancelPayment(id))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(payments.errorCancelPayment(errors))
    }
}

export const parsePayments = (data: Array<IPayment> = [], header: Array<string>): ITableData => {
    return {
        header,
        body: body(data),
    }
}

const body = (data: Array<IPayment> = []): Array<ITableDataBody> => {
    return data.reduce((acc, cur) => {
        return [
            ...acc,
            {
                id: cur.id,
                fileId: cur.fileId,
                firstTableData: {
                    image: documentImg,
                    top: cur.name,
                    bottom: dateAndTimeToLocaleString(cur?.createdAt),
                },
                mainData: [cur?.addressName, toLocaleStringWithCurrency(cur.amount.amount, cur.amount.currencyCode)],
                lastTableData: [
                    { type: 'status', value: cur.status },
                    // { type: 'click', value: 'download' },
                    { type: 'buttonWithColorClick', value: 'Cancel payment', secondValue: 'Download', hide: cur.isCancellable === false },
                ],
            },
        ]
    }, [] as Array<ITableDataBody>)
}
