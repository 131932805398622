import { Button, Text } from 'components/shared'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Gutter } from 'styles'

interface IProps {
    body?: ITableDataBody
    value?: string
    lastDataClick?: ILastTableClick
    data?: ILastTableData
}

const ConfirmButton: FC<IProps> = props => {
    const { t } = useTranslation()
    const [isConfirmed, setConfirm] = useState(false)

    const handleMakeAsSendConfirmClick = () => {
        props.lastDataClick?.action(props.body, 'ONE')
        setConfirm(false)
    }

    const handleDetailsClick = () => {
        props.lastDataClick?.action(props.body, 'TWO')
    }

    const confirmOrGoBackButtons = (
        <Gutter size={10}>
            <Button bordered onClick={() => setConfirm(false)} height={32}>
                {t('confirmButton.Back')}
            </Button>
            <Button onClick={handleMakeAsSendConfirmClick} height={32} disabled={props.lastDataClick?.loading}>
                {t('confirmButton.Confirm')}
            </Button>
        </Gutter>
    )

    const actionButton = (
        <>
            {isConfirmed ? (
                confirmOrGoBackButtons
            ) : (
                <Button onClick={() => setConfirm(true)} height={32} disabled={props.lastDataClick?.loading}>
                    {props.children}
                </Button>
            )}
        </>
    )

    return (
        <Gutter size={10} align="center">
            {!props.data?.hide && actionButton}
            <Text el="h4" cursor="pointer" onClick={handleDetailsClick}>
                {props.data?.secondValue || t('confirmButton.Details')}
            </Text>
        </Gutter>
    )
}

export default ConfirmButton
