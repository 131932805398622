import styled, { css } from 'styled-components'

interface IButton {
    width?: number
    maxWidth?: number
    height?: number
    bordered?: number
    full?: number
    disable?: number
    bgColor?: string
    loading?: boolean
}

export const Root = styled.button<IButton>`
    font-family: 'Source Sans Pro';
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;

    min-width: ${props => props.width || 100}px;
    min-height: ${props => props.height || 50}px;

    ${props =>
        props.bordered === 0 &&
        css`
            background-color: ${props.theme.palette.primary.main};
            color: ${props.theme.palette.primary.white};
            &:hover {
                background-color: ${props.theme.palette.button.hover};
            }
        `}

    ${props =>
        props.bordered === 1 &&
        css`
            background-color: ${props.theme.palette.primary.white};
            color: ${props.theme.palette.primary.main};
            border: 1px solid ${props.theme.palette.primary.main};
            &:hover {
                color: ${props.theme.palette.button.hover};
                border: 1px solid ${props.theme.palette.button.hover};
            }
        `}


    ${props =>
        props.full === 1 &&
        css`
            width: 100%;
        `}


    ${props =>
        !!props.maxWidth &&
        css`
            max-width: ${props.maxWidth}px;
        `}


     ${props =>
        props.disable === 1 &&
        css`
            cursor: not-allowed;
            pointer-events: none;
            background-color: ${props.theme.palette.primary.disable};
        `}

        
    ${props =>
        props.bordered === 1 &&
        (!!props.loading || props.disable === 1) &&
        css`
            background-color: ${props.theme.palette.primary.white};
            color: ${props.theme.palette.primary.main};
        `}

        
     ${props =>
        !!props.bgColor &&
        css`
            background-color: ${props.bgColor};
            &:hover {
                background-color: ${props.bgColor}e0;
            }
        `}
   
    ${props =>
        !!props.bgColor &&
        props.disable &&
        css`
            background-color: ${props.bgColor}e0;
        `}
`
