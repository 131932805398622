import { downloadFile } from 'components/common'
import { LoadMoreButton, Progress, Table, TableFilters } from 'components/shared'
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { DownloadContext } from 'reducers/download'
import { UtilityBillsTypesContext, finishGetUtilityBills } from 'reducers/utilityBills'
import { VirtualAddressesContext } from 'reducers/virtualAddresses'
import { Space } from 'styles'
import { makeQueryParamsString } from 'utils'
import { getSubscriptions } from 'components/MainTabs/TabComponents/Home/Home-state'
import { getUtilityBills, getUtilityBillTypes, parseUtilityBills } from './UtilityBills-state'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

const LIMIT = 10

const UtilityBills = () => {
    const { t } = useTranslation()
    const location = useLocation<{ addressId: string; addressName: string }>()
    const [utilityBillsState, utilityBillsDispatch] = useContext(UtilityBillsTypesContext)
    const [virtualAddressesState, virtualAddressesDispatch] = useContext(VirtualAddressesContext)
    const [downloadState, downloadDispatch] = useContext(DownloadContext)
    const filterResults = useRef<Object>()
    const filterLimit = useRef<number>(LIMIT)

    const addressId = location.state?.addressId
    const addressName = location.state?.addressName

    useEffect(() => {
        const queries = makeQueryParamsString({ limit: LIMIT, addressId })
        getUtilityBills(utilityBillsDispatch, queries)
        getUtilityBillTypes(utilityBillsDispatch)
        getSubscriptions(virtualAddressesDispatch)

        return () => {
            utilityBillsDispatch(finishGetUtilityBills())
        }
    }, [addressId, utilityBillsDispatch, virtualAddressesDispatch])

    const items: ITableData = useMemo(
        () => parseUtilityBills(utilityBillsState.utilityBills?.items, [t('tabComponentsUtilityBills.Sender'), t('tabComponentsUtilityBills.Addresses'), t('tabComponentsUtilityBills.Type'), '']),
        [t, utilityBillsState.utilityBills?.items]
    )

    const handleFilterClick = useCallback(
        (values: any) => {
            filterResults.current = values
            const queries = makeQueryParamsString({ ...values, limit: filterLimit.current })
            getUtilityBills(utilityBillsDispatch, queries)
        },
        [utilityBillsDispatch]
    )

    const handleDownloadClick = useCallback(
        (body?: ITableDataBody) => {
            body?.fileId && downloadFile(downloadDispatch, body?.fileId)
        },
        [downloadDispatch]
    )

    const handleLoadMore = useCallback(
        query => {
            getUtilityBills(utilityBillsDispatch, query)
        },
        [utilityBillsDispatch]
    )

    const downloadClickActions = {
        action: handleDownloadClick,
        loading: !!downloadState.loading?.FILE_DOWNLOAD,
    }

    const filerClickActions = {
        action: handleFilterClick,
        loading: !!utilityBillsState.loading.GET_UTILITY_BILLS,
    }

    const tableItems = {
        data: items,
        loading: !!utilityBillsState.loading.GET_UTILITY_BILLS,
    }

    const filterFields = [
        { label: t('tabComponentsUtilityBills.Addresses'), name: 'addressId', fields: virtualAddressesState.addresses, selector: 'id', value: addressName },
        { label: t('tabComponentsUtilityBills.Type'), name: 'type', fields: utilityBillsState.utilityBillTypes, selector: 'name' },
    ] as Array<IFilterTableFields>

    return (
        <>
            {!!downloadState.loading?.FILE_DOWNLOAD && <Progress />}
            <Space bottom={30} />
            <TableFilters fields={filterFields} filterClick={filerClickActions} dateRange />
            <Space bottom={30} />
            <Table items={tableItems} lastDataClick={downloadClickActions} />
            <Space bottom={30} />
            <LoadMoreButton
                onClick={handleLoadMore}
                limit={filterLimit}
                LIMIT={LIMIT}
                count={utilityBillsState.utilityBills?.count}
                loading={!!utilityBillsState.loading.GET_UTILITY_BILLS}
                filterResults={filterResults}
            />
            <Space bottom={30} />
        </>
    )
}

export default UtilityBills
