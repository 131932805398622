import { FC } from 'react'
import { Root } from './styles'
import { TabSwitch } from './TabSwitch'

interface IProps {
    tabComponents: Array<ITab>
    page: 'user' | 'admin' | 'adminSubscription'
    underscore?: boolean
}

const TabSwitches: FC<IProps> = props => {
    return (
        <Root underscore={props.underscore}>
            {props.tabComponents.map((tab: ITab) => (
                <TabSwitch key={tab.name} name={tab.name} subName={tab.subName} page={props.page} underscore={props.underscore} />
            ))}
        </Root>
    )
}

export default TabSwitches
