import React, { FC } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { ContentBox, HeaderWithLogo, HeaderWithTextCircle } from 'components/shared'
import { Row } from 'styles'
import { dateToLocaleString, secondsToMonths } from 'utils'
import { useTranslation } from 'react-i18next'

interface IProps extends Partial<IUserSubscriptionsAdmin> {}

const Content: FC<IProps> = props => {
    const { t } = useTranslation()
    const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'))
    const expiring = matches ? <Row>{`${t('adminContent.Expiring')}: ${props.expiresAt && dateToLocaleString(props.expiresAt)}`}</Row> : <></>

    return (
        <ContentBox>
            <HeaderWithLogo text={`${t('adminContent.virtualAddress')}: ${props.duration?.totalSeconds ? secondsToMonths(props.duration?.totalSeconds) : 0}  ${t('adminContent.months')} `} />
            {expiring}
            <HeaderWithTextCircle textInCircle={`${props.correspondences?.newCount}`} text={t('adminContent.usersUnopenedCorr')} />
            <HeaderWithTextCircle textInCircle={`${props.correspondences?.scanningInProgressCount}`} text={t('adminContent.pendingPayment')} />
        </ContentBox>
    )
}

export default Content
