import { ChooseAddress, PayWithCard } from 'components/OrderAddress'
import { DisplayStepper } from 'components/shared'
import { HeaderCenter } from 'layouts'
import { useContext, useEffect, useMemo } from 'react'
import { finishSetSubscriptionsCarouselIndex, finishGetAvailableSubscriptions, SubscriptionsContext } from 'reducers/subscriptions'
import { Space } from 'styles'
import { getAvailableSubscriptions, parseAddress } from './OrderAddress-state'

const OrderAddress = () => {
    const [state, dispatch] = useContext(SubscriptionsContext)

    useEffect(() => {
        getAvailableSubscriptions(dispatch)
        return () => {
            dispatch(finishSetSubscriptionsCarouselIndex())
            dispatch(finishGetAvailableSubscriptions())
        }
    }, [dispatch])

    const addressData = useMemo(() => parseAddress(state.availableSubscriptions), [state.availableSubscriptions])

    const addresses = {
        addressData,
        loading: state.loading.GET_AVAILABLE_SUBSCRIPTIONS,
    }

    const subscriptionExists = state.availableSubscriptions?.some(Boolean)

    return (
        <>
            <HeaderCenter loading={state.loading.GET_AVAILABLE_SUBSCRIPTIONS}>
                <Space bottom={50} />
                {subscriptionExists && (
                    <DisplayStepper>
                        <ChooseAddress addresses={addresses} />
                        <PayWithCard addresses={addresses} />
                    </DisplayStepper>
                )}
            </HeaderCenter>
        </>
    )
}

export default OrderAddress
