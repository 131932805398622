import { currencyFlag } from 'assets/currencyFlags'
import { AutocompleteValid, Button, Card, DropZone, ErrorAlert, FileAttachments, Image, Input, Spinner, SuccessMessage, Text } from 'components/shared'
import React, { Dispatch, FC, useCallback, useContext, useEffect, useState } from 'react'
import { Control, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CountriesContext } from 'reducers/countries'
import { finishGetSendoutsFee, finishAddSendouts, IReducer, IState, SendoutsContext } from 'reducers/sendouts'
import { VirtualAddressesContext } from 'reducers/virtualAddresses'
import { Grid, Gutter, Row, Space } from 'styles'
import { themeProperties } from 'theme'
import { combineArrays, toLocaleStringWithCurrency } from 'utils'
import MyFormData from 'utils/formData'
import { addSendout, startGetSendOutFee } from './SendOutFiles-state'
interface IProps {
    handleClose: () => void
}

const SendOutFiles: FC<IProps> = props => {
    const sendOutContext = useContext(SendoutsContext)
    const [sendOutState, sendOutDispatch] = sendOutContext
    const [virtualAddressesState] = useContext(VirtualAddressesContext)
    const [countriesState] = useContext(CountriesContext)
    const sendOutFilesState = useState<ISendOutState>({ files: [] })
    const [formValues, setFormValues] = sendOutFilesState
    const { t } = useTranslation()

    useEffect(() => () => sendOutDispatch(finishGetSendoutsFee()), [sendOutDispatch])

    const { handleSubmit, control } = useForm<ISenoutsFormInputs>({
        defaultValues: { userSubscriptionId: '', fullName: '', address: '', city: '', postCode: '', countryCode: '' },
        mode: 'onChange',
    })

    const handleAutocompleteChange = useCallback(
        (name: string, value: string | number) => {
            setFormValues({ ...formValues, [name]: value })
        },
        [formValues, setFormValues]
    )

    const onDrop = useCallback(
        acceptedFiles => {
            sendOutDispatch(finishAddSendouts())
            acceptedFiles.forEach((file: IBlob) => {
                setFormValues({ ...formValues, files: [file] })
            })
        },
        [formValues, sendOutDispatch, setFormValues]
    )

    const onSubmit = useCallback(
        data => {
            const myFormData = new MyFormData()
            myFormData.appendArray('file', formValues.files)
            myFormData.appendArrayOfObject([
                { name: 'userSubscriptionId', value: data.userSubscriptionId },
                { name: 'receiverFullName', value: data.fullName },
                { name: 'address.street', value: data.address },
                { name: 'address.city', value: data.city },
                { name: 'address.postCode', value: data.postCode },
                { name: 'address.countryCode', value: data.countryCode },
                { name: 'type', value: 'Correspondence' },
            ])
            addSendout(sendOutDispatch, myFormData.formData)
        },
        [sendOutDispatch, formValues]
    )

    const totalPrice = !!sendOutState.fee?.totalPrice && toLocaleStringWithCurrency(sendOutState.fee?.totalPrice?.amountWithVatIncluded, sendOutState.fee?.totalPrice?.currencyCode)

    if (sendOutState.success.ADD_SENDOUT)
        return (
            <SuccessMessage
                handleClose={props.handleClose}
                subtitle={`Service successfully ordered. Service cost of ${totalPrice} will be added to next month’s invoice. You will be able to check mail status on the send outs tab.`}
            />
        )

    const isError = !!sendOutState.error?.ADD_SENDOUT?.some(Boolean) || !!sendOutState.error?.GET_SENDOUTS_FEE?.some(Boolean)

    const determineError = isError && (
        <Text el={'h4'} align={'center'} color={themeProperties.palette.alert.red}>
            <Space bottom={30} />
            <ErrorAlert errors={combineArrays(sendOutState.error?.ADD_SENDOUT, sendOutState.error?.GET_SENDOUTS_FEE)} />
        </Text>
    )

    const loading = !!sendOutState.loading?.ADD_SENDOUT || !!sendOutState.loading.GET_SENDOUTS_FEE

    return (
        <Card padding={30} content="center">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row content="center">
                    <Text el="h2">{t('sendOutFiles.sendOutFiles')}</Text>
                </Row>
                <Space bottom={20} />
                <Text el="subtitle">{t('sendOutFiles.pleaseEnterReceiverDetailsAndUploadFilesWhichRecipientOfTheLetterWillReceive')}</Text>
                <Space bottom={30} />
                <AutocompleteValid
                    handleChange={handleAutocompleteChange}
                    label={t('sendOutFiles.virtualAddresses')}
                    name={'userSubscriptionId'}
                    fields={virtualAddressesState.addressedWithSubscriptionId}
                    selector={'id'}
                    rules={{ required: t('sendOutFiles.VirtualAddressIsRequired') }}
                    control={control}
                />
                <Space bottom={30} />
                <Grid elInRow={2} gap={30}>
                    <Input name="fullName" type="text" label={t('sendOutFiles.fullName')} rules={{ required: t('sendOutFiles.fullNameIsRequired') }} control={control} />
                    <Input name="address" type="text" label={t('sendOutFiles.Address')} rules={{ required: t('sendOutFiles.addressIsRequired') }} control={control} />
                    <Input name="city" type="text" label={t('sendOutFiles.City')} rules={{ required: t('sendOutFiles.cityIsRequired') }} control={control} />
                    <Input name="postCode" type="text" label={t('sendOutFiles.postCode')} rules={{ required: t('sendOutFiles.postcodeIsRequired') }} control={control} />
                    <AutocompleteValid
                        handleChange={handleAutocompleteChange}
                        label={t('sendOutFiles.Country')}
                        name={'countryCode'}
                        fields={countriesState.countries}
                        selector={'countryCode'}
                        displaySelect={'displayName'}
                        rules={{ required: t('sendOutFiles.countryIsRequired') }}
                        control={control}
                    />

                    <DropZone onDrop={onDrop} />
                </Grid>
                <FileAttachments parentState={sendOutFilesState} />
                {determineError}
                <SendOutFee control={control} sendOutContext={sendOutContext} countries={countriesState.countries} />
                <Space bottom={35} />
                <Button type="submit" full disabled={loading} loading={loading}>
                    {t('sendOutFiles.sendOut')}
                </Button>
                <Space bottom={30} />
            </form>
        </Card>
    )
}

export default SendOutFiles

interface ISendOutFee {
    control: Control<any, object>
    sendOutContext: [sendOutState: IState, sendOutDispatch: Dispatch<IReducer>]
    countries?: Array<ICountry>
}

const SendOutFee: FC<ISendOutFee> = props => {
    const [sendOutState, sendOutDispatch] = props.sendOutContext
    const { userSubscriptionId, countryCode } = useWatch({ control: props.control })
    const { t } = useTranslation()

    const totalPrice = !!sendOutState.fee?.totalPrice && toLocaleStringWithCurrency(sendOutState.fee?.totalPrice?.amountWithVatIncluded, sendOutState.fee?.totalPrice?.currencyCode)
    const shippingPrice = !!sendOutState.fee?.shippingPrice && toLocaleStringWithCurrency(sendOutState.fee?.shippingPrice?.amountWithVatIncluded, sendOutState.fee?.shippingPrice?.currencyCode)
    const serviceFee = !!sendOutState.fee?.serviceFee && toLocaleStringWithCurrency(sendOutState.fee?.serviceFee?.amountWithVatIncluded, sendOutState.fee?.serviceFee?.currencyCode)

    const isCountry = props.countries?.some((values: ICountry) => values.countryCode === countryCode)

    useEffect(() => {
        !!countryCode && !!userSubscriptionId && isCountry && startGetSendOutFee(sendOutDispatch, { userSubscriptionId, countryCode })
    }, [countryCode, isCountry, sendOutDispatch, userSubscriptionId])

    if (!!sendOutState.loading.GET_SENDOUTS_FEE)
        return (
            <>
                <Space bottom={30} />
                <Row align="center" content="right">
                    <Spinner color={themeProperties.palette.primary.main} />
                </Row>
            </>
        )

    if (!sendOutState.fee?.totalPrice && !sendOutState.loading.GET_SENDOUTS_FEE) return <></>

    return (
        <>
            <Space bottom={30} />
            <Gutter align="center" content="space-between">
                <Gutter size={20} align="center">
                    <Image height={50} width={50} image={currencyFlag['EUR']} />
                    <div>
                        <div>
                            {t('sendOutFiles.sendOutFixedFeeAmount')} ({serviceFee})
                        </div>
                        <div>
                            {t('sendOutFiles.shippingPrice')} ({shippingPrice})
                        </div>
                    </div>
                </Gutter>
                <Text el="h2" color={themeProperties.palette.primary.main}>
                    {totalPrice}
                </Text>
            </Gutter>
        </>
    )
}
