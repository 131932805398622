import styled from 'styled-components'

interface IRoot {
    color?: string
}

export const Root = styled.div<IRoot>`
    display: flex;
    color: ${props => props.theme.palette.primary.secondary};
    align-items: center;
    height: 77px;
    background-color: ${props => props.color || props.theme.palette.primary.background};

    a {
        color: ${props => props.theme.palette.primary.secondary};
    }
`
