import UtilityBillsTypes from './utilityBills-types'

export const startGetUtilityBills = () => ({
    type: UtilityBillsTypes.START_GET_UTILITY_BILLS,
})
export const successGetUtilityBills = (data: IUtilityBills) => ({
    type: UtilityBillsTypes.SUCCESS_GET_UTILITY_BILLS,
    utilityBills: data,
})
export const errorGetUtilityBills = () => ({
    type: UtilityBillsTypes.ERROR_GET_UTILITY_BILLS,
})
export const finishGetUtilityBills = () => ({
    type: UtilityBillsTypes.FINISH_GET_UTILITY_BILLS,
})

export const startGetUtilityBillTypes = () => ({
    type: UtilityBillsTypes.START_GET_UTILITY_BILLS_TYPES,
})
export const successGetUtilityBillTypes = (data: Array<IUtilityBillType>) => ({
    type: UtilityBillsTypes.SUCCESS_GET_UTILITY_BILLS_TYPES,
    utilityBillTypes: data,
})
export const errorGetUtilityBillTypes = () => ({
    type: UtilityBillsTypes.ERROR_GET_UTILITY_BILLS_TYPES,
})
export const finishGetUtilityBillTypes = () => ({
    type: UtilityBillsTypes.FINISH_GET_UTILITY_BILLS_TYPES,
})
