import { Theme, useMediaQuery } from '@mui/material'
import { useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import { SignInContext } from 'reducers/signIn'
import { findNameFromTabs } from 'utils'

const useMobilePageTitle = (components: ITab[]) => {
    const { tabParam } = useParams<{ tabParam: string }>()
    const [state] = useContext(SignInContext)
    const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'))
    const title = useMemo(() => findNameFromTabs<ITab>(components, tabParam), [components, tabParam])

    return state.signedIn && !matches ? title : null
}

export default useMobilePageTitle
