import { Dispatch } from 'react'
import * as payments from 'reducers/payments'
import { Payments } from 'services'

export const StartGetClientPaymentSecret = async (dispatch: Dispatch<payments.IReducer>, ids?: IPaymentIds) => {
    try {
        dispatch(payments.startGetPaymentsClientSecret())
        const { data }: { data: IPaymentClientSecret } = await Payments.getPaymentsClientSecret(ids)
        dispatch(payments.successGetPaymentsClientSecret(data))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(payments.errorGetPaymentsClientSecret(errors))
    }
}
