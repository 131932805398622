import { Close } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import documentCircleImg from 'assets/images/documentCircle.svg'
import { setUserFiles } from 'components/Forms/EditDeleteCorrespondence/EditDeleteCorrespondence-state'
import { useHover } from 'hooks/useHover'
import React, { Dispatch, FC, SetStateAction, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DownloadContext } from 'reducers/download'
import { Grid } from 'styles'
import { otherFileType } from 'utils'
import { Actions, AttachedImage, AttachedImageContainer, DelIcon } from './styles'

interface IPropsFileAttachments {
    item?: ITableDataBody | null
    parentState: [any, Dispatch<SetStateAction<any>>]
}

const FileAttachments: FC<IPropsFileAttachments> = props => {
    const { t } = useTranslation()
    const [downloadState, downloadDispatch] = useContext(DownloadContext)
    const [correspondences, setCorrespondences] = props.parentState

    useEffect(() => {
        !!props.item?.files?.some(Boolean) && setUserFiles(downloadDispatch, props.item?.files, setCorrespondences)
    }, [downloadDispatch, props.item?.files, setCorrespondences])

    const handleDeleteItem = (index: number) => {
        const files = [...correspondences.files]
        files.splice(index, 1)
        setCorrespondences({ ...correspondences, files })
    }

    return downloadState.loading?.FILE_DOWNLOAD ? (
        <h4>{t('fileAttachments.loadingAttachments')}...</h4>
    ) : (
        <div>
            {correspondences.files.some(Boolean) && <h4>{t('fileAttachments.Attached')}:</h4>}
            <Grid elInRow={6} gap={10}>
                {correspondences.files.map((file: IBlob, i: number) => (
                    <AttachedImageComponent key={i} file={file} onDeleteClick={() => handleDeleteItem(i)} />
                ))}
            </Grid>
        </div>
    )
}

interface IAttachedImageComponent {
    file: IBlob
    onDeleteClick: () => void
}

const AttachedImageComponent: FC<IAttachedImageComponent> = props => {
    const { isHovered, hoverEvents } = useHover(false)

    const isOtherFileType = otherFileType[props.file.type]

    const handleView = () => {
        window.open(URL.createObjectURL(props.file), '_blank')
    }

    const iconOrFileImg = !!isOtherFileType ? documentCircleImg : URL.createObjectURL(props.file)

    return (
        <AttachedImageContainer {...hoverEvents}>
            <Tooltip title={props.file?.name ? props.file?.name : isOtherFileType ? isOtherFileType : props.file.type}>
                <AttachedImage src={iconOrFileImg} onClick={handleView} />
            </Tooltip>
            {isHovered && (
                <Actions>
                    <DelIcon onClick={props.onDeleteClick}>
                        <IconButton color="primary" size="small">
                            <Close />
                        </IconButton>
                    </DelIcon>
                </Actions>
            )}
        </AttachedImageContainer>
    )
}

export default FileAttachments
