import { EmptyOval } from 'assets/svg/EmptyOval'
import React, { FC } from 'react'
import { Gutter, Row, ViewButton } from 'styles'
import { themeProperties } from 'theme'
import { Root, RowSc } from './styles'
import { Image } from 'components/shared'

import collapseArrowRightImg from 'assets/images/collapseArrowRight.svg'
import collapseArrowDownImg from 'assets/images/collapseArrowDown.svg'
import { ConfirmButton } from './ConfirmButton'

interface IStatusColor {
    [key: string]: string
}

const statusColor: IStatusColor = {
    Pending: themeProperties.palette.primary.main,
    Expired: themeProperties.palette.primary.main,
    Linked: themeProperties.palette.alert.green,
    Scanned: themeProperties.palette.alert.green,
    Taken: themeProperties.palette.alert.green,
    Free: themeProperties.palette.alert.green,
    Active: themeProperties.palette.alert.green,
    Cancelled: themeProperties.palette.alert.red,
    Failed: themeProperties.palette.alert.red,
    Paid: themeProperties.palette.alert.green,
    Completed: themeProperties.palette.alert.green,
}

interface IProps {
    body?: ITableDataBody
    id?: string
    data: ILastTableData
    lastDataClick?: ILastTableClick
    isCollapsed?: boolean
}

const LastTableData: FC<IProps> = props => {
    const contentByType = {
        status: (
            <Gutter size={10} align={'center'}>
                {<EmptyOval width={6} height={6} color={statusColor[props.data.value] || themeProperties.palette.primary.main} />}
                <div>{props.children}</div>
            </Gutter>
        ),
        click: <div>{props.children}</div>,
        collapse: (
            <RowSc content="right">
                <Image width={16} height={16} minWidth={16} minHeight={16} image={props.isCollapsed ? collapseArrowDownImg : collapseArrowRightImg} />
            </RowSc>
        ),
        buttonClick: (
            <Row content="right">
                <ViewButton>{props.children}</ViewButton>
            </Row>
        ),
        buttonWithColorClick: (
            <Row content="right">
                <ConfirmButton body={props.body} data={props.data} lastDataClick={props.lastDataClick}>
                    {props.children}
                </ConfirmButton>
            </Row>
        ),
        empty: <Row content="right"></Row>,
    }

    const handleLastItemClick = () => {
        if (['click', 'buttonClick'].includes(props.data.type)) {
            props.lastDataClick?.action(props.body)
        }
    }

    return (
        <Root onClick={handleLastItemClick} type={props.data.type} disable={props.lastDataClick?.loading}>
            {contentByType[props.data.type || 'empty']}
        </Root>
    )
}

export default LastTableData
