import styled, { css } from 'styled-components'
import { Row } from 'styles'

interface IRoot {
    type?: string
    disable?: boolean
}

export const Root = styled.td<IRoot>`
    ${props =>
        props.type === 'click' &&
        css`
            font-weight: 600;
            text-align: right;
            cursor: pointer;
        `}
    ${props =>
        props.disable &&
        css`
            pointer-events: none;
        `}
`

export const RowSc = styled(Row)`
    cursor: pointer;
`
