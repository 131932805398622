import styled, { css } from 'styled-components'

interface IGutter {
    size?: number
    mSize?: number
    mobile?: boolean
    align?: 'start' | 'end' | 'center'
    mAlign?: 'start' | 'end' | 'center'
    content?: 'center' | 'left' | 'right' | 'space-between' | 'space-around'
    equalChildSize?: boolean
    fullWidth?: boolean
}

export const Gutter = styled.div<IGutter>`
    display: flex;
    column-gap: ${({ size }) => size}px;
    align-items: ${({ align }) => align || 'start'};
    justify-content: ${({ content }) => content || 'start'};

    ${props =>
        props.fullWidth &&
        css`
            flex: 1;
        `}

    ${props =>
        !!props.mobile &&
        css`
            @media ${props.theme.breakpoints.max.md} {
                flex-direction: column;
                row-gap: ${props.mSize || props.size}px;
                align-items: ${props.mAlign || props.align || 'start'};
            }
        `}

    ${({ equalChildSize }) =>
        equalChildSize &&
        css`
            & > * {
                flex: 1;
                width: 100%;
            }
        `}
`
