import { FC } from 'react'
import { Root } from './styles'

interface IProps {
    name: string
    label?: string
    controllerFields: any
    inputProps: any
    autoComplete?: string
}

const Input: FC<IProps> = ({ controllerFields, ...props }) => {
    const { field, fieldState } = controllerFields

    const helperText = fieldState.invalid ? fieldState.error?.message : null
    return (
        <Root
            {...props}
            {...field}
            error={fieldState.invalid}
            helperText={helperText}
            inputProps={{
                ...props.inputProps,
                autoComplete: props.autoComplete ? props.autoComplete : 'new-password',
            }}
            variant="outlined"
        />
    )
}

export default Input
