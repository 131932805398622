import React, { FC, useState, memo } from 'react'
import { useTranslation } from 'react-i18next'
import TableCollapsed from './CollapsedTable/CollapsedTable'
import { FirstTableData } from './FirstTableData'
import { LastTableData } from './LastTableData'
import { Root, TableRow, Wrapper } from './styles'

interface IProps {
    items?: { data?: ITableData; loading?: boolean }
    lastDataClick?: ILastTableClick
    collapsible?: boolean
}

const Table: FC<IProps> = memo(props => {
    const { t } = useTranslation()

    const isBodyData = props.items?.data?.body.some(Boolean)
    const loadingData = props.items?.loading

    return (
        <Wrapper>
            <Root>
                <thead>
                    <tr>
                        {props.items?.data?.header.map((header: string, i: number) => (
                            <th key={i}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {!isBodyData && loadingData && (
                        <tr>
                            <td>{t('table.Loading')}...</td>
                        </tr>
                    )}
                    {!isBodyData && !loadingData && (
                        <tr>
                            <td>{t('table.noResultsCouldBeFound')}</td>
                        </tr>
                    )}
                    {isBodyData &&
                        props.items?.data?.body.map((body: ITableDataBody, i: number) => <TableBodyRow key={i} body={body} lastDataClick={props.lastDataClick} collapsible={props.collapsible} />)}
                </tbody>
            </Root>
        </Wrapper>
    )
})

export default Table

interface ITableBody {
    body: ITableDataBody
    lastDataClick?: ILastTableClick
    collapsible?: boolean
}

const TableBodyRow: FC<ITableBody> = props => {
    const collapseState = useState(false)
    const [isCollapsed, collapse] = collapseState

    const rowEvents = props.collapsible ? { onClick: () => collapse(!isCollapsed) } : {}

    return (
        <>
            <TableRow isCollapsed={isCollapsed} {...rowEvents}>
                {!!props.body.firstTableData && <FirstTableData firstTableData={props.body.firstTableData} collapsible={props.collapsible} isCollapsed={isCollapsed} />}
                {props.body.mainData.map((middle, i) => (
                    <td key={`mainData${i}`}>{middle}</td>
                ))}
                {props.body.lastTableData?.map((data: ILastTableData, i: number) => (
                    <LastTableData key={`lastTableData${i}`} id={props.body.id} lastDataClick={props.lastDataClick} isCollapsed={isCollapsed} data={data} body={props.body}>
                        {data?.value}
                    </LastTableData>
                ))}
            </TableRow>
            {props.collapsible && isCollapsed && <TableCollapsed collapsedData={props.body.collapsedData} lastDataClick={props.lastDataClick} isCollapsed={isCollapsed} />}
        </>
    )
}
