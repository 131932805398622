import { Theme, useMediaQuery } from '@mui/material'
import LogOutImg from 'assets/images/logOut.svg'
import { Image, Select } from 'components/shared'
import { History } from 'history'
import { FC, ReactElement, useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { SignInContext } from 'reducers/signIn'
import { navigationRoutes } from 'routes/Routes'
import { Gutter, Space } from 'styles'
import { HamBurger } from './HamBurger'
import { singOut } from './Header-state'
import { LinkingButton } from './LinkingButton'
import { MobileNav } from './MobileNav'
import { Logo, LogOut, Root } from './styles'
import { useTranslation } from 'react-i18next'
import { languages } from 'i18n'
interface IProps {
    color?: string
    page?: string
}
interface ILinking {
    [key: string]: ReactElement
}

const Header: FC<IProps> = props => {
    const { t } = useTranslation()
    const history = useHistory()
    const [state, dispatch] = useContext(SignInContext)
    const matches = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'))
    const hamburgerState = useState(false)

    const userPage = props.page === 'user'
    const adminPage = props.page === 'admin'
    const userAuthenticatedAndMobileView = matches && state.signedIn
    const isUserPageAuthenticatedAndMobile = userPage && state.signedIn && matches

    const Linking: ILinking = {
        signUp: <LinkingButton title={t('header.alreadyHaveAnAccount')} buttonBody={t('header.logIn')} onClick={() => history.push(navigationRoutes.signIn)} />,
        signIn: <LinkingButton title={t('header.dontHaveAnAccountYet')} buttonBody={t('header.signUp')} onClick={() => history.push(navigationRoutes.signUp)} />,
    }

    const handleLogOut = useCallback(() => {
        singOut(dispatch, history)
    }, [dispatch, history])

    return (
        <>
            <Root color={props.color} isUserPageAuthenticatedAndMobile={isUserPageAuthenticatedAndMobile}>
                <LogoDisplay userAuthenticatedAndMobileView={userAuthenticatedAndMobileView} hamburgerState={hamburgerState} history={history} />
                <Gutter size={20} align="center">
                    {state.isAdmin && <SelectPage history={history} adminPage={adminPage} />}
                    {matches === false && Linking[props.page || '']}
                    <SelectLanguage />
                    {state.signedIn && <LogoutButton handleLogOut={handleLogOut} />}
                </Gutter>
            </Root>
            {userAuthenticatedAndMobileView && <MobileNav hamburgerState={hamburgerState} />}
            {<HeaderBottom page={props.page} Linking={Linking} matches={matches} isUserPageAuthenticatedAndMobile={isUserPageAuthenticatedAndMobile} />}
        </>
    )
}

export default Header

interface IPropsLogout {
    handleLogOut: () => void
}

const LogoutButton: FC<IPropsLogout> = props => {
    return (
        <LogOut onClick={props.handleLogOut}>
            <Image width={16} height={18} image={LogOutImg} />
        </LogOut>
    )
}

interface ILogoDisplay {
    userAuthenticatedAndMobileView?: boolean
    history: History<unknown>
    hamburgerState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const LogoDisplay: FC<ILogoDisplay> = props => {
    return props.userAuthenticatedAndMobileView ? <HamBurger hamburgerState={props.hamburgerState} /> : <Logo onClick={() => props.history.push(navigationRoutes.landing)}>offiz</Logo>
}

interface IHeaderBottom {
    page?: string
    isUserPageAuthenticatedAndMobile?: boolean
    Linking: ILinking
    matches: boolean
}

const HeaderBottom: FC<IHeaderBottom> = props => {
    const { Linking } = props

    return (
        <>
            <Space bottom={20} />
            {props.matches && Linking[props.page || '']}
        </>
    )
}

interface ISelectPage {
    history: History<unknown>
    adminPage: boolean
}

const SelectPage: FC<ISelectPage> = props => {
    const { t } = useTranslation()

    const handleLinkTo = (to: string) => {
        props.history.push(to)
    }

    return (
        <Select selectedIndex={props.adminPage ? 1 : 0}>
            <div onClick={() => handleLinkTo(navigationRoutes.user.home)}>{t('header.userView')}</div>
            <div onClick={() => handleLinkTo(navigationRoutes.admin.addresses)}>{t('header.adminView')}</div>
        </Select>
    )
}

const SelectLanguage = () => {
    const { i18n } = useTranslation()

    const handleLanguageChange = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    return languages.length > 1 ? (
        <>
            <Select>
                {languages.map(lang => (
                    <div onClick={() => handleLanguageChange(lang)}>{lang.toUpperCase()}</div>
                ))}
            </Select>
        </>
    ) : (
        <></>
    )
}
