import PaymentsTypes from './payments-types'

export const startGetPaymentsAdmin = () => ({
    type: PaymentsTypes.START_GET_PAYMENTS,
})
export const successGetPaymentsAdmin = (data: IPaymentsAdmin) => ({
    type: PaymentsTypes.SUCCESS_GET_PAYMENTS,
    payments: data,
})
export const errorGetPaymentsAdmin = () => ({
    type: PaymentsTypes.ERROR_GET_PAYMENTS,
})
export const finishGetPaymentsAdmin = () => ({
    type: PaymentsTypes.FINISH_GET_PAYMENTS,
})
