import styled, { css } from 'styled-components'

interface IRoot {
    color?: string
    isUserPageAuthenticatedAndMobile?: boolean
}

export const Root = styled.div<IRoot>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 77px;
    background-color: ${props => props.color || props.theme.palette.primary.background};

    ${props => props.isUserPageAuthenticatedAndMobile && css`
        border-bottom: 1px solid ${props =>  props.theme.palette.primary.light};
    `} 

`

export const Logo = styled.div`
    font-size: 37px;
    font-weight: bold;
    cursor: pointer;
`

export const LogOut = styled.div`
    cursor: pointer;
`