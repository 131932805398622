import { Card, Text } from 'components/shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Gutter, Space } from 'styles'
import { themeProperties } from 'theme'
import { ViewAllButton } from './styles'

interface IProps {
    name: string
    subTitle: string
    count?: number
    viewAllAction?: () => void
}

const DetailsViewAll: FC<IProps> = props => {
    const { t } = useTranslation()

    return (
        <div>
            <Text el={'h3'}>{props.name}</Text>
            <Space bottom={20} />
            <Card border height={106} padding={30} content="center">
                <Gutter size={20} align="center" content="space-between">
                    <div>
                        <Text el={'h2'} color={themeProperties.palette.primary.main}>
                            {props.count}
                        </Text>
                        <Space bottom={10} />
                        {props.subTitle}
                    </div>
                    <ViewAllButton onClick={props.viewAllAction}>{t('detailsViewAll.viewAll')}</ViewAllButton>
                </Gutter>
            </Card>
        </div>
    )
}

export default DetailsViewAll
