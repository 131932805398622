import React, { FC } from 'react'
import { useParams } from 'react-router'
import { trimAndLowercase } from 'utils'
import { Root } from './styles'
import { TabContent } from './TabContent'

interface IProps {
    tabComponents: Array<ITab>
    cardShadow?: boolean
    cardPadding?: number
}
interface IParams {
    tabParam: string
}

const TabContents: FC<IProps> = props => {
    const { tabParam } = useParams<IParams>()

    const handleContentFilter = () => {
        return (tab: ITab) => {
            const tabName = trimAndLowercase(tab.name)
            return tabParam === tabName
        }
    }

    return (
        <Root content="center">
            {props.tabComponents.filter(handleContentFilter()).map((tab: ITab) => (
                <TabContent key={tab.name} component={tab.component} cardShadow={props.cardShadow} cardPadding={props.cardPadding} />
            ))}
        </Root>
    )
}

export default TabContents
