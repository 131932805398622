import { Dispatch } from 'react'
import * as subscriptions from 'reducers/admin/subscriptions'
import { UserSubscriptionsAdmin } from 'services'

export const getUserSubscriptions = async (dispatch: Dispatch<subscriptions.IReducer>, id?: string) => {
    try {
        dispatch(subscriptions.startGetSubscriptions())
        const { data }: { data: IUserSubscriptionsAdmin } = await UserSubscriptionsAdmin.userSubscriptions(id)
        dispatch(subscriptions.successGetSubscriptions(data))
    } catch (e) {
        dispatch(subscriptions.errorGetSubscriptions())
    }
}
