import styled from 'styled-components'
import { CircularProgress } from '@mui/material'
interface IProps {
    colorprops?: string
    loading?: string
}

export const Root = styled(CircularProgress)<IProps>`
    svg{
        color: ${({ colorprops }) => colorprops || 'white'};
    }
`
