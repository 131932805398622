import styled from 'styled-components'

interface IRoot {
    content?: 'center' | 'left' | 'right'
    align?: 'start' | 'end' | 'center'
}

export const Root = styled.div<IRoot>`
    display: flex;
    justify-content: ${({ content }) => content || 'left'};
    align-items: ${({ align }) => align || 'start'};
`
