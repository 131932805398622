import React, { FC } from 'react'
import PayNow from './PayNow'
import Dialog from '@mui/material/Dialog'
import { CloseButton } from 'styles'

const PayNowModal: FC = props => {
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => setOpen(true)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <div onClick={handleOpen}>{props.children}</div>
            <Dialog onClose={handleClose} open={open} fullWidth>
                <CloseButton onClick={handleClose} />
                <PayNow />
            </Dialog>
        </>
    )
}

export default PayNowModal
