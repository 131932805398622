import React, { FC, useContext } from 'react'
import { UploadCorrespondence } from '.'
import Dialog from '@mui/material/Dialog'

import * as adminCorrespondences from 'reducers/admin/correspondences'
import { CloseButton } from 'styles'

export interface SimpleDialogProps {}

const UploadCorrespondenceModal: FC<SimpleDialogProps> = props => {
    const [state, dispatch] = useContext(adminCorrespondences.AdminCorrespondencesContext)

    const [open, setOpen] = React.useState(false)

    const handleOpen = () => setOpen(true)

    const handleClose = () => {
        setOpen(false)

        if (state.success.UPLOAD_CORRESPONDENCES || state.errors.UPLOAD_CORRESPONDENCES?.some(Boolean)) {
            dispatch(adminCorrespondences.finishUploadCorrespondences())
        }
    }

    return (
        <>
            <div onClick={handleOpen}>{props.children}</div>
            <Dialog onClose={handleClose} open={open} fullWidth>
                <CloseButton onClick={handleClose} />
                <UploadCorrespondence handleClose={handleClose} />
            </Dialog>
        </>
    )
}

export default UploadCorrespondenceModal
