import { Table, TableFilters, LoadMoreButton } from 'components/shared'
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { AddressesContext, finishGetAddresses } from 'reducers/admin/addresses'
import { navigationRoutes } from 'routes/Routes'
import { Space } from 'styles'
import { makeQueryParamsString } from 'utils'
import { getAddresses, parseAddresses } from './Addresses-state'

const LIMIT = 10

const Addresses = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [addressesState, addressesDispatch] = useContext(AddressesContext)
    const filterResults = useRef<Object>()
    const filterLimit = useRef<number>(LIMIT)

    useEffect(() => {
        const queries = makeQueryParamsString({ limit: LIMIT })
        getAddresses(addressesDispatch, queries)

        return () => {
            addressesDispatch(finishGetAddresses())
        }
    }, [addressesDispatch])

    const items: ITableData = useMemo(
        () => parseAddresses(addressesState.addresses?.items, [t('adminTabComponentsAddress.fullAddress'), t('adminTabComponentsAddress.Status'), '']),
        [addressesState.addresses?.items, t]
    )

    const handleViewDetails = useCallback(
        (body?: ITableDataBody) => {
            history.push({
                pathname: `${navigationRoutes.adminSubscription.overview}`,
                state: { subscriptionId: body?.id },
            })
        },
        [history]
    )

    const handleFilterClick = useCallback(
        (values: any) => {
            filterResults.current = values
            const queries = makeQueryParamsString({ ...values, limit: filterLimit.current })
            getAddresses(addressesDispatch, queries)
        },
        [addressesDispatch]
    )

    const handleLoadMore = useCallback(
        query => {
            getAddresses(addressesDispatch, query)
        },
        [addressesDispatch]
    )

    const viewDetailsClickActions = {
        action: handleViewDetails,
        loading: !!addressesState.loading.GET_ADDRESSES,
    }

    const filerClickActions = {
        action: handleFilterClick,
        loading: !!addressesState.loading.GET_ADDRESSES,
    }

    const tableItems = {
        data: items,
        loading: !!addressesState.loading.GET_ADDRESSES,
    }

    const searchProps = { enabled: true, name: 'search', label: t('adminTabComponentsAddress.searchForAddressesByAddressMailboxNumberOrSubscriptionNo') }

    return (
        <div>
            <Space bottom={30} />
            <TableFilters fields={[]} filterClick={filerClickActions} search={searchProps} />
            <Space bottom={30} />
            <Table items={tableItems} collapsible lastDataClick={viewDetailsClickActions} />
            <Space bottom={30} />
            <LoadMoreButton
                onClick={handleLoadMore}
                limit={filterLimit}
                LIMIT={LIMIT}
                count={addressesState.addresses?.count}
                loading={!!addressesState.loading.GET_ADDRESSES}
                filterResults={filterResults}
            />
            <Space bottom={30} />
        </div>
    )
}

export default Addresses
