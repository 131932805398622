import { FC } from 'react'
import { TabContents } from './TabContents'
import { TabSwitches } from './TabSwitches'

interface IProps {
    tabComponents: Array<ITab>
    page: 'user' | 'admin' | 'adminSubscription'
    options?: { underscore?: boolean; cardShadow?: boolean; cardPadding?: number }
    showTabs: boolean
}

const MainTabs: FC<IProps> = props => {
    return (
        <div>
            {props.showTabs ? <TabSwitches tabComponents={props.tabComponents} page={props.page} underscore={props.options?.underscore} /> : <></>}
            <TabContents tabComponents={props.tabComponents} cardShadow={props.options?.cardShadow} cardPadding={props.options?.cardPadding} />
        </div>
    )
}

export default MainTabs
