import { LoadMoreButton, Progress, Table, TableFilters } from 'components/shared'
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { AdminUtilityBillsContext, finishGetUtilityBills } from 'reducers/admin/utilityBills'
import { VirtualAddressesContext } from 'reducers/virtualAddresses'
import { Space } from 'styles'
import { localStorageHelper, makeQueryParamsString } from 'utils'
import { getUtilityBills, parseUtilityBills } from './UtilityBills-state'
import { UtilityBillsTypesContext } from 'reducers/utilityBills'
import { getSubscriptions } from 'components/MainTabs/TabComponents/Home/Home-state'
import { getUtilityBillTypes } from 'components/MainTabs/TabComponents/UtilityBills/UtilityBills-state'
import { DownloadContext } from 'reducers/download'
import { downloadAdminFile } from 'components/common'
import { useTranslation } from 'react-i18next'

const LIMIT = 10

const UtilityBills = () => {
    const { t } = useTranslation()
    const [state, dispatch] = useContext(AdminUtilityBillsContext)
    const [virtualAddressesState, virtualAddressesDispatch] = useContext(VirtualAddressesContext)
    const [utilityBillsState, utilityBillsDispatch] = useContext(UtilityBillsTypesContext)
    const [downloadState, downloadDispatch] = useContext(DownloadContext)
    const filterResults = useRef<Object>()
    const filterLimit = useRef<number>(LIMIT)

    const subscriptionId = localStorageHelper('subscriptionId')

    useEffect(() => {
        const queries = makeQueryParamsString({ limit: LIMIT })
        getUtilityBills(dispatch, subscriptionId, queries)
        getUtilityBillTypes(utilityBillsDispatch)
        getSubscriptions(virtualAddressesDispatch)

        return () => {
            dispatch(finishGetUtilityBills())
        }
    }, [dispatch, subscriptionId, utilityBillsDispatch, virtualAddressesDispatch])

    const items: ITableData = useMemo(
        () =>
            parseUtilityBills(state.utilityBills?.items, [
                t('adminSubscriptionTabsUtilityBills.Sender'),
                t('adminSubscriptionTabsUtilityBills.Address'),
                t('adminSubscriptionTabsUtilityBills.Type'),
                '',
            ]),
        [state.utilityBills?.items, t]
    )

    const handleActionClick = useCallback(
        (body?: ITableDataBody) => {
            body?.fileId && downloadAdminFile(downloadDispatch, body?.fileId)
        },
        [downloadDispatch]
    )

    const handleFilterClick = useCallback(
        (values: any) => {
            filterResults.current = values
            const queries = makeQueryParamsString({ ...values, limit: filterLimit.current })
            getUtilityBills(dispatch, subscriptionId, queries)
        },
        [dispatch, subscriptionId]
    )

    const handleLoadMore = useCallback(
        query => {
            getUtilityBills(dispatch, subscriptionId, query)
        },
        [dispatch, subscriptionId]
    )

    const viewDetailsClickActions = {
        action: handleActionClick,
        loading: !!downloadState.loading?.FILE_DOWNLOAD,
    }

    const filerClickActions = {
        action: handleFilterClick,
        loading: !!state.loading.GET_UTILITY_BILLS,
    }

    const tableItems = {
        data: items,
        loading: !!state.loading.GET_UTILITY_BILLS,
    }

    const filterFields = [
        { label: t('adminSubscriptionTabsUtilityBills.Addresses'), name: 'addressId', fields: virtualAddressesState.addresses, selector: 'id' },
        { label: t('adminSubscriptionTabsUtilityBills.Type'), name: 'type', fields: utilityBillsState.utilityBillTypes, selector: 'name' },
    ] as Array<IFilterTableFields>

    return (
        <div>
            {!!downloadState.loading?.FILE_DOWNLOAD && <Progress />}
            <Space bottom={30} />
            <TableFilters fields={filterFields} filterClick={filerClickActions} dateRange />
            <Space bottom={30} />
            <Table items={tableItems} lastDataClick={viewDetailsClickActions} />
            <Space bottom={30} />
            <LoadMoreButton onClick={handleLoadMore} limit={filterLimit} LIMIT={LIMIT} count={state.utilityBills?.count} loading={!!state.loading.GET_UTILITY_BILLS} filterResults={filterResults} />
            <Space bottom={30} />
        </div>
    )
}

export default UtilityBills
