import { dateToLocaleString } from 'utils'

export const parseTableData = (data: Array<ILinkedService> = []): ITableData => {
    return {
        header: [],
        body: body(data),
    }
}

const body = (data: Array<ILinkedService> = []): Array<ITableDataBody> => {
    return data.reduce((acc, cur) => {
        return [
            ...acc,
            {
                id: cur.id,
                mainData: [cur?.serviceName, dateToLocaleString(cur?.linkedAt), cur.type],
                lastTableData: [{ type: 'status', value: cur.status }],
            },
        ]
    }, [] as Array<ITableDataBody>)
}
