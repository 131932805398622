import SubscriptionsTypes from './subscriptions-types'

export const startGetAvailableSubscriptions = () => ({
    type: SubscriptionsTypes.START_GET_AVAILABLE_SUBSCRIPTIONS,
})
export const successGetAvailableSubscriptions = (data: Array<IAvailableSubscription>) => ({
    type: SubscriptionsTypes.SUCCESS_GET_AVAILABLE_SUBSCRIPTIONS,
    availableSubscriptions: data,
})
export const errorGetAvailableSubscriptions = () => ({
    type: SubscriptionsTypes.ERROR_GET_AVAILABLE_SUBSCRIPTIONS,
})
export const finishGetAvailableSubscriptions = () => ({
    type: SubscriptionsTypes.FINISH_GET_AVAILABLE_SUBSCRIPTIONS,
})

export const startSetSubscriptionsCarouselIndex = () => ({
    type: SubscriptionsTypes.START_SET_SUBSCRIPTION_CAROUSEL_INDEX,
})
export const successSetSubscriptionsCarouselIndex = (index: number) => ({
    type: SubscriptionsTypes.SUCCESS_SET_SUBSCRIPTION_CAROUSEL_INDEX,
    subscriptionCarouselIndex: index,
})
export const errorSetSubscriptionsCarouselIndex = () => ({
    type: SubscriptionsTypes.ERROR_SET_SUBSCRIPTION_CAROUSEL_INDEX,
})
export const finishSetSubscriptionsCarouselIndex = () => ({
    type: SubscriptionsTypes.FINISH_SET_SUBSCRIPTION_CAROUSEL_INDEX,
})

export const startGetClientSecret = () => ({
    type: SubscriptionsTypes.START_GET_CLIENT_SECRET,
})
export const successGetClientSecret = (secret: string) => ({
    type: SubscriptionsTypes.SUCCESS_GET_CLIENT_SECRET,
    secret,
})
export const errorGetClientSecret = (errors: Array<IError>) => ({
    type: SubscriptionsTypes.ERROR_GET_CLIENT_SECRET,
    errors,
})
export const finishGetClientSecret = () => ({
    type: SubscriptionsTypes.FINISH_GET_CLIENT_SECRET,
})
