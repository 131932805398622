import { Button, Image, Text } from 'components/shared'
import React, { FC, useContext, FunctionComponent } from 'react'
import { Column, Gutter, Space } from 'styles'
import { Root } from './styles'
import * as payments from 'reducers/payments'

import paymentsDude from 'assets/images/paymentsDude.svg'
import { toLocaleStringWithCurrency } from 'utils'
import { useTranslation } from 'react-i18next'

interface IProps {
    payNowModal: FunctionComponent<any>
}

const PayNow: FC<IProps> = props => {
    const { t } = useTranslation()
    const [paymentsContextState] = useContext(payments.PaymentsContext)

    const { payNowModal: PayNowModal } = props

    const paymentBalance = paymentsContextState.paymentsBalances?.[0]
    const unpaidAmount = paymentBalance ? toLocaleStringWithCurrency(paymentBalance?.balance, paymentBalance?.currencyCode) : null

    return (
        <Root>
            <Gutter size={30} mobile align="center" content="center">
                <Image height={110} width={110} image={paymentsDude} />
                <Column align="center" content="center">
                    <Text el="h2">
                        {unpaidAmount} {t('payNow.unpaidAmount')}
                    </Text>
                    <Space bottom={28} />
                    <PayNowModal>
                        <Button width={180}>{t('payNow.payNow')}</Button>
                    </PayNowModal>
                </Column>
            </Gutter>
        </Root>
    )
}

export default PayNow
