import { Dispatch } from 'react'
import * as sendouts from 'reducers/sendouts'
import { SendOuts } from 'services'

export const addSendout = async (dispatch: Dispatch<sendouts.IReducer>, data: FormData) => {
    try {
        dispatch(sendouts.startAddSendouts())
        const res = await SendOuts.addSendout(data)
        if (res?.status === 204) dispatch(sendouts.successAddSendouts())
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(sendouts.errorAddSendouts(errors))
    }
}

export const startGetSendOutFee = async (dispatch: Dispatch<sendouts.IReducer>, inputs: ISendOutFeePathInputs) => {
    try {
        dispatch(sendouts.startGetSendoutsFee())
        const { data }: { data: ISendOutsFee } = await SendOuts.getSendoutsFee(inputs)
        dispatch(sendouts.successGetSendoutsFee(data))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(sendouts.errorGetSendoutsFee(errors))
    }
}
