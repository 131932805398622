import { ChangeEventHandler, FC } from 'react'
import { Root } from './styles'

interface IProps {
    name: string
    label?: string
    fullWidth?: boolean
    type?: string
    value?: string
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
    placeholder?: string
    InputLabelProps?: any
}

const InputNoValid: FC<IProps> = props => {
    return <Root {...props} variant="outlined" />
}

export default InputNoValid
