import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Button, InputNoValid } from 'components/shared'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Gutter } from 'styles'
import { Autocomplete } from '../Autocomplete'
import { DatePicker } from '../DatePicker'
import { initialState } from './TableFilters-state'

interface IProps {
    selectedValues?: (values: any) => void
    fields: Array<IFilterTableFields>
    filterClick: { action: (values: any) => void; loading: boolean }
    dateRange?: boolean
    search?: { enabled: boolean; label: string; name: string }
}

const TableFilters: FC<IProps> = memo(props => {
    const { t } = useTranslation()
    const [values, setValues] = useState({ ...initialState(props.fields) })

    useEffect(() => {
        !!props.selectedValues && props.selectedValues(values)
    }, [props, values])

    const handleChange = useCallback((name: string, values: string | number) => {
        setValues(prevValues => ({ ...prevValues, [name]: values }))
    }, [])

    return (
        <div>
            <form onSubmit={e => e.preventDefault()}>
                <Gutter size={20} equalChildSize mobile>
                    {props.dateRange && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker name={'fromDate'} label={t('tableFilter.From')} handleChange={handleChange} disableFuture />
                            <DatePicker name={'toDate'} label={t('tableFilter.To')} handleChange={handleChange} disableFuture />
                        </LocalizationProvider>
                    )}
                    {props.fields.map((field: IFilterTableFields, i: number) => (
                        <Autocomplete key={i} handleChange={handleChange} autoComplete="off" {...field} />
                    ))}
                    {props.search?.enabled && <InputNoValid label={props.search.label} name={props.search.name} onChange={e => handleChange(e.target.name, e.target.value)} />}
                    <Button type="submit" onClick={() => props.filterClick.action(values)} loading={props.filterClick.loading} disabled={props.filterClick.loading} maxWidth={180}>
                        {t('tableFilter.Filter')}
                    </Button>
                </Gutter>
            </form>
        </div>
    )
})

export default TableFilters
