import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import PaymentsTypes from './payments-types'

export interface IReducer {
    payments?: IPaymentsAdmin
    type: PaymentsTypes
}

export interface IState {
    payments?: IPaymentsAdmin
    loading: { GET_PAYMENTS: boolean }
    error?: { GET_PAYMENTS: boolean }
}

const initialState = {
    payments: { count: 0, items: [] },
    loading: { GET_PAYMENTS: false },
    error: { GET_PAYMENTS: false },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case PaymentsTypes.START_GET_PAYMENTS:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS: true },
            }
        case PaymentsTypes.SUCCESS_GET_PAYMENTS:
            return {
                ...state,
                payments: action.payments,
                loading: { ...state.loading, GET_PAYMENTS: false },
            }
        case PaymentsTypes.FINISH_GET_PAYMENTS:
            return {
                ...state,
                payments: { count: 0, items: [] },
                loading: { ...state.loading, GET_PAYMENTS: false },
                error: { ...state.error, GET_PAYMENTS: false },
            }
        case PaymentsTypes.ERROR_GET_PAYMENTS:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS: false },
                error: { ...state.error, GET_PAYMENTS: true },
            }
        default:
            return state
    }
}

export const PaymentsAdminContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const PaymentsAdminContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <PaymentsAdminContext.Provider value={[state, dispatch]}>{props.children}</PaymentsAdminContext.Provider>
}
