import { Button, Card, ItemsCarousel, Text } from 'components/shared'
import { FC, memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SubscriptionsContext, successSetSubscriptionsCarouselIndex } from 'reducers/subscriptions'
import { Gutter, Row, Space } from 'styles'
import { AddressDetails } from './AddressDetails'
import { DurationChoose } from './DurationChoose'

interface IProps {
    next?: () => void
    back?: () => void
    addresses: { addressData: Array<ISubscription>; loading: boolean }
}

const ChooseAddress: FC<IProps> = memo(props => {
    const { t } = useTranslation()
    const [state, dispatch] = useContext(SubscriptionsContext)

    const setCarouselIndex = (index: number) => dispatch(successSetSubscriptionsCarouselIndex(index))

    const durationComponents = props.addresses.addressData.map((data: ISubscription, i: number) => <DurationChoose key={i} durationData={data} />)

    const address = props.addresses.addressData[state.subscriptionCarouselIndex || 0]

    return (
        <Row content="center">
            <Card width={817} padding={30}>
                <Space bottom={30} />
                <Text el={'h2'}> {t('chooseAddress.chooseYourVirtualAddress')} </Text>
                <Space bottom={20} />
                <ItemsCarousel carouselIndex={state.subscriptionCarouselIndex} setCarouselIndex={setCarouselIndex}>
                    {durationComponents}
                </ItemsCarousel>
                <Space bottom={20} />
                <AddressDetails details={address} />
                <Space bottom={50} />
                <Gutter equalChildSize>
                    <div></div>
                    <Button onClick={() => props.next && props.next()}>{t('chooseAddress.chooseAddress')}</Button>
                </Gutter>
                <Space bottom={30} />
            </Card>
        </Row>
    )
})

export default ChooseAddress
