import axios from "axios";
import { localStorageHelper } from "utils";

export function setAccessTokens(response: ISignInResponse) {
    localStorageHelper('session', response)
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`
}

export function clearAccessTokens() {
    localStorage.removeItem('session')
    axios.defaults.headers.common['Authorization'] = false
}