import { Dispatch } from 'react'
import * as signIn from 'reducers/signIn'
import { User } from 'services'

export const magicLinks = async (dispatch: Dispatch<signIn.IReducer>, user: ISignInUser) => {
    try {
        dispatch(signIn.startMagicLink())
        const res = await User.magicLinks(user)
        if (res?.status === 204) dispatch(signIn.successMagicLink())
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(signIn.errorMagicLink(errors))
    }
}
