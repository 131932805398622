import { FilesDisplay, TableWithHorizontalDisplay } from 'components/shared'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from 'styles'
import { flatterSendOuts } from './SendOutDetails-state'
import { Root } from './styles'

interface IProps {
    item: ISendOutAdmin | ISendout | null
    handleClose: () => void
}

const SendOutDetails: FC<IProps> = props => {
    const { t } = useTranslation()
    const items = useMemo(() => flatterSendOuts(props.item), [props.item])

    return (
        <Root>
            <TableWithHorizontalDisplay title={t('sendOutDetails.Details')} items={items} />
            <Space bottom={20} />
            <FilesDisplay files={props.item?.files} />
            <Space bottom={50} />
        </Root>
    )
}

export default SendOutDetails
