import CorrespondencesTypes from './correspondences-types'

export const startGetCorrespondences = () => ({
    type: CorrespondencesTypes.START_GET_CORRESPONDENCES,
})
export const successGetCorrespondences = (data: ICorrespondencesAdmin) => ({
    type: CorrespondencesTypes.SUCCESS_GET_CORRESPONDENCES,
    correspondences: data,
})
export const errorGetCorrespondences = () => ({
    type: CorrespondencesTypes.ERROR_GET_CORRESPONDENCES,
})
export const finishGetCorrespondences = () => ({
    type: CorrespondencesTypes.FINISH_GET_CORRESPONDENCES,
})

export const startUploadCorrespondences = () => ({
    type: CorrespondencesTypes.START_UPLOAD_CORRESPONDENCES,
})
export const successUploadCorrespondences = () => ({
    type: CorrespondencesTypes.SUCCESS_UPLOAD_CORRESPONDENCES,
})
export const errorUploadCorrespondences = (errors: Array<IError>) => ({
    type: CorrespondencesTypes.ERROR_UPLOAD_CORRESPONDENCES,
    errors,
})
export const finishUploadCorrespondences = () => ({
    type: CorrespondencesTypes.FINISH_UPLOAD_CORRESPONDENCES,
})

export const startEditCorrespondences = () => ({
    type: CorrespondencesTypes.START_EDIT_CORRESPONDENCES,
})
export const successEditCorrespondences = () => ({
    type: CorrespondencesTypes.SUCCESS_EDIT_CORRESPONDENCES,
})
export const errorEditCorrespondences = (errors: Array<IError>) => ({
    type: CorrespondencesTypes.ERROR_EDIT_CORRESPONDENCES,
    errors,
})
export const finishEditCorrespondences = () => ({
    type: CorrespondencesTypes.FINISH_EDIT_CORRESPONDENCES,
})

export const startDeleteCorrespondences = () => ({
    type: CorrespondencesTypes.START_DELETE_CORRESPONDENCES,
})
export const successDeleteCorrespondences = () => ({
    type: CorrespondencesTypes.SUCCESS_DELETE_CORRESPONDENCES,
})
export const errorDeleteCorrespondences = (errors: Array<IError>) => ({
    type: CorrespondencesTypes.ERROR_DELETE_CORRESPONDENCES,
    errors,
})
export const finishDeleteCorrespondences = () => ({
    type: CorrespondencesTypes.FINISH_DELETE_CORRESPONDENCES,
})
