import pinImg from 'assets/images/pin.svg'
import mailImg from 'assets/images/mail.svg'
import tickBoxImg from 'assets/images/tickBox.svg'
import { Text } from 'components/shared'
import { LandingBenefit } from './LandingBenefit'
import { Gutter, Space } from 'styles'
import { Root } from './styles'
import { useTranslation } from 'react-i18next'

const LandingBenefits = () => {
    const { t } = useTranslation()

    return (
        <Root>
            <Text el={'h2'} align={'center'}>
                {t('landingBenefits.benefitsOfUsingOurService')}
            </Text>
            <Space bottom={50} />
            <Gutter size={20} mobile>
                <LandingBenefit
                    image={pinImg}
                    imgHeight={80}
                    imgWidth={80}
                    title={t('landingBenefits.getVirtualEUAddressInstantly')}
                    subtitle={t('landingBenefits.orderAndUseVirtualAddressInstantlyAfterPaymentIsReceivedProofOfAddressWillBePreparedInMinutes')}
                />
                <LandingBenefit
                    image={mailImg}
                    imgHeight={80}
                    imgWidth={80}
                    title={t('landingBenefits.receiveCorrespondenceToYourInbox')}
                    subtitle={t('landingBenefits.experienceFullSpectrumOfVirtualOfficeServicesIncludingConvenientCorrespondenceOpenScanToEmailService')}
                />
                <LandingBenefit
                    image={tickBoxImg}
                    imgHeight={80}
                    imgWidth={80}
                    title={t('landingBenefits.experienceEvenMore')}
                    subtitle={t('landingBenefits.collectLatestUtilityBillsLinkYourAddressWithOtherServicesAndOrderSendingOutCorrespondenceByPostService')}
                />
            </Gutter>
        </Root>
    )
}

export default LandingBenefits
