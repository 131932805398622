import documentImg from 'assets/images/documentCircle.svg'
import { Dispatch } from 'react'
import * as utilityBills from 'reducers/utilityBills'
import { UtilityBills } from 'services'
import { dateAndTimeToLocaleString } from 'utils'

export const getUtilityBills = async (dispatch: Dispatch<utilityBills.IReducer>, query?: string) => {
    try {
        dispatch(utilityBills.startGetUtilityBills())
        const { data }: { data: IUtilityBills } = await UtilityBills.utilityBills(query)
        dispatch(utilityBills.successGetUtilityBills(data))
    } catch (e) {
        dispatch(utilityBills.errorGetUtilityBills())
    }
}

export const getUtilityBillTypes = async (dispatch: Dispatch<utilityBills.IReducer>) => {
    try {
        dispatch(utilityBills.startGetUtilityBillTypes())
        const { data }: { data: Array<IUtilityBillType> } = await UtilityBills.utilityBillTypes()
        dispatch(utilityBills.successGetUtilityBillTypes(data))
    } catch (e) {
        dispatch(utilityBills.errorGetUtilityBillTypes())
    }
}

export const parseUtilityBills = (data: Array<IUtilityBill> = [], header: Array<string>): ITableData => {
    return {
        header,
        body: body(data),
    }
}

const body = (data: Array<IUtilityBill> = []): Array<ITableDataBody> => {
    return data.reduce((acc, cur) => {
        const downloadOrEmpty: ILastTableData = cur.fileId ? { type: 'click', value: 'download' } : { type: 'empty', value: '' }
        return [
            ...acc,
            {
                id: cur.id,
                fileId: cur.fileId,
                firstTableData: {
                    image: documentImg,
                    top: `${cur?.senderName} ${cur?.senderNumber}`,
                    bottom: dateAndTimeToLocaleString(cur?.createdAt),
                },
                mainData: [cur?.addressName, cur?.type],
                lastTableData: [downloadOrEmpty],
            },
        ]
    }, [] as Array<ITableDataBody>)
}
