import { AutocompleteValid, Button, Card, DropZone, ErrorAlert, FileAttachments, Input, SuccessMessage, Text } from 'components/shared'
import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AdminCorrespondencesContext } from 'reducers/admin/correspondences'
import { AdminSubscriptionsContext } from 'reducers/admin/subscriptions'
import * as correspondencesReducers from 'reducers/correspondence'
import { Grid, Space } from 'styles'
import { themeProperties } from 'theme'
import { localStorageHelper } from 'utils'
import MyFormData from 'utils/formData'
import { getCorrespondencesTypes, uploadCorrespondence } from './UploadCorrespondence-state'
interface IProps {
    handleClose: () => void
}

const UploadCorrespondence: FC<IProps> = props => {
    const { t } = useTranslation()
    const { handleSubmit, control } = useForm({ defaultValues: { senderName: '' }, mode: 'onChange' })
    const [correspondenceState, correspondenceDispatch] = useContext(correspondencesReducers.CorrespondenceTypesContext)
    const [adminCorrespondencesState, adminCorrespondencesDispatch] = useContext(AdminCorrespondencesContext)
    const [, adminSubscriptionsDispatch] = useContext(AdminSubscriptionsContext)
    const correspondencesState = useState<ICorrespondencesState>({ senderName: '', type: '', files: [] })
    const [correspondences, setCorrespondences] = correspondencesState
    const subscriptionId = localStorageHelper<string>('subscriptionId')

    useEffect(() => {
        getCorrespondencesTypes(correspondenceDispatch)
    }, [correspondenceDispatch])

    const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setCorrespondences({ ...correspondences, [name]: value })
    }

    const onDrop = useCallback(
        acceptedFiles => {
            acceptedFiles.forEach((file: IBlob) => {
                setCorrespondences({ ...correspondences, files: [file] })
            })
        },
        [correspondences, setCorrespondences]
    )

    const onSubmit = useCallback(
        data => {
            const myFormData = new MyFormData()
            myFormData.appendArray('files', correspondences.files)
            myFormData.appendArrayOfObject([
                { name: 'userSubscriptionId', value: subscriptionId },
                { name: 'senderName', value: data.senderName },
                { name: 'type', value: data.type },
            ])
            uploadCorrespondence(adminCorrespondencesDispatch, adminSubscriptionsDispatch, myFormData.formData)
        },
        [correspondences.files, subscriptionId, adminCorrespondencesDispatch, adminSubscriptionsDispatch]
    )

    const handleSelect = (name: string, value: string | number) => {
        setCorrespondences({ ...correspondences, [name]: value })
    }

    const determineError = adminCorrespondencesState.errors?.UPLOAD_CORRESPONDENCES?.some(Boolean) && (
        <>
            <Text el={'h4'} align="center" color={themeProperties.palette.alert.red}>
                <ErrorAlert errors={adminCorrespondencesState.errors?.UPLOAD_CORRESPONDENCES} />
            </Text>
            <Space bottom={20} />
        </>
    )

    if (adminCorrespondencesState.success.UPLOAD_CORRESPONDENCES) return <SuccessMessage subtitle={t('uploadCorrespondence.correspondenceSuccessfullyUploaded')} handleClose={props.handleClose} />

    return (
        <Card padding={30}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Text el={'h2'}>{t('uploadCorrespondence.uploadCorrespondence')}</Text>
                <Space bottom={20} />
                <Text el={'subtitle'}>{t('uploadCorrespondence.enterCorrespondenceDetails')}</Text>
                <Space bottom={30} />
                <Grid elInRow={2} gap={30}>
                    <Input
                        name="senderName"
                        type="text"
                        label={t('uploadCorrespondence.senderName')}
                        onChange={inputChange}
                        rules={{ required: t('uploadCorrespondence.senderNameIsRequired') }}
                        control={control}
                    />
                    <AutocompleteValid
                        handleChange={handleSelect}
                        selector={'name'}
                        label={t('uploadCorrespondence.Type')}
                        name={'type'}
                        fields={correspondenceState.correspondencesTypes}
                        rules={{ required: t('uploadCorrespondence.typeIsRequired') }}
                        control={control}
                        autoComplete="off"
                    />
                    <DropZone onDrop={onDrop} />
                </Grid>
                <FileAttachments parentState={correspondencesState} />
                <Space bottom={55} />
                <Button full type="submit" disabled={adminCorrespondencesState.loading.UPLOAD_CORRESPONDENCES} loading={adminCorrespondencesState.loading.UPLOAD_CORRESPONDENCES}>
                    {t('uploadCorrespondence.Upload')}
                </Button>
                <Space bottom={30} />
                {determineError}
            </form>
        </Card>
    )
}

export default UploadCorrespondence
