import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import { markAsDownloaded } from './correpondence-state'
import CorrespondenceTypes from './correspondence-types'

export interface IReducer {
    type: CorrespondenceTypes
    correspondences?: ICorrespondences
    correspondencesTypes?: Array<ICorrespondenceTypes>
    correspondencesStatuses?: Array<ICorrespondenceStatuses>
    correspondenceId?: string
}

interface IState {
    correspondences?: ICorrespondences
    correspondencesTypes?: Array<ICorrespondenceTypes>
    correspondencesStatuses?: Array<ICorrespondenceStatuses>
    loading: { GET_CORRESPONDENCES?: Boolean; GET_CORRESPONDENCES_TYPES?: Boolean; GET_CORRESPONDENCES_STATUSES?: Boolean }
    error?: { GET_CORRESPONDENCES?: Boolean; GET_CORRESPONDENCES_TYPES?: Boolean; GET_CORRESPONDENCES_STATUSES?: Boolean }
}

const initialState = {
    correspondences: { items: [], count: 0 },
    correspondencesTypes: [],
    correspondencesStatuses: [],
    loading: { GET_CORRESPONDENCES: false, GET_CORRESPONDENCES_TYPES: false, GET_CORRESPONDENCES_STATUSES: false },
    error: { GET_CORRESPONDENCES: false, GET_CORRESPONDENCES_TYPES: false, GET_CORRESPONDENCES_STATUSES: false },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case CorrespondenceTypes.START_GET_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES: true },
            }
        case CorrespondenceTypes.SUCCESS_GET_CORRESPONDENCES:
            return {
                ...state,
                correspondences: action.correspondences,
                loading: { ...state.loading, GET_CORRESPONDENCES: false },
            }
        case CorrespondenceTypes.ERROR_GET_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES: false },
                error: { ...state.error, GET_CORRESPONDENCES: true },
            }
        case CorrespondenceTypes.FINISH_GET_CORRESPONDENCES:
            return {
                ...state,
                correspondences: { items: [], count: 0 },
                loading: { ...state.loading, GET_CORRESPONDENCES: false },
                error: { ...state.error, GET_CORRESPONDENCES: false },
            }
        case CorrespondenceTypes.START_GET_CORRESPONDENCES_TYPES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES_TYPES: true },
            }
        case CorrespondenceTypes.SUCCESS_GET_CORRESPONDENCES_TYPES:
            return {
                ...state,
                correspondencesTypes: action.correspondencesTypes,
                loading: { ...state.loading, GET_CORRESPONDENCES_TYPES: false },
            }
        case CorrespondenceTypes.ERROR_GET_CORRESPONDENCES_TYPES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES_TYPES: false },
                error: { ...state.error, GET_CORRESPONDENCES_TYPES: true },
            }
        case CorrespondenceTypes.FINISH_GET_CORRESPONDENCES_TYPES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES_TYPES: false },
                error: { ...state.error, GET_CORRESPONDENCES_TYPES: false },
            }

        case CorrespondenceTypes.START_GET_CORRESPONDENCES_STATUSES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES_STATUSES: true },
            }
        case CorrespondenceTypes.SUCCESS_GET_CORRESPONDENCES_STATUSES:
            return {
                ...state,
                correspondencesStatuses: action.correspondencesStatuses,
                loading: { ...state.loading, GET_CORRESPONDENCES_STATUSES: false },
            }
        case CorrespondenceTypes.ERROR_GET_CORRESPONDENCES_STATUSES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES_STATUSES: false },
                error: { ...state.error, GET_CORRESPONDENCES_STATUSES: true },
            }
        case CorrespondenceTypes.FINISH_GET_CORRESPONDENCES_STATUSES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES_STATUSES: false },
                error: { ...state.error, GET_CORRESPONDENCES_STATUSES: false },
            }

        case CorrespondenceTypes.START_CORRESPONDENCE_IS_DOWNLOADED:
            return {
                ...state,
                loading: { ...state.loading, CORRESPONDENCE_IS_DOWNLOADED: true },
            }
        case CorrespondenceTypes.SUCCESS_CORRESPONDENCE_IS_DOWNLOADED:
            return {
                ...state,
                correspondences: markAsDownloaded(state.correspondences, action.correspondenceId),
                loading: { ...state.loading, CORRESPONDENCE_IS_DOWNLOADED: false },
            }
        case CorrespondenceTypes.ERROR_CORRESPONDENCE_IS_DOWNLOADED:
            return {
                ...state,
                loading: { ...state.loading, CORRESPONDENCE_IS_DOWNLOADED: false },
                error: { ...state.error, CORRESPONDENCE_IS_DOWNLOADED: true },
            }
        case CorrespondenceTypes.FINISH_CORRESPONDENCE_IS_DOWNLOADED:
            return {
                ...state,
                loading: { ...state.loading, CORRESPONDENCE_IS_DOWNLOADED: false },
                error: { ...state.error, CORRESPONDENCE_IS_DOWNLOADED: false },
            }
        default:
            return state
    }
}

export const CorrespondenceTypesContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const CorrespondenceTypesContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <CorrespondenceTypesContext.Provider value={[state, dispatch]}>{props.children}</CorrespondenceTypesContext.Provider>
}
