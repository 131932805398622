import styled from 'styled-components'

export const Root = styled.div`
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.primary.mainBlur};
    padding: 16px;
    font-weight: 600;
`
