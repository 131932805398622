import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { ConfirmCardPaymentData, loadStripe, PaymentIntentResult } from '@stripe/stripe-js'
import { Button, Card, CheckBoxWithTitle, ErrorAlert, InputNoValid, StripeTextFieldCVC, StripeTextFieldExpiry, StripeTextFieldNumber, SuccessMessage, Text } from 'components/shared'
import React, { ChangeEvent, FC, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import * as payments from 'reducers/payments'
import { navigationRoutes } from 'routes/Routes'
import { Grid, Row, Space } from 'styles'
import { themeProperties } from 'theme'
import { combineArrays, config, toLocaleStringWithCurrency } from 'utils'
import { StartGetClientPaymentSecret } from './PayNow-state'

const stripePromise = loadStripe(config.stripeKey)

const PayNow: FC = () => {
    return (
        <Elements stripe={stripePromise}>
            <Form />
        </Elements>
    )
}

const Form = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const stripe = useStripe()
    const elements = useElements()
    const [paymentsContextState, paymentsContextDispatch] = useContext(payments.PaymentsContext)
    const [paymentValues, setPaymentValues] = useState<IBuySubscriptionState>({ name: '', checked: false, clientSecret: '', errors: [] })
    const [stripePayload, setStripePayload] = useState<IStripePaymentState<PaymentIntentResult>>({ startPayment: false })

    const paymentBalance = paymentsContextState.paymentsBalances?.[0]
    const unpaidAmount = paymentBalance ? toLocaleStringWithCurrency(paymentBalance?.balance, paymentBalance?.currencyCode) : null

    const [state, setState] = useState({
        cardNumberComplete: false,
        expiredComplete: false,
        cvcComplete: false,
        cardNumberError: '',
        expiredError: '',
        cvcError: '',
    })

    useEffect(() => {
        StartGetClientPaymentSecret(paymentsContextDispatch, paymentBalance?.paymentIds)
    }, [paymentBalance?.paymentIds, paymentsContextDispatch])

    const onElementChange = useCallback(
        (field: any, errorField: any) =>
            ({ complete, error = { message: null } }: { complete: boolean; error: any }) => {
                setState({ ...state, [field]: complete, [errorField]: error.message })
            },
        [state]
    )

    const onCheck = useCallback(
        (name: string, value: boolean) => {
            setPaymentValues({ ...paymentValues, [name]: value })
        },
        [paymentValues]
    )

    const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setPaymentValues({ ...paymentValues, [name]: value })
    }

    const handleSubmit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault()
            if (!stripe || !elements) return

            setStripePayload({ ...stripePayload, startPayment: true })
            const secret = paymentsContextState.clientSecret || ''
            try {
                const payload = await stripe.confirmCardPayment(secret, {
                    payment_method: {
                        type: 'card',
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: paymentValues.name,
                        },
                    },
                } as ConfirmCardPaymentData | undefined)
                setStripePayload({ ...stripePayload, payload, startPayment: false })
            } catch (e: any) {
                console.error('stripe payload error:', e)
            }
        },
        [elements, paymentValues.name, paymentsContextState.clientSecret, stripe, stripePayload]
    )

    const determineError = paymentsContextState.error.GET_PAYMENTS_CLIENT_SECRET && (
        <Text el="h4" align="center" color={themeProperties.palette.alert.red}>
            <Space bottom={20} />
            <ErrorAlert errors={combineArrays(paymentsContextState.error.GET_PAYMENTS_CLIENT_SECRET)} />
        </Text>
    )

    const determineStripeError = stripePayload.payload?.error && (
        <Text el="h4" align="center" color={themeProperties.palette.alert.red}>
            <Space bottom={20} />
            {stripePayload.payload.error.message}
        </Text>
    )

    const { cardNumberError, expiredError, cvcError } = state

    if (!!stripePayload.payload?.paymentIntent)
        return (
            <Row content="center">
                <SuccessMessage width={570} subtitle={`${t('payNow.paymentOf')} ${unpaidAmount} ${t('payNow.successfullyCompleted')}`} handleClose={() => history.push(navigationRoutes.user.home)} />
            </Row>
        )

    return (
        <Card padding={30} content="center">
            <form onSubmit={handleSubmit}>
                <Text el="h2">
                    {t('payNow.totalToPay')}: {unpaidAmount}
                </Text>
                <Space bottom={20} />
                <Text el="subtitle">{t('payNow.enterYourCreditCardDetails')}</Text>
                <Space bottom={30} />
                <Grid elInRow={2} gap={30}>
                    <InputNoValid name="name" type="text" label={t('payNow.nameOnCard')} onChange={inputChange} placeholder={t('payNow.nameOnCard')} InputLabelProps={{ shrink: true }} />
                    <StripeTextFieldNumber error={Boolean(cardNumberError)} labelErrorMessage={cardNumberError} onChange={onElementChange('cardNumberComplete', 'cardNumberError')} />
                    <StripeTextFieldExpiry error={Boolean(expiredError)} labelErrorMessage={expiredError} onChange={onElementChange('expiredComplete', 'expiredError')} />
                    <StripeTextFieldCVC error={Boolean(cvcError)} labelErrorMessage={cvcError} onChange={onElementChange('cvcComplete', 'cvcError')} />
                </Grid>
                <Space bottom={30} />
                <CheckBoxWithTitle name="checked" label={`${t('payNow.iAgreeToPay')} ${unpaidAmount}`} onCheck={onCheck} />
                {determineError}
                {determineStripeError}
                <Space bottom={30} />
                <Button full type="submit" disabled={!paymentValues.checked || stripePayload.startPayment} loading={stripePayload.startPayment}>
                    {t('payNow.payWithCard')}
                </Button>
            </form>
            <Space bottom={30} />
        </Card>
    )
}

export default PayNow
