import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import { increaseCorrespondenceCount } from './subscriptions-state'
import SubscriptionsTypes from './subscriptions-types'

export interface IReducer {
    id?: string
    subscriptions?: IUserSubscriptionsAdmin
    type: SubscriptionsTypes
}

export interface IState {
    id?: string
    subscriptions?: Partial<IUserSubscriptionsAdmin>
    loading?: { SET_SUBSCRIPTIONS_ID?: boolean; GET_SUBSCRIPTIONS?: boolean }
    error?: { SET_SUBSCRIPTIONS_ID?: boolean; GET_SUBSCRIPTIONS?: boolean }
}

const initialState = {
    subscriptions: {},
    id: '',
    loading: { SET_SUBSCRIPTIONS_ID: false, GET_SUBSCRIPTIONS: false },
    error: { SET_SUBSCRIPTIONS_ID: false, GET_SUBSCRIPTIONS: false },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case SubscriptionsTypes.START_SET_SUBSCRIPTIONS_ID:
            return {
                ...state,
                loading: { ...state.loading, SET_SUBSCRIPTIONS_ID: true },
            }
        case SubscriptionsTypes.SUCCESS_SET_SUBSCRIPTIONS_ID:
            return {
                ...state,
                id: action.id,
                loading: { ...state.loading, SET_SUBSCRIPTIONS_ID: false },
            }
        case SubscriptionsTypes.FINISH_SET_SUBSCRIPTIONS_ID:
            return {
                ...state,
                id: '',
                loading: { ...state.loading, SET_SUBSCRIPTIONS_ID: false },
                error: { ...state.error, SET_SUBSCRIPTIONS_ID: false },
            }
        case SubscriptionsTypes.ERROR_SET_SUBSCRIPTIONS_ID:
            return {
                ...state,
                loading: { ...state.loading, SET_SUBSCRIPTIONS_ID: false },
                error: { ...state.error, SET_SUBSCRIPTIONS_ID: true },
            }

        case SubscriptionsTypes.START_GET_SUBSCRIPTIONS:
            return {
                ...state,
                loading: { ...state.loading, GET_SUBSCRIPTIONS: true },
            }
        case SubscriptionsTypes.SUCCESS_GET_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.subscriptions,
                loading: { ...state.loading, GET_SUBSCRIPTIONS: false },
            }
        case SubscriptionsTypes.FINISH_GET_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: {},
                loading: { ...state.loading, GET_SUBSCRIPTIONS: false },
                error: { ...state.error, GET_SUBSCRIPTIONS: false },
            }
        case SubscriptionsTypes.ERROR_GET_SUBSCRIPTIONS:
            return {
                ...state,
                loading: { ...state.loading, GET_SUBSCRIPTIONS: false },
                error: { ...state.error, GET_SUBSCRIPTIONS: true },
            }

        case SubscriptionsTypes.START_INCREASE_CORRESPONDENCE_COUNT:
            return {
                ...state,
                loading: { ...state.loading, INCREASE_CORRESPONDENCE_COUNT: true },
            }
        case SubscriptionsTypes.SUCCESS_INCREASE_CORRESPONDENCE_COUNT:
            return {
                ...state,
                subscriptions: increaseCorrespondenceCount(state.subscriptions),
                loading: { ...state.loading, INCREASE_CORRESPONDENCE_COUNT: false },
            }
        case SubscriptionsTypes.FINISH_INCREASE_CORRESPONDENCE_COUNT:
            return {
                ...state,
                loading: { ...state.loading, INCREASE_CORRESPONDENCE_COUNT: false },
                error: { ...state.error, INCREASE_CORRESPONDENCE_COUNT: false },
            }
        case SubscriptionsTypes.ERROR_INCREASE_CORRESPONDENCE_COUNT:
            return {
                ...state,
                loading: { ...state.loading, INCREASE_CORRESPONDENCE_COUNT: false },
                error: { ...state.error, INCREASE_CORRESPONDENCE_COUNT: true },
            }
        default:
            return state
    }
}

export const AdminSubscriptionsContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const AdminSubscriptionsContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <AdminSubscriptionsContext.Provider value={[state, dispatch]}>{props.children}</AdminSubscriptionsContext.Provider>
}
