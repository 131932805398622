import styled from 'styled-components'

export const ViewAllButton = styled.div`
    position: relative;
    bottom: -22px;
    width: 110px;
    height: 32px;
    display: flex;
    align-items: end;
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.palette.primary.light};
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${({ theme }) => theme.palette.primary.background};
    }
`
