import VirtualAddressesTypes from './virtualAddresses-types'

export const startGetSubscriptions = () => ({
    type: VirtualAddressesTypes.START_GET_SUBSCRIPTIONS,
})
export const successGetSubscriptions = (data: Array<IUserSubscription>) => ({
    type: VirtualAddressesTypes.SUCCESS_GET_SUBSCRIPTIONS,
    subscriptions: data,
})
export const errorGetSubscriptions = () => ({
    type: VirtualAddressesTypes.ERROR_GET_SUBSCRIPTIONS,
})
export const finishGetSubscriptions = () => ({
    type: VirtualAddressesTypes.FINISH_GET_SUBSCRIPTIONS,
})
