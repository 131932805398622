import React, { FC } from 'react'
import { CheckBoxSc, Root } from './styles'

interface IProps {
    name: string
    label: string
    onCheck: (name: string, value: boolean) => void
}

const CheckBoxWithTitle: FC<IProps> = props => {
    return <Root control={<CheckBoxSc name={props.name} onChange={e => props.onCheck(props.name, e.target.checked)} />} label={props.label}></Root>
}

export default CheckBoxWithTitle
