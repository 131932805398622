import React, { FC } from 'react'
import { Gutter, Oval, Row } from 'styles'

interface IProps {
    textInCircle: string
    text: string
}

const HeaderWithTextCircle: FC<IProps> = props => {
    return (
        <Row>
            <Gutter size={10} align={'center'}>
                <Oval width={32} height={32}>
                    {props.textInCircle}
                </Oval>
                <div>{props.text}</div>
            </Gutter>
        </Row>
    )
}

export default HeaderWithTextCircle
