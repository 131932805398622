import React, { FC, useState, Children, isValidElement, cloneElement, useCallback, memo, ReactNode } from 'react'

const DisplayStepper: FC = memo(props => {
    const [switcherIndex, setSwitcherIndex] = useState(0)

    const next = useCallback(() => setSwitcherIndex(Math.min(Children.count(props.children), +1)), [props.children, setSwitcherIndex])

    const back = useCallback(() => setSwitcherIndex(Math.max(0, switcherIndex - 1)), [setSwitcherIndex, switcherIndex])

    const childWithProps = Children.toArray(props.children)
        .filter((_, i: number) => i === switcherIndex)
        .map((children: ReactNode) => {
            if (isValidElement(children)) {
                return cloneElement(children, { next, back })
            } else {
                return children
            }
        })

    return <>{childWithProps}</>
})

export default DisplayStepper
