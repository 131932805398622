import { UtilityBills } from './UtilityBills'
import { Home } from './Home'
import { Correspondence } from './Correspondence'
import { Sendouts } from './Sendouts'
import { Payments } from './Payments'

export const tabComponents = [
    {
        name: 'Home',
        subName: 'General overview',
        component: <Home />,
    },
    {
        name: 'Payments',
        subName: 'Receipts for services',
        component: <Payments />,
    },
    {
        name: 'Correspondence',
        subName: 'Received mails to address',
        component: <Correspondence />,
    },
    {
        name: 'Utility bills',
        subName: 'POA documents',
        component: <UtilityBills />,
    },
    {
        name: 'Send outs',
        subName: 'Docs delivery service',
        component: <Sendouts />,
    },
] as Array<ITab>
