import React, { FC } from 'react'

interface IProps {
    errors?: Array<IError>
}

const ErrorAlert: FC<IProps> = props => {
    return (
        <>
            {props.errors?.map((error: IError, i: number) => (
                <div key={i}>{error.message}</div>
            ))}
        </>
    )
}

export default ErrorAlert
