import { Dispatch } from 'react'
import * as subscriptions from 'reducers/subscriptions'
import { Subscriptions } from 'services'
import { secondsToMonths, toLocaleStringWithCurrency } from 'utils'

export const getAvailableSubscriptions = async (dispatch: Dispatch<subscriptions.IReducer>) => {
    try {
        dispatch(subscriptions.startGetAvailableSubscriptions())
        const { data }: { data: Array<IAvailableSubscription> } = await Subscriptions.availableSubscriptions()
        dispatch(subscriptions.successGetAvailableSubscriptions(data))
    } catch (e: any) {
        dispatch(subscriptions.errorGetAvailableSubscriptions())
    }
}

export const parseAddress = (data: Array<IAvailableSubscription> = []): Array<ISubscription> => {
    return data.reduce((acc, cur) => {
        return [
            ...acc,
            {
                id: cur.id,
                duration: secondsToMonths(cur.duration?.totalSeconds),
                freeCorrespondenceCount: cur.freeCorrespondenceCount,
                price: toLocaleStringWithCurrency(cur.price.amount, cur.price.currencyCode),
                correspondenceSendPrice: toLocaleStringWithCurrency(cur.correspondenceSendPrice.amount, cur.correspondenceSendPrice.currencyCode),
                extraCorrespondencePrice: toLocaleStringWithCurrency(cur.extraCorrespondencePrice.amount, cur.extraCorrespondencePrice.currencyCode),
                isCorrespondenceIncluded: cur.isCorrespondenceIncluded,
            },
        ]
    }, [] as Array<ISubscription>)
}
