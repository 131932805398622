enum SubscriptionsTypes {
    START_GET_AVAILABLE_SUBSCRIPTIONS = 'START_GET_AVAILABLE_SUBSCRIPTIONS',
    SUCCESS_GET_AVAILABLE_SUBSCRIPTIONS = 'SUCCESS_GET_AVAILABLE_SUBSCRIPTIONS',
    FINISH_GET_AVAILABLE_SUBSCRIPTIONS = 'FINISH_GET_AVAILABLE_SUBSCRIPTIONS',
    ERROR_GET_AVAILABLE_SUBSCRIPTIONS = 'ERROR_GET_AVAILABLE_SUBSCRIPTIONS',

    START_SET_SUBSCRIPTION_CAROUSEL_INDEX = 'START_SET_SUBSCRIPTION_CAROUSEL_INDEX',
    SUCCESS_SET_SUBSCRIPTION_CAROUSEL_INDEX = 'SUCCESS_SET_SUBSCRIPTION_CAROUSEL_INDEX',
    FINISH_SET_SUBSCRIPTION_CAROUSEL_INDEX = 'FINISH_SET_SUBSCRIPTION_CAROUSEL_INDEX',
    ERROR_SET_SUBSCRIPTION_CAROUSEL_INDEX = 'ERROR_SET_SUBSCRIPTION_CAROUSEL_INDEX',

    START_GET_CLIENT_SECRET = 'START_GET_CLIENT_SECRET',
    SUCCESS_GET_CLIENT_SECRET = 'SUCCESS_GET_CLIENT_SECRET',
    FINISH_GET_CLIENT_SECRET = 'FINISH_GET_CLIENT_SECRET',
    ERROR_GET_CLIENT_SECRET = 'ERROR_GET_CLIENT_SECRET',
}
export default SubscriptionsTypes
