enum PaymentsTypes {
    START_GET_PAYMENTS = 'START_GET_PAYMENTS',
    SUCCESS_GET_PAYMENTS = 'SUCCESS_GET_PAYMENTS',
    FINISH_GET_PAYMENTS = 'FINISH_GET_PAYMENTS',
    ERROR_GET_PAYMENTS = 'ERROR_GET_PAYMENTS',
    START_GET_PAYMENTS_STATUSES = 'START_GET_PAYMENTS_STATUSES',
    SUCCESS_GET_PAYMENTS_STATUSES = 'SUCCESS_GET_PAYMENTS_STATUSES',
    FINISH_GET_PAYMENTS_STATUSES = 'FINISH_GET_PAYMENTS_STATUSES',
    ERROR_GET_PAYMENTS_STATUSES = 'ERROR_GET_PAYMENTS_STATUSES',
    START_GET_PAYMENTS_BALANCE = 'START_GET_PAYMENTS_BALANCE',
    SUCCESS_GET_PAYMENTS_BALANCE = 'SUCCESS_GET_PAYMENTS_BALANCE',
    FINISH_GET_PAYMENTS_BALANCE = 'FINISH_GET_PAYMENTS_BALANCE',
    ERROR_GET_PAYMENTS_BALANCE = 'ERROR_GET_PAYMENTS_BALANCE',
    START_GET_PAYMENTS_CLIENT_SECRET = 'START_GET_PAYMENTS_CLIENT_SECRET',
    SUCCESS_GET_PAYMENTS_CLIENT_SECRET = 'SUCCESS_GET_PAYMENTS_CLIENT_SECRET',
    FINISH_GET_PAYMENTS_CLIENT_SECRET = 'FINISH_GET_PAYMENTS_CLIENT_SECRET',
    ERROR_GET_PAYMENTS_CLIENT_SECRET = 'ERROR_GET_PAYMENTS_CLIENT_SECRET',
    START_CANCEL_PAYMENT = 'START_CANCEL_PAYMENT',
    SUCCESS_CANCEL_PAYMENT = 'SUCCESS_CANCEL_PAYMENT',
    FINISH_CANCEL_PAYMENT = 'FINISH_CANCEL_PAYMENT',
    ERROR_CANCEL_PAYMENT = 'ERROR_CANCEL_PAYMENT',
}
export default PaymentsTypes
