import { Button } from 'components/shared'
import { Space, Gutter } from 'styles'
import { Root } from './styles'
import { Text, Image } from 'components/shared'
import { useHistory } from 'react-router'

import IllustrationImg from 'assets/images/Illustration.svg'
import { useTranslation } from 'react-i18next'

const LandingSignUp = () => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Root>
            <Image height={290} width={391} image={IllustrationImg} />
            <Space bottom={50} />
            <Text el={'h1'} align={'center'}>
                {t('landingSignUp.allInOneVirtualOfficeSolution')}
            </Text>
            <Space bottom={50} />
            <Gutter size={15} mobile>
                <Button width={288} height={50} onClick={() => history.push('sign-up')}>
                    {t('landingSignUp.signUp')}
                </Button>
                <Button bordered width={288} height={50} onClick={() => history.push('sign-in')}>
                    {t('landingSignUp.logIn')}
                </Button>
            </Gutter>
        </Root>
    )
}

export default LandingSignUp
