import { createTheme } from '@mui/material/styles'

export const themeProperties = {
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 16,
        color: '#666666',
    },
    palette: {
        primary: {
            main: '#874BBB',
            secondary: '#878E9F',
            light: '#DDDDDD',
            background: '#F6F6F6',
            white: '#FFFFFF',
            mainBlur: '#874BBB14',
            disable: '#a97ad2',
        },
        secondary: {
            main: '#FFFFFF',
            box: '#EEEEEE',
        },
        button: {
            hover: '#a032ff',
        },
        font: {
            main: '#222222',
            secondary: '#666666',
            light: '#999999',
        },
        alert: {
            green: '#60D956',
            red: '#E93948',
            redHover: '#d32f2f',
            yellow: '#FBBC04',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
        max: {
            xs: `(max-width: 0px)`,
            sm: `(max-width: 600px)`,
            md: `(max-width: 960px)`,
            lg: `(max-width: 1280px)`,
            xl: `(max-width: 1920px)`,
        },
    },
}

export const muiTheme = createTheme(themeProperties)
