import { FC } from 'react'
import { Root, Container } from './styles'

interface IProps {
    color?: string
}

const PageContainer: FC<IProps> = props => {
    return (
        <Root color={props.color}>
            <Container>{props.children}</Container>
        </Root>
    )
}

export default PageContainer
