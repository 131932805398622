/**
 *
 * @param blob Passing blob to download file
 */
export const downloadFileInBrowser = (blob: IBlob) => {
    const file = new Blob([blob], { type: blob.type })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = 'NewDocument'
    document.body.appendChild(element)
    element.click()
}

export const otherFileType = {
    'application/pdf': '.pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
} as { [key: string]: string }
