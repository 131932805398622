import styled from 'styled-components'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

export const Root = styled(FormControlLabel)``

export const CheckBoxSc = styled(Checkbox)`
    svg {
        width: 33px;
        height: 33px;
    }
`
