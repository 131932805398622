import { Dispatch } from 'react'
import * as verifyDocument from 'reducers/verifyDocument'
import { Files } from 'services'
import { camelToText, dateToLocaleString } from 'utils'

export const startVerify = async (dispatch: Dispatch<verifyDocument.IReducer>, id: string) => {
    try {
        dispatch(verifyDocument.startGetInfo())
        const { data }: { data: IDocumentInfo } = await Files.info(id)
        dispatch(verifyDocument.successGetInfo(data))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(verifyDocument.errorGetInfo(errors))
    }
}

export const flatterItems = (items?: Partial<IDocumentInfo>): Array<IItem> => {
    const newItems = {
        no: items?.no,
        date: items?.date && dateToLocaleString(items?.date),
        amount: items?.amount,
    }

    return Object.entries(newItems).map(([name, value]) => {
        return { name: camelToText(name), value }
    })
}
