import { HeaderFooter } from 'layouts'
import { SignUpForm } from 'components/Forms/SignUpForm'
import { Row, Space } from 'styles'

const SignUp = () => {
    return (
        <HeaderFooter page={'signUp'}>
            <Space bottom={83} />
            <Row content="center">
                <SignUpForm />
            </Row>
        </HeaderFooter>
    )
}

export default SignUp
