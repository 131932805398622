import UtilityBillsTypes from './utilityBills-types'

export const startGetUtilityBills = () => ({
    type: UtilityBillsTypes.START_GET_UTILITY_BILLS,
})
export const successGetUtilityBills = (data: IUtilityBillsAdmin) => ({
    type: UtilityBillsTypes.SUCCESS_GET_UTILITY_BILLS,
    utilityBills: data,
})
export const errorGetUtilityBills = () => ({
    type: UtilityBillsTypes.ERROR_GET_UTILITY_BILLS,
})
export const finishGetUtilityBills = () => ({
    type: UtilityBillsTypes.FINISH_GET_UTILITY_BILLS,
})
