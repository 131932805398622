import SubscriptionsTypes from './subscriptions-types'

export const startSetSubscriptionsId = () => ({
    type: SubscriptionsTypes.START_SET_SUBSCRIPTIONS_ID,
})
export const successSetSubscriptionsId = (id: string) => ({
    type: SubscriptionsTypes.SUCCESS_SET_SUBSCRIPTIONS_ID,
    id,
})
export const finishSetSubscriptionsId = () => ({
    type: SubscriptionsTypes.FINISH_SET_SUBSCRIPTIONS_ID,
})
export const errorSetSubscriptionsId = () => ({
    type: SubscriptionsTypes.ERROR_SET_SUBSCRIPTIONS_ID,
})

export const startGetSubscriptions = () => ({
    type: SubscriptionsTypes.START_GET_SUBSCRIPTIONS,
})
export const successGetSubscriptions = (data: IUserSubscriptionsAdmin) => ({
    type: SubscriptionsTypes.SUCCESS_GET_SUBSCRIPTIONS,
    subscriptions: data,
})
export const finishGetSubscriptions = () => ({
    type: SubscriptionsTypes.FINISH_GET_SUBSCRIPTIONS,
})
export const errorGetSubscriptions = () => ({
    type: SubscriptionsTypes.ERROR_GET_SUBSCRIPTIONS,
})

export const startIncreaseCorrespondenceCount = () => ({
    type: SubscriptionsTypes.START_INCREASE_CORRESPONDENCE_COUNT,
})
export const successIncreaseCorrespondenceCount = () => ({
    type: SubscriptionsTypes.SUCCESS_INCREASE_CORRESPONDENCE_COUNT,
})
export const finishIncreaseCorrespondenceCount = () => ({
    type: SubscriptionsTypes.FINISH_INCREASE_CORRESPONDENCE_COUNT,
})
export const errorIncreaseCorrespondenceCount = () => ({
    type: SubscriptionsTypes.ERROR_INCREASE_CORRESPONDENCE_COUNT,
})
