import { Dialog } from '@mui/material'
import React, { FC, useContext } from 'react'
import { EditDeleteCorrespondence } from '.'
import * as adminCorrespondences from 'reducers/admin/correspondences'
import { CloseButton } from 'styles'

export interface IPros {
    isOpen: ITableDataBody | null
    close: () => void
}

const EditDeleteCorrespondenceModal: FC<IPros> = props => {
    const [state, dispatch] = useContext(adminCorrespondences.AdminCorrespondencesContext)

    const handleClose = () => {
        props.close()

        if (state.success.EDIT_CORRESPONDENCES || state.errors?.EDIT_CORRESPONDENCES?.some(Boolean)) {
            dispatch(adminCorrespondences.finishEditCorrespondences())
        }
        if (state.success.DELETE_CORRESPONDENCES || state.errors?.DELETE_CORRESPONDENCES?.some(Boolean)) {
            dispatch(adminCorrespondences.finishDeleteCorrespondences())
        }
    }

    return (
        <Dialog onClose={handleClose} open={!!props.isOpen} fullWidth>
            <CloseButton onClick={handleClose} />
            <EditDeleteCorrespondence item={props.isOpen} handleClose={handleClose} />
        </Dialog>
    )
}

export default EditDeleteCorrespondenceModal
