import styled from 'styled-components'

interface IRoot {}

export const Root = styled.div<IRoot>`
    background-color: ${props => props.color || props.theme.palette.primary.background};
    min-height: 100vh;
`

export const Container = styled.div`
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 15px;

    /* @media ${({ theme }) => theme.breakpoints.max.sm} { */
    /* } */
`
