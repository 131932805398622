import { Dispatch } from 'react'
import * as virtualAddresses from 'reducers/virtualAddresses'
import { UserSubscriptions } from 'services'

export const getSubscriptions = async (dispatch: Dispatch<virtualAddresses.IReducer>) => {
    try {
        dispatch(virtualAddresses.startGetSubscriptions())
        const { data }: { data: Array<IUserSubscription> } = await UserSubscriptions.subscriptions()
        dispatch(virtualAddresses.successGetSubscriptions(data))
    } catch (e) {
        dispatch(virtualAddresses.errorGetSubscriptions())
    }
}
