import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import VerifyDocumentTypes from './verifyDocument-types'

export interface IReducer {
    verifyDocument?: IDocumentInfo
    type: VerifyDocumentTypes
    errors?: Array<IError>
}

export interface IState {
    verifyDocument?: Partial<IDocumentInfo>
    loading: { GET_INFO: boolean }
    errors?: Array<IError>
}

const initialState = {
    verifyDocument: {},
    loading: { GET_INFO: false },
    errors: [],
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case VerifyDocumentTypes.START_GET_INFO:
            return {
                ...state,
                loading: { GET_INFO: true },
            }
        case VerifyDocumentTypes.SUCCESS_GET_INFO:
            return {
                ...state,
                verifyDocument: action.verifyDocument,
                loading: { GET_INFO: false },
            }
        case VerifyDocumentTypes.ERROR_GET_INFO:
            return {
                ...state,
                loading: { GET_INFO: false },
                errors: action.errors,
            }
        case VerifyDocumentTypes.FINISH_GET_INFO:
            return {
                ...state,
                verifyDocument: {},
                loading: { GET_INFO: false },
                errors: [],
            }
        default:
            return state
    }
}

export const VerifyDocumentContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const VerifyDocumentContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <VerifyDocumentContext.Provider value={[state, dispatch]}>{props.children}</VerifyDocumentContext.Provider>
}
