import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import { completeSendOut } from './sendouts-state'
import SendoutsTypes from './sendouts-types'

export interface IReducer {
    sendouts?: Array<ISendOutAdmin>
    error?: Array<IError>
    type: SendoutsTypes
    id?: string
}

export interface IState {
    sendouts?: Array<ISendOutAdmin>
    loading: { GET_SEND_OUTS_ADMIN?: boolean; MAKE_AS_SEND?: boolean }
    error?: { GET_SEND_OUTS_ADMIN?: boolean; MAKE_AS_SEND?: Array<IError> }
}

const initialState = {
    sendouts: [],
    loading: { GET_SEND_OUTS_ADMIN: false, MAKE_AS_SEND: false },
    error: { GET_SEND_OUTS_ADMIN: false, MAKE_AS_SEND: [] },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case SendoutsTypes.START_GET_SEND_OUTS_ADMIN:
            return {
                ...state,
                loading: { ...state.loading, GET_SEND_OUTS_ADMIN: true },
            }
        case SendoutsTypes.SUCCESS_GET_SEND_OUTS_ADMIN:
            return {
                ...state,
                sendouts: action.sendouts,
                loading: { ...state.loading, GET_SEND_OUTS_ADMIN: false },
            }
        case SendoutsTypes.FINISH_GET_SEND_OUTS_ADMIN:
            return {
                ...state,
                sendouts: [],
                loading: { ...state.loading, GET_SEND_OUTS_ADMIN: false },
                error: { ...state.error, GET_SEND_OUTS_ADMIN: false },
            }
        case SendoutsTypes.ERROR_GET_SEND_OUTS_ADMIN:
            return {
                ...state,
                loading: { ...state.loading, GET_SEND_OUTS_ADMIN: false },
                error: { ...state.error, GET_SEND_OUTS_ADMIN: true },
            }
        case SendoutsTypes.START_MAKE_AS_SEND:
            return {
                ...state,
                loading: { ...state.loading, MAKE_AS_SEND: true },
                error: { ...state.error, MAKE_AS_SEND: [] },
            }
        case SendoutsTypes.SUCCESS_MAKE_AS_SEND:
            return {
                ...state,
                sendouts: completeSendOut(action.id, state.sendouts),
                loading: { ...state.loading, MAKE_AS_SEND: false },
                error: { ...state.error, MAKE_AS_SEND: [] },
            }
        case SendoutsTypes.FINISH_MAKE_AS_SEND:
            return {
                ...state,
                loading: { ...state.loading, MAKE_AS_SEND: false },
                error: { ...state.error, MAKE_AS_SEND: [] },
            }
        case SendoutsTypes.ERROR_MAKE_AS_SEND:
            return {
                ...state,
                loading: { ...state.loading, MAKE_AS_SEND: false },
                error: { ...state.error, MAKE_AS_SEND: action.error },
            }
        default:
            return state
    }
}

export const AdminSendoutsContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const AdminSendoutsContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <AdminSendoutsContext.Provider value={[state, dispatch]}>{props.children}</AdminSendoutsContext.Provider>
}
