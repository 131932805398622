import { Button, Image, Text } from 'components/shared'
import React from 'react'
import { Space } from 'styles'

import IllustrationImg from 'assets/images/Illustration.svg'
import { Root } from './styles'
import { navigationRoutes } from 'routes/Routes'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

const OrderAddress = () => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Root>
            <Space bottom={50} />
            <Image height={160} width={215} image={IllustrationImg} />
            <Space bottom={35} />
            <Text el={'h1'} align={'center'}>
                {t('orderAddress.youHaveNoVirtualAddresses')}
            </Text>
            <Space bottom={15} />
            <Text el={'subtitle'} align={'center'}>
                {t('orderAddress.orderVirtualEUAddressForYourPersonalOrBusinessUse')}
            </Text>
            <Space bottom={30} />
            <Button width={220} onClick={() => history.push(navigationRoutes.orderAddress)}>
                {t('orderAddress.orderAddress')}
            </Button>
            <Space bottom={50} />
        </Root>
    )
}

export default OrderAddress
