import { Dispatch } from 'react'
import * as download from 'reducers/download'
import { downloadFileInBrowser } from 'utils'
import { Files, FilesAdmin } from 'services'

export const downloadFile = async (dispatch: Dispatch<download.IReducer>, id: string) => {
    try {
        dispatch(download.startFileDownload())
        const { data } = await Files.download(id)
        downloadFileInBrowser(data)
        dispatch(download.successFileDownload())
    } catch (e) {
        dispatch(download.errorFileDownload())
    }
}
export const downloadAdminFile = async (dispatch: Dispatch<download.IReducer>, id: string) => {
    try {
        dispatch(download.startFileDownload())
        const { data } = await FilesAdmin.download(id)
        downloadFileInBrowser(data)
        dispatch(download.successFileDownload())
    } catch (e) {
        dispatch(download.errorFileDownload())
    }
}
