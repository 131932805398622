import { FC, useCallback, useState } from 'react'
import { Root, TextFieldStyle } from './styles'

interface IProps {
    name: string
    fields: any
    selector?: 'id' | 'name' | 'countryCode'
    label: string
    value?: string | {}
    displaySelect?: string
    autoComplete?: string
    handleChange?: (name: string, values: string | number) => void
}

const Autocomplete: FC<IProps> = props => {
    const [state, setState] = useState(props.value ? { name: props.value } : null)

    const handleChange = useCallback(
        (_: any, newValue: any) => {
            setState(newValue)
            const values = newValue?.[!!props.selector ? props.selector : 'value']
            !!props.handleChange && props.handleChange(props.name, values)
        },
        [props]
    )

    return (
        <Root
            id="combo-box"
            disablePortal
            value={state}
            onChange={handleChange}
            getOptionLabel={(option: any) => option[props.displaySelect || 'name']}
            options={props.fields}
            isOptionEqualToValue={(option: any, value: any) => {
                return option[props.displaySelect || 'name'] === value[props.displaySelect || 'name']
            }}
            renderInput={params => (
                <TextFieldStyle
                    {...params}
                    label={props.label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: props.autoComplete ? props.autoComplete : 'new-password',
                    }}
                />
            )}
        />
    )
}

export default Autocomplete
