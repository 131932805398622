import IllustrationImg from 'assets/images/Illustration.svg'
import { Button, Image, Text } from 'components/shared'
import React, { FC, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Gutter, Space } from 'styles'
import { Root } from './styles'

interface IProps {
    sendOutForm: FunctionComponent<any>
}

const HassleFreeFilesSendout: FC<IProps> = props => {
    const { t } = useTranslation()
    const { sendOutForm: SendOutForm } = props

    return (
        <Root>
            <Gutter size={30} content="space-between" align="center" mobile>
                <div>
                    <Gutter align="center" content="space-between">
                        <Image height={160} width={215} image={IllustrationImg} />
                        <Space right={30} />
                        <Column>
                            <Text el="h2">{t('hassleFreeFileSendOut.hassleFreeFilesSendOut')}</Text>
                            <Space bottom={30} />
                            <SendOutForm>
                                <Button width={180}>{t('hassleFreeFileSendOut.sendOut')}</Button>
                            </SendOutForm>
                        </Column>
                    </Gutter>
                </div>
                <Column content="center">
                    <Text el="h4">{t('hassleFreeFileSendOut.deliverYourDocumentFromVirtualAddress')}</Text>
                    <Space bottom={30} />
                    <Text el="subtitle">{t('hassleFreeFileSendOut.allYouNeedIsToSpecifyReceiverAndUploadDocumentsWeWillPrint')}</Text>
                    <Text el="subtitle">{t('hassleFreeFileSendOut.andSendOutThemThroughPostOffice')}</Text>
                </Column>
            </Gutter>
        </Root>
    )
}

export default HassleFreeFilesSendout
