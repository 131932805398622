import SignUpTypes from './signUp-types'

export const startSignUp = () => ({
    type: SignUpTypes.START_SIGN_UP,
    success: false,
})

export const successSignUp = () => ({
    type: SignUpTypes.SUCCESS_SIGN_UP,
    success: true,
})

export const errorSignUp = (errors: Array<IError>) => ({
    type: SignUpTypes.ERROR_SIGN_UP,
    errors,
})

export const finishSignUp = () => ({
    type: SignUpTypes.FINISH_SIGN_UP,
    success: false,
})
