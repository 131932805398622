import styled, { css } from 'styled-components'


interface IProps { 
    isClicked: boolean
}

export const Root = styled.div<IProps>`

    display: flex;
    flex-direction: column;
    row-gap: 4px;
    cursor: pointer;
    padding: 20px 0px;
    
    &:before{
        display: inline-block;
        content: "";
        border-top: 2px solid black;
        width: 24px;
        ${({isClicked}) => isClicked && css`
            transform: rotate(45deg) translate3d(4px,4px,0);
        `}
        transition: transform 0.3s;
    }

    .hamburger{
        display: inline-block;
        content: "";
        border-top: 2px solid black;
        width: 24px;
        display: ${({isClicked}) => isClicked ? 'none' : 'block'};
    }

    &:after{
        display: inline-block;
        content: "";
        border-top: 2px solid black;
        width: 24px;

        ${({isClicked}) => isClicked && css`
            transform: rotate(-45deg) ;
        `}

        transition: transform 0.3s;
    }

`