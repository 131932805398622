import { Tooltip } from '@mui/material'
import documentCircleImg from 'assets/images/documentCircle.svg'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DownloadContext } from 'reducers/download'
import { Grid, Space } from 'styles'
import { otherFileType } from 'utils'
import { Image, Text } from '..'
import { downloadFiles } from './FilesDisplay-state'
import { FileSc } from './styles'

interface IProps {
    files?: Array<IFile>
}

const FilesDisplay: FC<IProps> = props => {
    const [downloadState, downloadDispatch] = useContext(DownloadContext)
    const [files, setFiles] = useState<Array<IBlob>>([])
    const { t } = useTranslation()

    useEffect(() => {
        props.files?.some(Boolean) && downloadFiles(downloadDispatch, props.files, setFiles)
    }, [downloadDispatch, props.files])

    if (!props.files?.some(Boolean)) return <Text el="h4">{t('filesDisplay.documentHasNoFiles')}</Text>
    if (downloadState.loading?.FILE_DOWNLOAD) return <Text el="h4">{t('filesDisplay.loadingFiles')}...</Text>

    return (
        <>
            <Text el="h4" align="center">
                {t('filesDisplay.Files')}
            </Text>
            <Space bottom={20} />
            <Grid gap={15} elInRow={10}>
                {files.map((file: IBlob, i: number) => (
                    <FileDisplay file={file} key={`fileDisplay-${i}`} />
                ))}
            </Grid>
        </>
    )
}

export default FilesDisplay

interface IFileDisplay {
    file: IBlob
}

const FileDisplay: FC<IFileDisplay> = props => {
    const handleView = () => {
        window.open(URL.createObjectURL(props.file), '_blank')
    }
    const isOtherFileType = otherFileType[props.file.type]

    const iconOrFileImg = !!isOtherFileType ? documentCircleImg : URL.createObjectURL(props.file)

    return (
        <Tooltip title={props.file.type}>
            <FileSc onClick={handleView}>
                <Image height={50} width={50} minHeight={50} minWidth={50} image={iconOrFileImg} />
            </FileSc>
        </Tooltip>
    )
}
