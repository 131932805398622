import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import PaymentsTypes from './payments-types'

export interface IReducer {
    type: PaymentsTypes
    payments?: IPayments
    paymentsStatuses?: Array<IPaymentStatus>
    paymentsBalances?: Array<IPaymentBalance>
    clientSecret?: IPaymentClientSecret
    error?: Array<IError>
    id?: string
}

export interface IState {
    payments?: IPayments
    paymentsStatuses?: Array<IPaymentStatus>
    paymentsBalances?: Array<IPaymentBalance>
    clientSecret?: string
    loading: { GET_PAYMENTS?: boolean; GET_PAYMENTS_STATUSES?: boolean; GET_PAYMENTS_BALANCE?: boolean; GET_PAYMENTS_CLIENT_SECRET?: boolean }
    error: { GET_PAYMENTS?: Array<IError>; GET_PAYMENTS_STATUSES?: Array<IError>; GET_PAYMENTS_BALANCE?: Array<IError>; GET_PAYMENTS_CLIENT_SECRET?: Array<IError> }
}

const initialState = {
    payments: { items: [], count: 0 },
    paymentsStatuses: [],
    paymentsBalances: [],
    clientSecret: '',
    loading: { GET_PAYMENTS: false, GET_PAYMENTS_STATUSES: false, GET_PAYMENTS_BALANCE: false },
    error: { GET_PAYMENTS: [], GET_PAYMENTS_STATUSES: [], GET_PAYMENTS_BALANCE: [], GET_PAYMENTS_CLIENT_SECRET: [] },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case PaymentsTypes.START_GET_PAYMENTS:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS: true },
            }
        case PaymentsTypes.SUCCESS_GET_PAYMENTS:
            return {
                ...state,
                payments: action.payments,
                loading: { ...state.loading, GET_PAYMENTS: false },
            }
        case PaymentsTypes.FINISH_GET_PAYMENTS:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS: false },
                error: { ...state.error, GET_PAYMENTS: [] },
                payments: { items: [], count: 0 },
            }
        case PaymentsTypes.ERROR_GET_PAYMENTS:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS: false },
                error: { ...state.error, GET_PAYMENTS: action.error },
            }
        case PaymentsTypes.START_GET_PAYMENTS_STATUSES:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_STATUSES: true },
            }
        case PaymentsTypes.SUCCESS_GET_PAYMENTS_STATUSES:
            return {
                ...state,
                paymentsStatuses: action.paymentsStatuses,
                loading: { ...state.loading, GET_PAYMENTS_STATUSES: false },
            }
        case PaymentsTypes.FINISH_GET_PAYMENTS_STATUSES:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_STATUSES: false },
                error: { ...state.error, GET_PAYMENTS_STATUSES: [] },
                paymentsStatuses: [],
            }
        case PaymentsTypes.ERROR_GET_PAYMENTS_STATUSES:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_STATUSES: false },
                error: { ...state.error, GET_PAYMENTS_STATUSES: action.error },
            }
        case PaymentsTypes.START_GET_PAYMENTS_BALANCE:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_BALANCE: true },
            }
        case PaymentsTypes.SUCCESS_GET_PAYMENTS_BALANCE:
            const getEurBalance = (cur: IPaymentBalance) => cur.currencyCode === 'EUR'

            return {
                ...state,
                paymentsBalances: action.paymentsBalances?.filter(getEurBalance),
                loading: { ...state.loading, GET_PAYMENTS_BALANCE: false },
            }
        case PaymentsTypes.FINISH_GET_PAYMENTS_BALANCE:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_BALANCE: false },
                error: { ...state.error, GET_PAYMENTS_BALANCE: [] },
                paymentsBalances: [],
            }
        case PaymentsTypes.ERROR_GET_PAYMENTS_BALANCE:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_BALANCE: false },
                error: { ...state.error, GET_PAYMENTS_BALANCE: action.error },
            }
        case PaymentsTypes.START_GET_PAYMENTS_CLIENT_SECRET:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_CLIENT_SECRET: true },
            }
        case PaymentsTypes.SUCCESS_GET_PAYMENTS_CLIENT_SECRET:
            return {
                ...state,
                clientSecret: action.clientSecret?.clientSecret,
                loading: { ...state.loading, GET_PAYMENTS_CLIENT_SECRET: false },
            }
        case PaymentsTypes.FINISH_GET_PAYMENTS_CLIENT_SECRET:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_CLIENT_SECRET: false },
                error: { ...state.error, GET_PAYMENTS_CLIENT_SECRET: [] },
                clientSecret: '',
            }
        case PaymentsTypes.ERROR_GET_PAYMENTS_CLIENT_SECRET:
            return {
                ...state,
                loading: { ...state.loading, GET_PAYMENTS_CLIENT_SECRET: false },
                error: { ...state.error, GET_PAYMENTS_CLIENT_SECRET: action.error },
            }
        case PaymentsTypes.START_CANCEL_PAYMENT:
            return {
                ...state,
                loading: { ...state.loading, CANCEL_PAYMENT: true },
            }
        case PaymentsTypes.SUCCESS_CANCEL_PAYMENT:
            return {
                ...state,
                payments: cancelPayment(state.payments, action.id),
                loading: { ...state.loading, CANCEL_PAYMENT: false },
            }
        case PaymentsTypes.FINISH_CANCEL_PAYMENT:
            return {
                ...state,
                loading: { ...state.loading, CANCEL_PAYMENT: false },
                error: { ...state.error, CANCEL_PAYMENT: [] },
            }
        case PaymentsTypes.ERROR_CANCEL_PAYMENT:
            return {
                ...state,
                loading: { ...state.loading, CANCEL_PAYMENT: false },
                error: { ...state.error, CANCEL_PAYMENT: action.error },
            }
        default:
            return state
    }
}

const cancelPayment = (payments?: IPayments, id?: string) => {
    const paymentValues: IPayments = JSON.parse(JSON.stringify(payments))
    const { items } = paymentValues
    const payment = items.find(value => value.id === id)
    if (payment) {
        payment.status = 'Cancelled'
        payment.isCancellable = false
    }
    return paymentValues
}

export const PaymentsContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const PaymentsContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <PaymentsContext.Provider value={[state, dispatch]}>{props.children}</PaymentsContext.Provider>
}
