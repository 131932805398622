import styled, { css } from 'styled-components'

interface IRoot {
    isDragActive: boolean
    isDragReject: boolean
}

export const Root = styled.div<IRoot>`
    cursor: pointer;
    padding: 0 5px;
    box-sizing: border-box;
    border-radius: 4px;

    ${({ isDragActive, theme }) =>
        !!isDragActive &&
        css`
            border: 1px dashed ${theme.palette.primary.main};
            margin: -1px;
        `}
    ${({ isDragReject, theme }) =>
        !!isDragReject &&
        css`
            border: 1px dashed ${theme.palette.alert.red};
            margin: -1px;
        `}
`
