import { Card, Text } from 'components/shared'
import React, { FC } from 'react'
import { Space } from 'styles'

interface IProps {
    name: string
}

const Detail: FC<IProps> = props => {
    return (
        <div>
            <Text el={'h3'}>{props.name}</Text>
            <Space bottom={20} />
            <Card border height={106} padding={30} content="center" fullWidth>
                {props.children}
            </Card>
        </div>
    )
}

export default Detail
