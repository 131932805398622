import styled, { css } from 'styled-components'

interface IRoot {
    selected: boolean
    underscore?: boolean
}

export const Root = styled.div<IRoot>`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 15px 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    max-width: 206px;
    min-width: 206px;

    ${props =>
        !!props.selected &&
        css`
            background: ${({ theme }) => theme.palette.primary.white};
        `}

    ${props =>
        !!props.selected &&
        !!props.underscore &&
        css`
            position: relative;
            bottom: -1px;
            border-bottom: 2px solid ${props.theme.palette.primary.main};
        `}     


    div {
        ${props =>
            !props.selected &&
            css`
                color: #666666;
                font-weight: 400;
            `}
    }

    @media ${({ theme }) => theme.breakpoints.max.md} {
        min-width: 130px;
    }
`
