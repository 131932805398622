import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import { localStorageHelper } from 'utils'
import SignInTypes from './signIn-types'
import jwt_decode from 'jwt-decode'

const accessToken = localStorageHelper<ISignInResponse>('session')?.accessToken
export interface IReducer {
    type: SignInTypes
    success?: boolean
    errors?: Array<IError>
    signInResponse?: ISignInResponse
}

interface IState {
    loading: { MAGIC_LINK: boolean; SIGN_IN: boolean }
    success?: { MAGIC_LINK?: boolean; SIGN_IN?: boolean }
    errors?: { MAGIC_LINK?: Array<IError>; SIGN_IN?: Array<IError> }
    signedIn?: boolean
    accessToken?: string
    user?: IUser
    isAdmin: boolean
}

const initialUser: IUser = {
    exp: 0,
    nbf: 0,
    id: '',
    iss: '',
    roles: [],
}

const initialState = {
    loading: { MAGIC_LINK: false, SIGN_IN: false },
    success: { MAGIC_LINK: false, SIGN_IN: false },
    errors: { MAGIC_LINK: [], SIGN_IN: [] },
    signedIn: !!accessToken,
    accessToken: accessToken || '',
    user: !!accessToken ? jwt_decode<IUser>(accessToken) : initialUser,
    isAdmin: !!accessToken && !!jwt_decode<IUser>(accessToken).roles?.includes('Administrator'),
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case SignInTypes.START_MAGIC_LINK:
            return {
                ...state,
                loading: { ...state.loading, MAGIC_LINK: true },
                success: { ...state.success, MAGIC_LINK: false },
                errors: { ...state.errors, MAGIC_LINK: [], SIGN_IN: [] },
            }
        case SignInTypes.SUCCESS_MAGIC_LINK:
            return {
                ...state,
                loading: { ...state.loading, MAGIC_LINK: false },
                success: { ...state.success, MAGIC_LINK: true },
                errors: { ...state.errors, MAGIC_LINK: [], SIGN_IN: [] },
            }
        case SignInTypes.ERROR_MAGIC_LINK:
            return {
                ...state,
                loading: { ...state.loading, MAGIC_LINK: false },
                success: { ...state.success, MAGIC_LINK: false },
                errors: { ...state.errors, MAGIC_LINK: action.errors },
            }
        case SignInTypes.FINISH_MAGIC_LINK:
            return {
                ...state,
                loading: { ...state.loading, MAGIC_LINK: false },
                success: { ...state.success, MAGIC_LINK: false },
                errors: { ...state.errors, MAGIC_LINK: [], SIGN_IN: [] },
            }
        case SignInTypes.START_SIGN_IN:
            return {
                ...state,
                loading: { ...state.loading, SIGN_IN: true },
                success: { ...state.success, SIGN_IN: false },
                errors: { ...state.errors, SIGN_IN: [] },
            }
        case SignInTypes.SUCCESS_SIGN_IN:
            return {
                ...state,
                loading: { ...state.loading, SIGN_IN: false },
                success: { ...state.success, SIGN_IN: true },
                signedIn: !!action.signInResponse?.accessToken,
                accessToken: action.signInResponse?.accessToken,
                user: !!action.signInResponse?.accessToken ? jwt_decode<IUser>(action.signInResponse?.accessToken) : initialUser,
                isAdmin: !!action.signInResponse?.accessToken && !!jwt_decode<IUser>(action.signInResponse?.accessToken).roles?.includes('Administrator'),
                errors: { ...state.errors, SIGN_IN: [] },
            }
        case SignInTypes.ERROR_SIGN_IN:
            return {
                ...state,
                loading: { ...state.loading, SIGN_IN: false },
                success: { ...state.success, SIGN_IN: false },
                errors: { ...state.errors, SIGN_IN: action.errors },
            }
        case SignInTypes.FINISH_SIGN_IN:
            return {
                ...state,
                loading: { ...state.loading, SIGN_IN: false },
                success: { ...state.success, SIGN_IN: false },
                errors: { ...state.errors, SIGN_IN: [] },
                signedIn: false,
                accessToken: '',
                user: initialUser,
                isAdmin: false,
            }
        default:
            return state
    }
}

export const SignInContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const SignInContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    // console.log('sign in state', state)

    return <SignInContext.Provider value={[state, dispatch]}>{props.children}</SignInContext.Provider>
}
