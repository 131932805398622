import React, { FC, memo, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'styles'
import { themeProperties } from 'theme'
import { makeQueryParamsString } from 'utils'
import { Button } from '..'

interface IProps {
    onClick: (val: string) => void
    count?: number
    limit: MutableRefObject<number>
    LIMIT: number
    loading?: boolean
    filterResults?: MutableRefObject<Object | undefined>
}

const LoadMoreButton: FC<IProps> = memo(props => {
    const { t } = useTranslation()
    const limit = props.limit
    const filterResults = props.filterResults?.current || {}

    const handleClick = () => {
        limit.current = limit.current + props.LIMIT
        const queries = makeQueryParamsString({ limit: limit.current, ...filterResults })

        props.onClick(queries)
    }

    const itemsCount = props.count || 0
    const loadMore = itemsCount > limit.current && (
        <Row content="right">
            <Button width={180} maxWidth={180} bordered onClick={handleClick} disabled={props.loading} loading={props.loading} spinnerColor={themeProperties.palette.primary.main}>
                {t('loadMore.loadMore')} ..
            </Button>
        </Row>
    )

    return <>{loadMore}</>
})

export default LoadMoreButton
