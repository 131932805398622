import styled from 'styled-components'

export const AttachedImageContainer = styled.div`
    display: flex;
    position: relative;
    width: 60px;
    height: 50px;
`

export const AttachedImage = styled.img`
    min-width: 60px;
    min-height: 50px;
    cursor: pointer;
    border-radius: 4px;
`

export const DelIcon = styled.div`
    position: relative;
    right: 18px;
    top: -20px;
    width: 37px;
    height: 37px;
`

export const Actions = styled.div``

export const ViewTxt = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
    z-index: 1;
`
