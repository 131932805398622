import AddressesTypes from './addresses-types'

export const startGetAddresses = () => ({
    type: AddressesTypes.START_GET_ADDRESSES,
})
export const successGetAddresses = (data: IAdminAddresses) => ({
    type: AddressesTypes.SUCCESS_GET_ADDRESSES,
    addresses: data,
})
export const errorGetAddresses = () => ({
    type: AddressesTypes.ERROR_GET_ADDRESSES,
})
export const finishGetAddresses = () => ({
    type: AddressesTypes.FINISH_GET_ADDRESSES,
})
