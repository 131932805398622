import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import CountriesTypes from './countries-types'

export interface IReducer {
    type: CountriesTypes
    loading?: boolean
    error?: Array<IError>
    countries?: Array<ICountry>
}

export interface IState {
    countries?: Array<ICountry>
    loading: { GET_COUNTRIES: Boolean }
    error?: { GET_COUNTRIES?: Array<IError> }
}

const initialState = {
    countries: [],
    loading: { GET_COUNTRIES: false },
    error: { GET_COUNTRIES: [] },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case CountriesTypes.START_GET_COUNTRIES:
            return {
                ...state,
                loading: { ...state.loading, GET_COUNTRIES: false },
                error: { ...state.error, GET_COUNTRIES: [] },
            }
        case CountriesTypes.SUCCESS_GET_COUNTRIES:
            return {
                ...state,
                countries: action.countries,
                loading: { ...state.loading, GET_COUNTRIES: true },
                error: { ...state.error, GET_COUNTRIES: [] },
            }
        case CountriesTypes.FINISH_GET_COUNTRIES:
            return {
                ...state,
                loading: { ...state.loading, GET_COUNTRIES: false },
                error: { ...state.error, GET_COUNTRIES: [] },
            }
        case CountriesTypes.ERROR_GET_COUNTRIES:
            return {
                ...state,
                loading: { ...state.loading, GET_COUNTRIES: false },
                error: { ...state.error, GET_COUNTRIES: action.error },
            }
        default:
            return state
    }
}

export const CountriesContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const CountriesContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <CountriesContext.Provider value={[state, dispatch]}>{props.children}</CountriesContext.Provider>
}
