import styled from 'styled-components'

export const Root = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 8px;
    width: 100%;
    padding: 35px 0;
    box-sizing: border-box;

    @media ${props => props.theme.breakpoints.max.md} {
        flex-direction: column;
        padding: 35px;
    }
`
