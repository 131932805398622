import CorrespondenceTypes from './correspondence-types'

export const startGetCorrespondences = () => ({
    type: CorrespondenceTypes.START_GET_CORRESPONDENCES,
})
export const successGetCorrespondences = (data: ICorrespondences) => ({
    type: CorrespondenceTypes.SUCCESS_GET_CORRESPONDENCES,
    correspondences: data,
})
export const errorGetCorrespondences = () => ({
    type: CorrespondenceTypes.ERROR_GET_CORRESPONDENCES,
})
export const finishGetCorrespondences = () => ({
    type: CorrespondenceTypes.FINISH_GET_CORRESPONDENCES,
})

export const startGetCorrespondencesTypes = () => ({
    type: CorrespondenceTypes.START_GET_CORRESPONDENCES_TYPES,
})
export const successGetCorrespondencesTypes = (data: Array<ICorrespondenceTypes>) => ({
    type: CorrespondenceTypes.SUCCESS_GET_CORRESPONDENCES_TYPES,
    correspondencesTypes: data,
})
export const errorGetCorrespondencesTypes = () => ({
    type: CorrespondenceTypes.ERROR_GET_CORRESPONDENCES_TYPES,
})
export const finishGetCorrespondencesTypes = () => ({
    type: CorrespondenceTypes.FINISH_GET_CORRESPONDENCES_TYPES,
})

export const startGetCorrespondencesStatuses = () => ({
    type: CorrespondenceTypes.START_GET_CORRESPONDENCES_STATUSES,
})
export const successGetCorrespondencesStatuses = (data: Array<ICorrespondenceStatuses>) => ({
    type: CorrespondenceTypes.SUCCESS_GET_CORRESPONDENCES_STATUSES,
    correspondencesStatuses: data,
})
export const errorGetCorrespondencesStatuses = () => ({
    type: CorrespondenceTypes.ERROR_GET_CORRESPONDENCES_STATUSES,
})
export const finishGetCorrespondencesStatuses = () => ({
    type: CorrespondenceTypes.FINISH_GET_CORRESPONDENCES_STATUSES,
})

export const startCorrespondenceIsDownloaded = () => ({
    type: CorrespondenceTypes.START_CORRESPONDENCE_IS_DOWNLOADED,
})
export const successCorrespondenceIsDownloaded = (correspondenceId?: string) => ({
    type: CorrespondenceTypes.SUCCESS_CORRESPONDENCE_IS_DOWNLOADED,
    correspondenceId,
})
export const errorCorrespondenceIsDownloaded = () => ({
    type: CorrespondenceTypes.ERROR_CORRESPONDENCE_IS_DOWNLOADED,
})
export const finishCorrespondenceIsDownloaded = () => ({
    type: CorrespondenceTypes.FINISH_CORRESPONDENCE_IS_DOWNLOADED,
})
