import SendOutDetailsModal from 'components/Forms/SendOutDetails/SendOutDetails-modal'
import SendOutFilesModal from 'components/Forms/SendOutFiles/SendOutFiles-modal'
import { getSubscriptions } from 'components/MainTabs/TabComponents/Home/Home-state'
import { HassleFreeFilesSendout } from 'components/promos'
import { LoadMoreButton, Table, TableFilters } from 'components/shared'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CountriesContext } from 'reducers/countries'
import { SendoutsContext } from 'reducers/sendouts'
import { VirtualAddressesContext } from 'reducers/virtualAddresses'
import { Space } from 'styles'
import { makeQueryParamsString } from 'utils'
import { cancelSendOut, getCountriesList, getSendOuts, getSendOutStatuses, parseSendouts } from './Sendouts-state'

const LIMIT = 10

const Sendouts = () => {
    const { t } = useTranslation()
    const [virtualAddressesState, virtualAddressesDispatch] = useContext(VirtualAddressesContext)
    const [, countriesDispatch] = useContext(CountriesContext)
    const [sendoutsState, sendoutsDispatch] = useContext(SendoutsContext)
    const [sendout, setSendout] = useState<ISendout | null>(null)
    const filterResults = useRef<Object>()
    const filterLimit = useRef<number>(LIMIT)

    useEffect(() => {
        const queries = makeQueryParamsString({ limit: LIMIT })
        getSubscriptions(virtualAddressesDispatch)
        getCountriesList(countriesDispatch)
        getSendOuts(sendoutsDispatch, queries)
        getSendOutStatuses(sendoutsDispatch)
    }, [countriesDispatch, sendoutsDispatch, virtualAddressesDispatch])

    const items: ITableData = useMemo(
        () =>
            parseSendouts(sendoutsState.sendouts?.items, [
                t('tabComponentsSendouts.Document'),
                t('tabComponentsSendouts.Receiver'),
                t('tabComponentsSendouts.Address'),
                t('tabComponentsSendouts.Status'),
                '',
            ]),
        [sendoutsState.sendouts, t]
    )

    const handleLoadMore = useCallback(
        query => {
            getSendOuts(sendoutsDispatch, query)
        },
        [sendoutsDispatch]
    )

    const handleDetailsClick = useCallback(
        (body?: ITableDataBody, type?: LastTableDataClickType) => {
            if (type === 'ONE') {
                cancelSendOut(sendoutsDispatch, body?.id)
            } else {
                !!body && setSendout(body.originalData)
            }
        },
        [sendoutsDispatch]
    )

    const handleFilterClick = useCallback(
        (values: any) => {
            filterResults.current = values
            const queries = makeQueryParamsString({ ...values, limit: filterLimit.current })
            getSendOuts(sendoutsDispatch, queries)
        },
        [sendoutsDispatch]
    )

    const filerClickActions = useMemo(
        () => ({
            action: handleFilterClick,
            loading: !!sendoutsState.loading.GET_SENDOUTS,
        }),
        [handleFilterClick, sendoutsState.loading.GET_SENDOUTS]
    )

    const detailsClickActions = {
        action: handleDetailsClick,
        loading: sendoutsState.loading.CANCEL_SENDOUT,
    }

    const tableItems = {
        data: items,
        loading: !!sendoutsState.loading.GET_SENDOUTS,
    }

    const filterFields = useMemo(
        () => [
            { label: t('tabComponentsPayments.Addresses'), name: 'addressId', fields: virtualAddressesState.addresses, selector: 'id' },
            { label: t('tabComponentsPayments.Status'), name: 'status', fields: sendoutsState.statuses, selector: 'name' },
        ],
        [t, virtualAddressesState.addresses, sendoutsState.statuses]
    ) as Array<IFilterTableFields>

    return (
        <div>
            <Space bottom={30} />
            <HassleFreeFilesSendout sendOutForm={SendOutFilesModal} />
            <Space bottom={30} />
            <TableFilters fields={filterFields} filterClick={filerClickActions} dateRange />
            <Space bottom={30} />
            <Table items={tableItems} lastDataClick={detailsClickActions} />
            <Space bottom={30} />
            <LoadMoreButton
                onClick={handleLoadMore}
                limit={filterLimit}
                LIMIT={LIMIT}
                count={sendoutsState.sendouts?.count}
                loading={!!sendoutsState.loading.GET_SENDOUTS}
                filterResults={filterResults}
            />
            <Space bottom={30} />
            <SendOutDetailsModal isOpen={sendout} close={() => setSendout(null)} />
        </div>
    )
}

export default Sendouts
