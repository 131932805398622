import { FC } from 'react'
import { Root } from './styles'

interface IProps {
    el: 'h1' | 'h2' | 'h3' | 'h4' | 'subtitle' | 'alert' | 'copyright' | 'link'
    align?: 'center' | 'left' | 'right'
    mAlign?: 'center' | 'left' | 'right'
    color?: string
    cursor?: 'pointer'
    onClick?: () => void
}

const Text: FC<IProps> = props => {
    return <Root {...props}>{props.children}</Root>
}

export default Text
