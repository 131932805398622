import { MainTabs } from 'components/MainTabs'
import { adminTabComponents } from 'components/MainTabs/AdminTabComponents'
import { HeaderFooter } from 'layouts'
import { Space } from 'styles'
import { Text } from 'components/shared'
import { Theme, useMediaQuery } from '@mui/material'
import { useMobilePageTitle } from 'hooks/useMobilePageTitle'

const Admin = () => {
    const title = useMobilePageTitle(adminTabComponents)
    const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'))

    return (
        <HeaderFooter page={'admin'}>
            <Text el="h2">{title}</Text>
            <Space bottom={50} mBottom={20} />
            <MainTabs tabComponents={adminTabComponents} page={'admin'} options={{ cardShadow: true, cardPadding: 30 }} showTabs={matches} />
        </HeaderFooter>
    )
}

export default Admin
