import { FC } from 'react'
import { Root } from './styles'

interface IProps {
    width?: number
    maxWidth?: number
    height?: number
    border?: boolean
    fullWidth?: boolean
    content?: 'center' | 'left' | 'right'
    padding: number
    mPadding?: number
}

const Card: FC<IProps> = props => {
    return <Root {...props}>{props.children}</Root>
}

export default Card
