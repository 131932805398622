import { Image, Text } from 'components/shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Gutter } from 'styles'
import { New, Root } from './styles'

interface IProps {
    firstTableData: IFirstTableData
    collapsible?: boolean
    isCollapsed?: boolean
}

const FirstTableData: FC<IProps> = props => {
    const { t } = useTranslation()

    return (
        <Root>
            <div>
                {props.collapsible ? (
                    <SwitchableImage firstTableData={props.firstTableData} isCollapsed={props.isCollapsed} />
                ) : (
                    <Image height={38} width={38} minHeight={38} minWidth={38} image={props.firstTableData?.image} />
                )}
            </div>
            <div>
                <Text el={'h4'}>{props.firstTableData?.top}</Text>
                <Gutter size={8} align="center">
                    {!!props.firstTableData?.bottom && <div>{props.firstTableData?.bottom}</div>}
                    {!!props.firstTableData?.new && <New>{t('firstTableData.NEW')}</New>}
                </Gutter>
            </div>
        </Root>
    )
}

export default FirstTableData

interface ISwitchableImage {
    firstTableData: IFirstTableData
    isCollapsed?: boolean
}

const SwitchableImage: FC<ISwitchableImage> = props => (
    <Image height={38} width={38} minHeight={38} minWidth={38} image={props.isCollapsed ? props.firstTableData?.imageOnCollapse : props.firstTableData?.image} />
)
