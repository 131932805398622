import SignInTypes from './signIn-types'

export const startMagicLink = () => ({
    type: SignInTypes.START_MAGIC_LINK,
})

export const successMagicLink = () => ({
    type: SignInTypes.SUCCESS_MAGIC_LINK,
})

export const errorMagicLink = (errors: Array<IError>) => ({
    type: SignInTypes.ERROR_MAGIC_LINK,
    errors,
})

export const finishMagicLink = () => ({
    type: SignInTypes.FINISH_MAGIC_LINK,
})

export const startSignIn = () => ({
    type: SignInTypes.START_SIGN_IN,
})

export const successSignIn = (data: ISignInResponse) => ({
    type: SignInTypes.SUCCESS_SIGN_IN,
    signInResponse: data,
})

export const errorSignIn = (errors: Array<IError>) => ({
    type: SignInTypes.ERROR_SIGN_IN,
    errors,
})

export const finishSignIn = () => ({
    type: SignInTypes.FINISH_SIGN_IN,
})
