import styled from 'styled-components'

export const Root = styled.div`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary.light};
    border-spacing: 0px;
    padding-bottom: 15px;
`

export const TableHeader = styled.div`
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.light};
`

export const TableBody = styled.div`
    padding: 0 15px;
`

export const TableData = styled.div`
    margin: 10px 0;
`
