import axios from 'axios'
import { navigationRoutes } from 'routes/Routes'
import { User } from 'services'
import { clearAccessTokens, localStorageHelper, setAccessTokens } from 'utils'

axios.interceptors.response.use(
    response => {
        return response
    },
    async error => {
        if (error?.response?.status !== 401) {
            return Promise.reject(error)
        }

        if (error?.response?.status === 401 && (error?.config?.url.includes('signIn') || error?.config?.url.includes('magicLinks'))) {
            return Promise.reject(error)
        }

        if (error?.config?.url.includes('refresh')) {
            clearAccessTokens()
            window.location.href = navigationRoutes.signIn
            return Promise.reject(error)
        }

        if (!!error?.response?.config && !error?.response?.config.__isRetryRequest) {
            return getAuthToken().then((response: any) => {
                const { data } = response
                setAccessTokens(data)
                const errorConfig = error.config
                errorConfig.headers['Authorization'] = `Bearer ${data.accessToken}`
                error.response.config.__isRetryRequest = true
                return axios(error.response.config)
            })
        }
    }
)

let authTokenRequest: any
function getAuthToken() {
    if (!authTokenRequest) {
        authTokenRequest = User.refresh({ refreshToken: localStorageHelper<ISignInResponse>('session').refreshToken })
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest)
    }
    return authTokenRequest
}

function resetAuthTokenRequest() {
    authTokenRequest = null
}
