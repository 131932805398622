import { FC } from 'react'
import { Spinner } from '..'
import { Root } from './styles'

interface IProps {
    width?: number
    maxWidth?: number
    height?: number
    bordered?: boolean
    full?: boolean
    disabled?: boolean
    loading?: boolean
    onClick?: () => void
    bgColor?: string
    type?: any
    spinnerColor?: string
}

const Button: FC<IProps> = ({ bgColor, disabled, loading, bordered, full, type, ...props }) => {
    const borderedCast = +!!bordered
    const fullCast = +!!full
    const disabledCast = +!!disabled

    return (
        <Root bgColor={bgColor} full={fullCast} disable={disabledCast} bordered={borderedCast} type={type} {...props}>
            {loading === true ? <Spinner color={props.spinnerColor} /> : props.children}
        </Root>
    )
}

export default Button
