import { Dispatch } from 'react'
import * as paymentsAdmin from 'reducers/admin/payments'
import { UserSubscriptionsAdmin } from 'services'
import { dateAndTimeToLocaleString, toLocaleStringWithCurrency } from 'utils'
import documentCircleImg from 'assets/images/documentCircle.svg'

export const startGetPayments = async (dispatch: Dispatch<paymentsAdmin.IReducer>, id?: string, query?: string) => {
    try {
        dispatch(paymentsAdmin.startGetPaymentsAdmin())
        const { data }: { data: IPaymentsAdmin } = await UserSubscriptionsAdmin.payments(id, query)
        dispatch(paymentsAdmin.successGetPaymentsAdmin(data))
    } catch (e) {
        dispatch(paymentsAdmin.errorGetPaymentsAdmin())
    }
}

export const parsePayments = (data: Array<IPaymentAdmin> = [], header: Array<string>): ITableData => {
    return {
        header,
        body: body(data),
    }
}

const body = (data: Array<IPaymentAdmin> = []): Array<ITableDataBody> => {
    return data.reduce((acc, cur, i) => {
        return [
            ...acc,
            {
                id: cur.id,
                fileId: cur.fileId,
                status: cur.status,
                originalData: cur,
                firstTableData: {
                    image: documentCircleImg,
                    top: cur.addressName,
                    bottom: dateAndTimeToLocaleString(cur?.createdAt),
                },
                mainData: [toLocaleStringWithCurrency(cur.amount.amount, cur.amount.currencyCode)],
                lastTableData: [
                    { type: 'status', value: cur.status },
                    { type: 'click', value: 'Download' },
                ],
            },
        ]
    }, [] as Array<ITableDataBody>)
}
