import styled from 'styled-components'
import Tabs from '@mui/material/Tabs'

export const Root = styled(Tabs)`
    align-items: center;
    position: relative;

    .MuiTabs-scrollButtons {
        background-color: ${({ theme }) => theme.palette.primary.background};
        width: 38px;
        height: 38px;
        margin: 0;
        padding: 0;
        border-radius: 50%;
    }

    .MuiTabs-scrollButtons:first-child {
        position: absolute;
        z-index: 99999;
    }

    .MuiTabs-scrollButtons:last-child {
        position: absolute;
        right: 0;
    }

    .MuiTabs-scrollButtons.Mui-disabled {
        display: none;
    }

    button {
        color: initial;
        padding: 12px 10px;
        text-transform: initial;
    }

    .MuiTab-root {
        padding: 0;
        margin-right: 20px;
        @media ${({ theme }) => theme.breakpoints.max.sm} {
            margin-right: 0px;
            margin-bottom: 10px;
            width: 233px;
        }
    }

    button.Mui-selected {
        color: initial;
        border-radius: 8px;
        border: 2px solid ${({ theme }) => theme.palette.primary.main};
    }

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-flexContainer {
        @media ${({ theme }) => theme.breakpoints.max.sm} {
            flex-direction: column;
        }
    }

    .MuiTabs-flexContainer {
        @media ${({ theme }) => theme.breakpoints.max.sm} {
            align-items: center;
        }
    }
`
