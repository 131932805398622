import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import SubscriptionsTypes from './subscriptions-types'

export interface IReducer {
    type: SubscriptionsTypes
    availableSubscriptions?: Array<IAvailableSubscription>
    subscriptionCarouselIndex?: number
    secret?: string
    errors?: Array<IError>
}

export interface IState {
    availableSubscriptions?: Array<IAvailableSubscription>
    subscriptionCarouselIndex?: number
    secret?: string
    loading: { GET_AVAILABLE_SUBSCRIPTIONS: boolean }
    errors?: { GET_AVAILABLE_SUBSCRIPTIONS?: boolean; GET_CLIENT_SECRET?: Array<IError> }
}

const initialState = {
    availableSubscriptions: [],
    subscriptionCarouselIndex: 0,
    secret: '',
    loading: { GET_AVAILABLE_SUBSCRIPTIONS: false },
    errors: { GET_AVAILABLE_SUBSCRIPTIONS: false, GET_CLIENT_SECRET: [] },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case SubscriptionsTypes.START_GET_AVAILABLE_SUBSCRIPTIONS:
            return {
                ...state,
                loading: { ...state.loading, GET_AVAILABLE_SUBSCRIPTIONS: true },
            }
        case SubscriptionsTypes.SUCCESS_GET_AVAILABLE_SUBSCRIPTIONS:
            return {
                ...state,
                availableSubscriptions: action.availableSubscriptions,
                loading: { ...state.loading, GET_AVAILABLE_SUBSCRIPTIONS: false },
            }
        case SubscriptionsTypes.FINISH_GET_AVAILABLE_SUBSCRIPTIONS:
            return {
                ...state,
                availableSubscriptions: [],
                loading: { ...state.loading, GET_AVAILABLE_SUBSCRIPTIONS: false },
                errors: { ...state.errors, GET_AVAILABLE_SUBSCRIPTIONS: false },
            }
        case SubscriptionsTypes.ERROR_GET_AVAILABLE_SUBSCRIPTIONS:
            return {
                ...state,
                loading: { ...state.loading, GET_AVAILABLE_SUBSCRIPTIONS: false },
                errors: { ...state.errors, GET_AVAILABLE_SUBSCRIPTIONS: true },
            }
        case SubscriptionsTypes.START_SET_SUBSCRIPTION_CAROUSEL_INDEX:
            return {
                ...state,
            }
        case SubscriptionsTypes.SUCCESS_SET_SUBSCRIPTION_CAROUSEL_INDEX:
            return {
                ...state,
                subscriptionCarouselIndex: action.subscriptionCarouselIndex,
            }
        case SubscriptionsTypes.FINISH_SET_SUBSCRIPTION_CAROUSEL_INDEX:
            return {
                ...state,
                subscriptionCarouselIndex: 0,
            }
        case SubscriptionsTypes.ERROR_SET_SUBSCRIPTION_CAROUSEL_INDEX:
            return {
                ...state,
            }
        case SubscriptionsTypes.START_GET_CLIENT_SECRET:
            return {
                ...state,
            }
        case SubscriptionsTypes.SUCCESS_GET_CLIENT_SECRET:
            return {
                ...state,
                secret: action.secret,
            }
        case SubscriptionsTypes.FINISH_GET_CLIENT_SECRET:
            return {
                ...state,
                secret: '',
                errors: { ...state.errors, GET_CLIENT_SECRET: [] },
            }
        case SubscriptionsTypes.ERROR_GET_CLIENT_SECRET:
            return {
                ...state,
                errors: { ...state.errors, GET_CLIENT_SECRET: action.errors },
            }
        default:
            return state
    }
}

export const SubscriptionsContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const SubscriptionsContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    // console.log('Subscriptions state', state)

    return <SubscriptionsContext.Provider value={[state, dispatch]}>{props.children}</SubscriptionsContext.Provider>
}
