import { Dispatch } from 'react'
import * as utilityBillsAdmin from 'reducers/admin/utilityBills'
import { UserSubscriptionsAdmin } from 'services'

import documentCircleImg from 'assets/images/documentCircle.svg'
import { dateAndTimeToLocaleString } from 'utils'

export const getUtilityBills = async (dispatch: Dispatch<utilityBillsAdmin.IReducer>, id?: string, query?: string) => {
    try {
        dispatch(utilityBillsAdmin.startGetUtilityBills())
        const { data }: { data: IUtilityBillsAdmin } = await UserSubscriptionsAdmin.utilityBills(id, query)
        dispatch(utilityBillsAdmin.successGetUtilityBills(data))
    } catch (e) {
        dispatch(utilityBillsAdmin.errorGetUtilityBills())
    }
}

export const parseUtilityBills = (data: Array<IUtilityBillAdmin> = [], header: Array<string>): ITableData => {
    return {
        header,
        body: body(data),
    }
}

const body = (data: Array<IUtilityBillAdmin> = []): Array<ITableDataBody> => {
    return data.reduce((acc, cur, i) => {
        return [
            ...acc,
            {
                id: cur.id,
                fileId: cur.fileId,
                firstTableData: {
                    image: documentCircleImg,
                    top: `${cur.senderName} ${cur.senderNumber}`,
                    bottom: dateAndTimeToLocaleString(cur?.createdAt),
                },
                mainData: [cur.addressName, cur.type],
                lastTableData: [{ type: 'click', value: 'Download' }],
            },
        ]
    }, [] as Array<ITableDataBody>)
}
