import styled, { css } from 'styled-components'

interface IRoot {
    height?: number
    width?: number
    maxWidth?: number
    border?: boolean
    fullWidth?: boolean
    content?: 'center' | 'left' | 'right'
    padding: number
    mPadding?: number
}

export const Root = styled.div<IRoot>`
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
    border-radius: 8px;
    padding: 0 ${({ padding }) => padding}px;
    background-color: ${({ theme }) => theme.palette.primary.white};
    display: flex;
    flex-direction: column;
    justify-content: ${({ content }) => content || 'start'};
    box-sizing: border-box;

    ${({ border }) =>
        !!border &&
        css`
            border: 1px solid ${({ theme }) => theme.palette.primary.light};
        `}

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            flex: 1;
        `}


    ${({ mPadding, padding }) =>
        mPadding &&
        css`
            @media ${({ theme }) => theme.breakpoints.max.md} {
                padding: 0 ${mPadding || padding}px;
            }
        `}
`
