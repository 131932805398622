import React, { FC, Children, ReactNode, useMemo, useState, isValidElement } from 'react'
import ListItem from '@mui/material/ListItem'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Image, Text } from 'components/shared'

import selectImg from 'assets/images/select.svg'
import { Gutter, Row } from 'styles'
import { ListSc } from './styles'

interface IProps {
    selectedIndex?: number
}

const Select: FC<IProps> = props => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex ?? 0)
    const open = Boolean(anchorEl)
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, el: ReactNode) => {
        setSelectedIndex(index)
        setAnchorEl(null)
        isValidElement(el) && !!el.props.onClick && el.props.onClick()
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const titles = useMemo(() => {
        return Children.toArray(props.children).map((child: ReactNode) => {
            if (isValidElement(child)) {
                return child.props.children
            } else {
                throw new Error('Not valid react children element')
            }
        })
    }, [props.children])

    return (
        <>
            <ListSc>
                <ListItem button onClick={handleClickListItem}>
                    <Gutter size={10} content="center" align="center">
                        <Text el="h4">{titles[selectedIndex]}</Text>
                        <Image width={8} height={15} image={selectImg} />
                    </Gutter>
                </ListItem>
            </ListSc>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {Children.toArray(props.children).map((option, index) => (
                    <MenuItem key={index} selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index, option)}>
                        <Row> {option} </Row>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default Select
