import { Dialog } from '@mui/material'
import React, { FC } from 'react'
import { CloseButton } from 'styles'
import { SendOutDetails } from '.'

export interface IPros {
    isOpen: ISendOutAdmin | ISendout | null
    close: () => void
}

const SendOutDetailsModal: FC<IPros> = props => {
    const handleClose = () => {
        props.close()
    }

    return (
        <Dialog onClose={handleClose} open={!!props.isOpen} fullWidth>
            <CloseButton onClick={handleClose} />
            <SendOutDetails item={props.isOpen} handleClose={handleClose} />
        </Dialog>
    )
}

export default SendOutDetailsModal
