import { CheckOval } from 'assets/svg/CheckOval'
import { Text } from 'components/shared'
import { FC } from 'react'
import { Gutter, Space } from 'styles'
import { Root } from './styles'

interface IProps {
    details?: ISubscription
}

const AddressDetails: FC<IProps> = props => {
    return (
        <Root>
            <Text el={'h2'}>{`${props.details?.duration} months - ${props.details?.price}`}</Text>
            <Space bottom={20} />
            <Text el={'h4'}>What’s included:</Text>
            <Space bottom={15} />
            <Gutter size={15}>
                <CheckOval width={20} height={20} />
                <Text el={'subtitle'}>Instant virtual address</Text>
            </Gutter>
            <Space bottom={10} />
            <Gutter size={15}>
                <CheckOval width={20} height={20} />
                <Text el={'subtitle'}>{props.details?.freeCorrespondenceCount} free correspondences (Open & scan to email service)</Text>
            </Gutter>
            <Space bottom={20} />
            <Text el={'h4'}>Pay as you go:</Text>
            <Space bottom={15} />
            <Gutter size={15}>
                <CheckOval width={20} height={20} />
                <Text el={'subtitle'}>
                    <b>{props.details?.extraCorrespondencePrice}</b> per every additional correspondence (Open & scan to e-mail service)
                </Text>
            </Gutter>
            <Space bottom={10} />
            <Gutter size={15}>
                <CheckOval width={20} height={20} />
                <Text el={'subtitle'}>
                    <b>{props.details?.correspondenceSendPrice}</b> cost of sending out each correspondence (By post to your address)
                </Text>
            </Gutter>
        </Root>
    )
}

export default AddressDetails
