import styled from 'styled-components'

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    padding: 30px 0;
    border-radius: 8px;
`
