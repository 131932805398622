import documentImg from 'assets/images/documentCircle.svg'
import { Dispatch } from 'react'
import * as correspondences from 'reducers/correspondence'
import * as download from 'reducers/download'
import { Correspondences, Files } from 'services'
import { dateAndTimeToLocaleString, downloadFileInBrowser } from 'utils'

export const getCorrespondences = async (dispatch: Dispatch<correspondences.IReducer>, query?: string) => {
    try {
        dispatch(correspondences.startGetCorrespondences())
        const { data }: { data: ICorrespondences } = await Correspondences.correspondences(query)
        dispatch(correspondences.successGetCorrespondences(data))
    } catch (e) {
        dispatch(correspondences.errorGetCorrespondences())
    }
}
export const getCorrespondencesTypes = async (dispatch: Dispatch<correspondences.IReducer>) => {
    try {
        dispatch(correspondences.startGetCorrespondencesTypes())
        const { data }: { data: Array<ICorrespondenceTypes> } = await Correspondences.correspondencesTypes()
        dispatch(correspondences.successGetCorrespondencesTypes(data))
    } catch (e) {
        dispatch(correspondences.errorGetCorrespondencesTypes())
    }
}
export const getCorrespondencesStatuses = async (dispatch: Dispatch<correspondences.IReducer>) => {
    try {
        dispatch(correspondences.startGetCorrespondencesStatuses())
        const { data }: { data: Array<ICorrespondenceStatuses> } = await Correspondences.correspondencesStatuses()
        dispatch(correspondences.successGetCorrespondencesStatuses(data))
    } catch (e) {
        dispatch(correspondences.errorGetCorrespondencesStatuses())
    }
}

export const downloadFile = async (dispatchCorrespondence: Dispatch<correspondences.IReducer>, dispatchDownload: Dispatch<download.IReducer>, files?: Array<IFile>, correspondenceId?: string) => {
    try {
        dispatchDownload(download.startFileDownload())
        files?.forEach(async (file: IFile) => {
            const { data } = await Files.download(file.id)
            downloadFileInBrowser(data)
        })
        dispatchDownload(download.successFileDownload())
        dispatchCorrespondence(correspondences.successCorrespondenceIsDownloaded(correspondenceId))
    } catch (e) {
        dispatchDownload(download.errorFileDownload())
    }
}

const header: Array<string> = ['Sender', 'Address', 'Type', 'Status', '']

export const parseCorrespondences = (data: Array<ICorrespondence> = []): ITableData => {
    return {
        header,
        body: body(data),
    }
}

const body = (data: Array<ICorrespondence> = []): Array<ITableDataBody> => {
    return data.reduce((acc, cur) => {
        const downloadOrEmpty: ILastTableData = cur.files.some(Boolean) ? { type: 'click', value: 'download' } : { type: 'empty', value: '' }

        return [
            ...acc,
            {
                id: cur.id,
                firstTableData: {
                    image: documentImg,
                    top: cur?.senderName,
                    bottom: dateAndTimeToLocaleString(cur?.createdAt),
                    new: !cur.isDownloaded,
                },
                mainData: [cur?.addressName, cur?.type],
                lastTableData: [{ type: 'status', value: cur.status }, downloadOrEmpty],
                files: cur.files,
            },
        ]
    }, [] as Array<ITableDataBody>)
}
