import FailureModal from 'components/Forms/FailureModal/FailureModal'
import SendOutDetailsModal from 'components/Forms/SendOutDetails/SendOutDetails-modal'
import { Progress, Table } from 'components/shared'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdminSendoutsContext, finishGetSendoutsAdmin, finishMakeAsSend } from 'reducers/admin/sendouts'
import { Space } from 'styles'
import { localStorageHelper, makeQueryParamsString } from 'utils'
import { completeSendOut, parseSendouts, startGetSendouts, startMakeAsSend } from './Sendouts-state'

const LIMIT = 10

const Sendouts = () => {
    const { t } = useTranslation()
    const [sendoutsAdminState, sendoutsAdminDispatch] = useContext(AdminSendoutsContext)
    const subscriptionId = localStorageHelper('subscriptionId')
    const queries = makeQueryParamsString({ limit: LIMIT })
    const [sendout, setSendout] = useState<ISendOutAdmin | null>(null)

    useEffect(() => {
        startGetSendouts(sendoutsAdminDispatch, subscriptionId, queries)
        return () => {
            sendoutsAdminDispatch(finishGetSendoutsAdmin())
        }
    }, [queries, sendoutsAdminDispatch, subscriptionId])

    const items: ITableData = useMemo(
        () =>
            parseSendouts(sendoutsAdminState.sendouts, [
                t('adminTabComponentsSendOuts.Document'),
                t('adminTabComponentsSendOuts.Receiver'),
                t('adminTabComponentsSendOuts.Address'),
                t('adminTabComponentsSendOuts.Status'),
                '',
            ]),
        [sendoutsAdminState.sendouts, t]
    )

    const handleActionClick = useCallback(
        (body?: ITableDataBody, type?: LastTableDataClickType) => {
            if (type === 'ONE') {
                const sendOut: ISendOutAdmin = completeSendOut(body?.originalData)
                startMakeAsSend(sendoutsAdminDispatch, sendOut)
            } else {
                !!body && setSendout(body.originalData)
            }
        },
        [sendoutsAdminDispatch]
    )

    const handleCloseFailureModal = () => {
        sendoutsAdminDispatch(finishMakeAsSend())
    }

    const viewDetailsClickActions = {
        action: handleActionClick,
        loading: !!sendoutsAdminState.loading.MAKE_AS_SEND,
    }

    const tableItems = {
        data: items,
        loading: sendoutsAdminState.loading.GET_SEND_OUTS_ADMIN,
    }

    return (
        <div>
            {!!sendoutsAdminState.loading.MAKE_AS_SEND && <Progress />}
            <Space bottom={30} />
            <Table items={tableItems} lastDataClick={viewDetailsClickActions} />
            <Space bottom={30} />
            <SendOutDetailsModal isOpen={sendout} close={() => setSendout(null)} />
            <FailureModal isOpen={!!sendoutsAdminState.error?.MAKE_AS_SEND?.some(Boolean)} close={handleCloseFailureModal} errors={sendoutsAdminState.error?.MAKE_AS_SEND} />
        </div>
    )
}

export default Sendouts
