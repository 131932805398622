import { Button } from 'components/shared'
import React, { FC } from 'react'
import { Gutter } from 'styles'
import { Root } from './styles'

interface IProps {
    title: string
    buttonBody: string
    onClick: () => void
}

const LinkingButton: FC<IProps> = props => {
    return (
        <Root>
            <Gutter size={20} align={'center'} content={'right'}>
                <div>{props.title}</div>
                <Button width={101} height={38} bordered onClick={props.onClick}>
                    {props.buttonBody}
                </Button>
            </Gutter>
        </Root>
    )
}

export default LinkingButton
