import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { navigationRoutes } from 'routes/Routes'
import { Gutter } from 'styles'
import { Root } from './styles'

interface IProps {
    color?: string
}

const Footer: FC<IProps> = props => {
    const { t } = useTranslation()

    return (
        <Root color={props.color}>
            <Gutter size={10}>
                <div>{`© ${new Date().getFullYear()} Offiz `}</div>
                <div>{'|'}</div>
                <Link to={navigationRoutes.legal.termsOfUse}>{t('footer.termsOfUse')}</Link>
            </Gutter>
        </Root>
    )
}

export default Footer
