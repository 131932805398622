import { EmptyOval } from 'assets/svg/EmptyOval'
import { Card, Text } from 'components/shared'
import { HeaderCenter } from 'layouts'
import React from 'react'
import { Gutter, Space } from 'styles'
import { themeProperties } from 'theme'

const Legal = () => {
    return (
        <HeaderCenter>
            <Space bottom={50} />
            <Card padding={30} content="center">
                <Space bottom={50} />
                <Text el="h2" align="center">
                    VIRTUAL ADDRESS SERVICE TERMS & CONDITIONS
                </Text>
                <Space bottom={50} />
                <Text el="subtitle">
                    These virtual address service terms & conditions (the “T&Cs”) are subject to virtual address services provided by UAB “TETERA”, company code 305706375, company registered in
                    Republic of Lithuania, registered address Savanorių pr. 219, Vilnius, Lithuania (the “Service provider”) and accepted by the applicant of virtual address services (the “Customer”),
                    both executing those terms and conditions stated in this agreement.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    T&Cs shall be governed by and construed in accordance with the laws of the Republic of Lithuania and the parties hereby irrevocably submit to the exclusive jurisdiction of the
                    Courts of Lithuania.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Customer understands and agrees that they will have no right to occupy and access any part of the premises and any equipment or facilities within the premises of the Service
                    Provider under this agreement.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Customer understands and agrees that if they use the registered address of the Service Provider to apply for any license, they MUST get written approval from the Service
                    Provider. Otherwise, the Service Provider would terminate the service(s) with immediate effect and send objection to the related party
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Customer shall not publish or use the provided address without the prior authorization from the Service Provider, before the commencement of services or after termination of
                    services, or on behalf of a company or user not registered with the Service Provider. The Service Provider reserves all rights for claiming against all losses and expenses
                    incurred.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    Before the commencement of service, during suspension period, or any situation without the prior authorization, the Service Provider will reject all mails, parcels, fax or any
                    other objects sent to the Customer and will not handle all calls from the assigned telephone number. The Service Provider is not responsible to notify the Customer of such
                    delivery. 30 days after the termination of service, any mails, parcels, fax or any other objects sent to or left at any offices of the Service Provider shall be at the disposal of
                    the Service Provider at its.
                </Text>
                <Space bottom={30} />
                <Text el="h3">
                    Under the following circumstances, the Service Provider reserves all rights to terminate services without any prior notice. The Service Provider shall bear no legal
                    responsibilities nor shall be liable for any claims or compensation for discontinuing services. The following circumstances may be:
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        The Customer is running any business that is identified as direct or indirect competition to the Service Provider
                    </Gutter>
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        The Customer has failed to settle any service fees, handling fees or reimbursements of postage, or to renew business registration on time; or to submit necessary documents
                        required by the law of Lithuania
                    </Gutter>
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        In suspicion of the Customer is involving or carrying out a fraud and any illegal or improper activities
                    </Gutter>
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        In suspicion of the Customer is using provided services to hold any promotional sales or public recruitment events
                    </Gutter>
                </Text>
                <Space bottom={30} />

                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        In suspicion of the Customer is transferring or assigning any or part of the services to any other parties, without the prior authorization from the Service Provider
                    </Gutter>
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        The Customer, or any contact person(s) of the Customer is ordered to pay debt by phone calls, letters, by person, etc.
                    </Gutter>
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        The Customer, or any contact person(s) of the Customer has caused any third party to disturb the operation at any service point of the Service Provider to claim for lost,
                        protest, etc.
                    </Gutter>
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        The Customer, or any contact person(s) of the Customer has caused the press and media to arrive at any service point of the Service Provider for interview, filming or taking
                        photograph
                    </Gutter>
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    <Gutter size={10} align="center">
                        <EmptyOval width={15} height={15} color={themeProperties.palette.primary.main} />
                        The Customers' company has been liquidated / wound-up, a Baliff's presence at the service point and has started effecting the execution of court orders and judgments, or the
                        company has been dissolved
                    </Gutter>
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Client acknowledges and agrees that the Service Provider does not guarantee to endorse an application for the Client regarding the declaration to including but not limited to
                    the government and law enforcement agencies in relation to the use of our company’s registered address as the Customer’s address. The Service Provider may require up to 14 days to
                    review the relevant documents and may induce additional charges for endorsing the associated documents. The service provider reserves the right to refuse to endorse any documents,
                    and to submit the notification of termination of relevant authorization to the government and law enforcement agencies.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">Should any disputes arise, the decision of the Service Provider shall be final.</Text>
                <Space bottom={50} />
                <Text el="h2" align="center">
                    VIRTUAL ADDRESS SERVICE TERMS & CONDITIONS
                </Text>
                <Space bottom={50} />
                <Text el="subtitle">
                    The Customer acknowledges that due to the imperfect nature of verbal, written and electronic communications, the Service Provider is not responsible for any failure to render any
                    service, any error or omission, or any delay or interruption of any service, the sole obligation is limited to the service charges during the affected period.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Customer agrees to waive, and agrees not to make, any claims for damages, direct or consequential, including with respect to lost business or profits, arising out of any
                    failure to furnish any service, any error or omission with respect there to, or any delay or interruption of services.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Service Provider agrees that they are not allowed to sell or provide any information of the Customer to any third party without consent from the Customer.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Customer bears full responsibility for the lawfulness of the requests, submitted to the Service Provider. The Customer is fully responsible for the lawfulness of the
                    correspondence he receives and / or sends, using the virtual address services
                </Text>
                <Space bottom={50} />
                <Text el="h2" align="center">
                    CONTRACT PERIOD
                </Text>
                <Space bottom={50} />
                <Text el="subtitle">
                    The first contract period will be the period started from the date of service commenced to the last date of the period covered within the first payment. The contract period will be
                    extended according to the period covered of each payment of the Customer afterward, and the content of this agreement will be applicable within the extended contract period.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">The term of the services provided is subject to the plan the Customer selected.</Text>
                <Space bottom={30} />
                <Text el="subtitle">Service fee will not be refund for early termination.</Text>
                <Space bottom={50} />
                <Text el="h2" align="center">
                    OTHER PROVISIONS
                </Text>
                <Space bottom={50} />
                <Text el="subtitle">
                    The Customer hereby understands that virtual address service only covers the parcel (documents) storage, digitization and related activities of the virtual address and
                    correspondence management. The Customer is only allowed to receive documents (printed documents, forms, applications, cards).
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Service Provider does not accept special (registered) shipments, items (goods) or registered shipments that require special or certain approvals by the Customer or shipments
                    that require Customer’s personal signature or Service Provider is not authorized to receive such shipments.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Service Provider reserves the rights to refuse receiving any dangerous or illegal items for the Customer. The Customer agrees to be personally liable for any illegal activity
                    (i.e. receiving prohibited items, fraudulent documents, etc.) according to the laws of Lithuania.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">The handling charge of the parcel received for the Customer will be based on the storing time and the quantity, details as follows.</Text>
                <Space bottom={30} />
                <Text el="subtitle">The Service Provider shall not be liable if the stored items are lost, being stolen or damaged due to any reason during the storage period.</Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    If the mail forwarding services is required, the Customer should state at the time of application. Otherwise, the Customer should notice the Service Provider by email or written
                    notice afterward every time.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">The Service Provider shall not be liable for any losses, damages, costs, claims and expenses of liabilities of whatever nature in mail forwarding.</Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    The Customer shall pay the fees before the due date specified on the relevant invoices, or the Service Provider has the right to suspend the services to the Customer. The Customer
                    has the responsibility to make sure that their payments are received and identified by the Service Provider before the due date specified on the relevant invoices.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    If Customer requests to reactive the services which has been suspended by the Service Provider due to late payment or overdue situation, the Service Provider shall charge the
                    Customer the service fees for the suspended period.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">
                    If the Customer requires to make amendments to service instructions, contents or location, a written notice should be given to the Service Provider to the amendment take place,
                    related service fees shall apply.
                </Text>
                <Space bottom={30} />
                <Text el="subtitle">The T&Cs shall be valid for the period which the Customer purchased to services.</Text>
                <Space bottom={50} />
            </Card>
            <Space bottom={50} />
        </HeaderCenter>
    )
}

export default Legal
