import React, { FC, useCallback } from 'react'
import { tabComponents } from 'components/MainTabs/TabComponents'
import { Root, NavLink } from './styles'
import { trimAndLowercase } from 'utils'
import { useParams, useHistory } from 'react-router'
import { navigationRoutes } from 'routes/Routes'

interface IProps {
    hamburgerState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

interface IParams {
    tabParam: string
}

const MobileNav: FC<IProps> = props => {
    const history = useHistory()
    const { tabParam } = useParams<IParams>()
    const [isHamburgerClicked, hamBurgerState] = props.hamburgerState

    const isSelected = useCallback((name: string): boolean => trimAndLowercase(name) === tabParam, [tabParam])
    const handleNavClick = (name: string) => {
        history.push(`${navigationRoutes.user.base}/${trimAndLowercase(name)}`)
        hamBurgerState(!isHamburgerClicked)
    }

    return (
        <>
            {isHamburgerClicked && (
                <Root>
                    {tabComponents.map(({ name }) => (
                        <NavLink key={name} selected={isSelected(name)} onClick={() => handleNavClick(name)}>
                            {name}
                        </NavLink>
                    ))}
                </Root>
            )}
        </>
    )
}

export default MobileNav
