import { Dispatch } from 'react'
import * as addresses from 'reducers/admin/addresses'
import { AddressesAdmin } from 'services'
import houseCircleGreyImg from 'assets/images/houseCircleGrey.svg'
import houseCircleDarkGreyImg from 'assets/images/houseCircleDarkGrey.svg'
import { dateAndTimeToLocaleString } from 'utils'

export const getAddresses = async (dispatch: Dispatch<addresses.IReducer>, query?: string) => {
    try {
        dispatch(addresses.startGetAddresses())
        const { data }: { data: IAdminAddresses } = await AddressesAdmin.addresses(query)
        dispatch(addresses.successGetAddresses(data))
    } catch (e) {
        dispatch(addresses.errorGetAddresses())
    }
}

export const parseAddresses = (data: Array<IAdminAddress> = [], header: Array<string>): ITableData => {
    return {
        header,
        body: body(data, header),
    }
}

const body = (data: Array<IAdminAddress> = [], header: Array<string>): Array<ITableDataBody> => {
    return data.reduce((acc, cur, i) => {
        return [
            ...acc,
            {
                id: `${i}`,
                firstTableData: {
                    image: houseCircleGreyImg,
                    imageOnCollapse: houseCircleDarkGreyImg,
                    top: `${cur.street}, ${cur.city}, ${cur.postCode}, ${cur.countryCode}`,
                },
                mainData: [],
                lastTableData: [
                    { type: 'status', value: cur.status },
                    { type: 'collapse', value: cur.status },
                ],
                collapsedData: {
                    header,
                    body: userSubscriptions(cur.userSubscriptions),
                    colSpan: header.length,
                },
            },
        ]
    }, [] as Array<ITableDataBody>)
}

const userSubscriptions = (data: Array<IAdminAddressUserSubscription>): Array<ITableDataBody> => {
    return data.reduce((acc, cur) => {
        return [
            ...acc,
            {
                id: cur.id,
                firstTableData: {
                    top: cur.subscriptionName,
                },
                mainData: [cur.id, dateAndTimeToLocaleString(cur.expiresAt)],
                lastTableData: [
                    { type: 'status', value: cur.status },
                    { type: 'buttonClick', value: 'view details' },
                ],
            },
        ]
    }, [] as Array<ITableDataBody>)
}
