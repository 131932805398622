import { OrderAddress, OrderAddressLong } from 'components/promos'
import { Progress } from 'components/shared'
import { VirtualAddresses } from 'components/VirtualAddresses'
import React, { FC, useContext, useEffect } from 'react'
import * as virtualAddresses from 'reducers/virtualAddresses'
import { Space } from 'styles'
import { getSubscriptions } from './Home-state'

const Home = () => {
    const [state, dispatch] = useContext(virtualAddresses.VirtualAddressesContext)

    useEffect(() => {
        getSubscriptions(dispatch)
    }, [dispatch])

    return (
        <InitialAddresses state={state}>
            <VirtualAddresses subscriptions={state.subscriptions} />
            <OrderAddressLong />
            <Space bottom={50} />
        </InitialAddresses>
    )
}

interface IInitialAddressesProps {
    state: virtualAddresses.IState
}

const InitialAddresses: FC<IInitialAddressesProps> = props => {
    return <div>{props.state.subscriptions?.some(Boolean) ? props.children : props.state.loading ? <Progress /> : <OrderAddress />}</div>
}

export default Home
