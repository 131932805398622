import React, { FC } from 'react'
import { Root } from './styles'

interface IProps {
    width: number
    height: number
    color?: string
}

const CheckOval: FC<IProps> = props => {
    return (
        <Root width={props.width} height={props.height}>
            <svg width={`${props.width}`} height={`${props.height}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="4" width="14" height="12" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM3 10L8 15L17 6L15.59 4.58L8 12.17L4.41 8.59L3 10Z"
                    fill={`${props.color || '#60D956'}`}
                />
            </svg>
        </Root>
    )
}

export default CheckOval
