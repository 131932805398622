import React, { FC } from 'react'

interface IProps {
    width: number
    height: number
    color: string
}

const EmptyOval: FC<IProps> = props => {
    return (
        <div style={{ minHeight: props.height, minWidth: props.width }}>
            <svg width={`${props.width}`} height={`${props.height}`} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="3" stroke={`${props.color || '#874BBB'}`} strokeWidth="2" />
            </svg>
        </div>
    )
}

export default EmptyOval
