import { Dispatch } from 'react'
import * as signUp from 'reducers/signUp'
import { User } from 'services'

export const signUpUser = async (dispatch: Dispatch<signUp.IReducer>, user: ISignUpUser) => {
    try {
        dispatch(signUp.startSignUp())
        const res = await User.signUp(user)
        if (res?.status === 204) dispatch(signUp.successSignUp())
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data?.errors
        dispatch(signUp.errorSignUp(errors))
    }
}
