import { Dispatch } from 'react'
import * as correspondencesAdmin from 'reducers/admin/correspondences'
import * as correspondences from 'reducers/correspondence'
import { UserSubscriptionsAdmin, Correspondences } from 'services'

import documentCircleImg from 'assets/images/documentCircle.svg'
import { dateAndTimeToLocaleString } from 'utils'

export const getCorrespondences = async (dispatch: Dispatch<correspondencesAdmin.IReducer>, id?: string, query?: string) => {
    try {
        dispatch(correspondencesAdmin.startGetCorrespondences())
        const { data }: { data: ICorrespondencesAdmin } = await UserSubscriptionsAdmin.correspondences(id, query)
        dispatch(correspondencesAdmin.successGetCorrespondences(data))
    } catch (e) {
        dispatch(correspondencesAdmin.errorGetCorrespondences())
    }
}

export const getCorrespondencesTypes = async (dispatch: Dispatch<correspondences.IReducer>) => {
    try {
        dispatch(correspondences.startGetCorrespondencesTypes())
        const { data }: { data: Array<ICorrespondenceTypes> } = await Correspondences.correspondencesTypes()
        dispatch(correspondences.successGetCorrespondencesTypes(data))
    } catch (e) {
        dispatch(correspondences.errorGetCorrespondencesTypes())
    }
}
export const getCorrespondencesStatuses = async (dispatch: Dispatch<correspondences.IReducer>) => {
    try {
        dispatch(correspondences.startGetCorrespondencesStatuses())
        const { data }: { data: Array<ICorrespondenceStatuses> } = await Correspondences.correspondencesStatuses()
        dispatch(correspondences.successGetCorrespondencesStatuses(data))
    } catch (e) {
        dispatch(correspondences.errorGetCorrespondencesStatuses())
    }
}

export const parseCorrespondences = (data: Array<ICorrespondenceAdmin> = [], header: Array<string>): ITableData => {
    return {
        header,
        body: body(data),
    }
}

const body = (data: Array<ICorrespondenceAdmin> = []): Array<ITableDataBody> => {
    return data.reduce((acc, cur, i) => {
        return [
            ...acc,
            {
                id: cur.id,
                firstTableData: {
                    image: documentCircleImg,
                    top: cur.senderName,
                    bottom: dateAndTimeToLocaleString(cur?.createdAt),
                    new: !cur.isDownloaded,
                },
                mainData: [cur.type],
                lastTableData: [
                    { type: 'status', value: cur.status },
                    { type: 'click', value: 'Actions' },
                ],
                files: cur.files,
                type: cur.type,
                status: cur.status,
            },
        ]
    }, [] as Array<ITableDataBody>)
}
