enum CorrespondencesTypes {
    START_GET_CORRESPONDENCES = 'START_GET_CORRESPONDENCES',
    SUCCESS_GET_CORRESPONDENCES = 'SUCCESS_GET_CORRESPONDENCES',
    FINISH_GET_CORRESPONDENCES = 'FINISH_GET_CORRESPONDENCES',
    ERROR_GET_CORRESPONDENCES = 'ERROR_GET_CORRESPONDENCES',

    START_UPLOAD_CORRESPONDENCES = 'START_UPLOAD_CORRESPONDENCES',
    SUCCESS_UPLOAD_CORRESPONDENCES = 'SUCCESS_UPLOAD_CORRESPONDENCES',
    FINISH_UPLOAD_CORRESPONDENCES = 'FINISH_UPLOAD_CORRESPONDENCES',
    ERROR_UPLOAD_CORRESPONDENCES = 'ERROR_UPLOAD_CORRESPONDENCES',

    START_EDIT_CORRESPONDENCES = 'START_EDIT_CORRESPONDENCES',
    SUCCESS_EDIT_CORRESPONDENCES = 'SUCCESS_EDIT_CORRESPONDENCES',
    FINISH_EDIT_CORRESPONDENCES = 'FINISH_EDIT_CORRESPONDENCES',
    ERROR_EDIT_CORRESPONDENCES = 'ERROR_EDIT_CORRESPONDENCES',

    START_DELETE_CORRESPONDENCES = 'START_DELETE_CORRESPONDENCES',
    SUCCESS_DELETE_CORRESPONDENCES = 'SUCCESS_DELETE_CORRESPONDENCES',
    FINISH_DELETE_CORRESPONDENCES = 'FINISH_DELETE_CORRESPONDENCES',
    ERROR_DELETE_CORRESPONDENCES = 'ERROR_DELETE_CORRESPONDENCES',
}
export default CorrespondencesTypes
