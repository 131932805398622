enum SignInTypes {
    START_SIGN_IN = 'START_SIGN_IN',
    SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN',
    FINISH_SIGN_IN = 'FINISH_SIGN_IN',
    ERROR_SIGN_IN = 'ERROR_SIGN_IN',
    START_MAGIC_LINK = 'START_MAGIC_LINK',
    SUCCESS_MAGIC_LINK = 'SUCCESS_MAGIC_LINK',
    ERROR_MAGIC_LINK = 'ERROR_MAGIC_LINK',
    FINISH_MAGIC_LINK = 'FINISH_MAGIC_LINK',
}
export default SignInTypes
