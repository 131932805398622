import React, { FC } from 'react'
import { Root } from './styles'
import { IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'

interface IProps {
    onClick: () => void
}

const CloseButton: FC<IProps> = props => {
    return (
        <Root onClick={props.onClick}>
            <IconButton color="primary">
                <Close />
            </IconButton>
        </Root>
    )
}

export default CloseButton
