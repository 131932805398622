import VerifyDocumentTypes from './verifyDocument-types'

export const startGetInfo = () => ({
    type: VerifyDocumentTypes.START_GET_INFO,
})
export const successGetInfo = (data: IDocumentInfo) => ({
    type: VerifyDocumentTypes.SUCCESS_GET_INFO,
    verifyDocument: data,
})
export const errorGetInfo = (errors: Array<IError>) => ({
    type: VerifyDocumentTypes.ERROR_GET_INFO,
    errors,
})
export const finishGetInfo = () => ({
    type: VerifyDocumentTypes.FINISH_GET_INFO,
})
