import backArrowImg from 'assets/images/backArrow.svg'
import { Text } from 'components/shared/Text'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Gutter, Space } from 'styles'
import { Image } from '..'
import { GoBack, Logo, Root } from './styles'

interface IProps {
    goBackRoute?: string
}

const HeaderWithGoBack: FC<IProps> = props => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Root>
            <GoBack onClick={() => (!!props.goBackRoute ? history.push(props.goBackRoute) : history.goBack())}>
                <Gutter size={15}>
                    <Space left={30} mLeft={1} />
                    <Image height={11} width={17} image={backArrowImg} />
                    <Text el="h4">{t('headerWithGoBack.goBack')}</Text>
                </Gutter>
            </GoBack>
            <Logo>offiz</Logo>
        </Root>
    )
}

export default HeaderWithGoBack
