import { FC, useState, Children, ReactNode } from 'react'
import Tab from '@mui/material/Tab'
import { Root } from './styles'

interface IProps {
    setCarouselIndex?: (index: number) => void
    carouselIndex?: number
}

/**
 *
 * @param props if setCarouselIndex and carouselIndex passed use it to set index otherwise use useState
 * @returns Carousel with passed items in children
 */

const ItemsCarousel: FC<IProps> = props => {
    const [value, setValue] = useState(0)

    const indexFromParent = props.setCarouselIndex && props.carouselIndex

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        props.setCarouselIndex ? props.setCarouselIndex(newValue) : setValue(newValue)
    }

    return (
        <Root value={indexFromParent ? props.carouselIndex : value} visibleScrollbar={false} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            {Children.map(props.children, (element: ReactNode) => (
                <Tab label={element} />
            ))}
        </Root>
    )
}

export default ItemsCarousel
