import { DetailsViewAll } from 'components/shared'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { navigationRoutes } from 'routes/Routes'
import { Gutter } from 'styles'
import { useTranslation } from 'react-i18next'

interface IProps extends Partial<IUserSubscriptionsAdmin> {}

const Details: FC<IProps> = props => {
    const history = useHistory()
    const { t } = useTranslation()

    return (
        <Gutter size={28} mobile equalChildSize>
            <DetailsViewAll
                name={t('adminDetails.Correspondence')}
                subTitle={t('adminDetails.totalReceivedMails')}
                count={props.correspondences?.count}
                viewAllAction={() => history.push(navigationRoutes.adminSubscription.correspondence)}
            />
            <DetailsViewAll
                name={t('adminDetails.utilityBills')}
                subTitle={t('adminDetails.poaDocuments')}
                count={props.utilityBillCount}
                viewAllAction={() => history.push(navigationRoutes.adminSubscription.utilityBills)}
            />
            <DetailsViewAll
                name={t('adminDetails.Sendouts')}
                subTitle={t('adminDetails.userSendOutDocuments')}
                count={props.sendOuts?.count}
                viewAllAction={() => history.push(navigationRoutes.adminSubscription.sendouts)}
            />
        </Gutter>
    )
}

export default Details
