import { Progress } from 'components/shared'
import { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { SignInContext } from 'reducers/signIn'
import { RedirectApi } from './redirect-state'

interface IUseParams {
    signInToken: string
}

const Redirect = () => {
    const history = useHistory()
    const [, dispatch] = useContext(SignInContext)
    let { signInToken } = useParams<IUseParams>()

    useEffect(() => {
        RedirectApi(dispatch, signInToken, history)
    }, [dispatch, history, signInToken])

    return <Progress />
}

export default Redirect
