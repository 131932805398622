import { Content } from './Content'
import React, { FC, useMemo } from 'react'
import { Gutter, Space } from 'styles'
import { Details } from './Details'
import { Button, Table, Text } from 'components/shared'
import { parseTableData } from 'components/VirtualAddresses/VirtualAddress/VirtualAddress-state'
import UploadCorrespondenceModal from 'components/Forms/UploadCorrespondence/UploadCorrespondence-modal'
import { useTranslation } from 'react-i18next'

interface IProps extends Partial<IUserSubscriptionsAdmin> {}

const SubscriptionDetails: FC<IProps> = props => {
    const { t } = useTranslation()
    const tableData = useMemo(() => parseTableData(props.linkedServices), [props])

    const tableItems = {
        data: tableData,
    }

    return (
        <div>
            <Space bottom={30} />
            <Content {...props} />
            <Space bottom={30} />
            <Gutter size={30} content="space-between" mobile>
                <div>
                    <Text el={'h4'}>
                        {props.subscriber?.firstName} {props.subscriber?.lastName}
                    </Text>
                    <Text el={'subtitle'}>
                        {props.address?.street} {props.address?.postCode}
                    </Text>
                    <Text el={'subtitle'}>
                        {props.address?.city} {props.address?.countryCode}
                    </Text>
                </div>
                {!!props.subscriber?.registeredAddress && (
                    <div>
                        <Text el={'h4'}>{t('adminSubscriptionDetails.registeredAddress')} </Text>
                        <Text el={'subtitle'}>
                            {props.subscriber?.registeredAddress?.street} {props.subscriber?.registeredAddress?.postCode}
                        </Text>
                        <Text el={'subtitle'}>
                            {props.subscriber?.registeredAddress?.city} {props.subscriber?.registeredAddress?.countryCode}
                        </Text>
                    </div>
                )}
                <UploadCorrespondenceModal>
                    <Button width={200} maxWidth={200}>
                        {t('adminSubscriptionDetails.uploadCorrespondence')}
                    </Button>
                </UploadCorrespondenceModal>
            </Gutter>
            <Space bottom={30} />
            <Details {...props} />
            <Space bottom={30} />
            <Text el="h3">{t('adminSubscriptionDetails.linkedServices')}</Text>
            <Space bottom={20} />
            <Table items={tableItems} />
            <Space bottom={50} />
        </div>
    )
}

export default SubscriptionDetails
