import { LoadMoreButton, Table, TableFilters } from 'components/shared'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AdminCorrespondencesContext, finishGetCorrespondences } from 'reducers/admin/correspondences'
import { Space } from 'styles'
import { localStorageHelper, makeQueryParamsString } from 'utils'
import { getCorrespondences, getCorrespondencesStatuses, getCorrespondencesTypes, parseCorrespondences } from './Correspondences-state'
import * as correspondences from 'reducers/correspondence'
import EditDeleteCorrespondenceModal from 'components/Forms/EditDeleteCorrespondence/EditDeleteCorrespondence-modal'
import { useTranslation } from 'react-i18next'

const LIMIT = 10

const Correspondences = () => {
    const { t } = useTranslation()
    const [correspondencesAdminState, correspondencesDispatch] = useContext(AdminCorrespondencesContext)
    const [correspondenceState, correspondenceDispatch] = useContext(correspondences.CorrespondenceTypesContext)
    const [isCorrespondence, setCorrespondence] = useState<ITableDataBody | null>(null)
    const filterResults = useRef<Object>()
    const filterLimit = useRef<number>(LIMIT)

    const subscriptionId = localStorageHelper('subscriptionId')

    useEffect(() => {
        const queries = makeQueryParamsString({ limit: LIMIT })
        getCorrespondencesTypes(correspondenceDispatch)
        getCorrespondencesStatuses(correspondenceDispatch)
        subscriptionId && getCorrespondences(correspondencesDispatch, subscriptionId, queries)

        return () => {
            correspondencesDispatch(finishGetCorrespondences())
        }
    }, [correspondenceDispatch, correspondencesDispatch, subscriptionId])

    const items: ITableData = useMemo(
        () =>
            parseCorrespondences(correspondencesAdminState.correspondences?.items, [
                t('adminSubscriptionTabsCorrespondences.Sender'),
                t('adminSubscriptionTabsCorrespondences.Type'),
                t('adminSubscriptionTabsCorrespondences.Status'),
                '',
            ]),
        [correspondencesAdminState.correspondences?.items, t]
    )

    const handleFilterClick = useCallback(
        (values: any) => {
            filterResults.current = values
            const queries = makeQueryParamsString({ limit: filterLimit.current, ...values })
            subscriptionId && getCorrespondences(correspondencesDispatch, subscriptionId, queries)
        },
        [correspondencesDispatch, subscriptionId]
    )

    const handleLoadMore = useCallback(
        query => {
            getCorrespondences(correspondencesDispatch, subscriptionId, query)
        },
        [correspondencesDispatch, subscriptionId]
    )

    const handleActionClick = useCallback((body?: ITableDataBody) => {
        !!body && setCorrespondence(body)
    }, [])

    const viewDetailsClickActions = {
        action: handleActionClick,
        loading: correspondencesAdminState.loading.GET_CORRESPONDENCES,
    }

    const filterClickActions = {
        action: handleFilterClick,
        loading: correspondencesAdminState.loading.GET_CORRESPONDENCES,
    }

    const tableItems = {
        data: items,
        loading: correspondencesAdminState.loading.GET_CORRESPONDENCES,
    }

    const filterFields = [
        { label: t('adminSubscriptionTabsCorrespondences.Type'), name: 'type', fields: correspondenceState.correspondencesTypes, selector: 'name' },
        { label: t('adminSubscriptionTabsCorrespondences.Status'), name: 'status', fields: correspondenceState.correspondencesStatuses, selector: 'name' },
    ] as Array<IFilterTableFields>

    return (
        <div>
            <Space bottom={30} />
            <TableFilters fields={filterFields} filterClick={filterClickActions} dateRange />
            <Space bottom={30} />
            <Table items={tableItems} lastDataClick={viewDetailsClickActions} />
            <Space bottom={30} />
            <LoadMoreButton
                onClick={handleLoadMore}
                limit={filterLimit}
                LIMIT={LIMIT}
                count={correspondencesAdminState.correspondences?.count}
                loading={correspondencesAdminState.loading.GET_CORRESPONDENCES}
                filterResults={filterResults}
            />
            <Space bottom={30} />
            <EditDeleteCorrespondenceModal isOpen={isCorrespondence} close={() => setCorrespondence(null)} />
        </div>
    )
}

export default Correspondences
