import { FC, useCallback, useState } from 'react'
import { Root, TextFieldStyle } from './styles'
import { dateFormatting } from 'utils'
import { isValid } from 'date-fns'

interface IProps {
    name: string
    label: string
    disableFuture?: boolean
    handleChange: (name: string, values: string) => void
}

const DatePicker: FC<IProps> = props => {
    const [value, setValue] = useState<unknown>(null)

    const handleDateChange = useCallback(
        (date: any) => {
            if (isValid(date)) {
                setValue(date)
                props.handleChange(props.name, dateFormatting(date))
            }
        },
        [props]
    )

    return <Root maxDate={props.disableFuture ? new Date() : null} label={props.label} value={value} onChange={handleDateChange} renderInput={params => <TextFieldStyle {...params} />} />
}

export default DatePicker
