import { CheckOval } from 'assets/svg/CheckOval'
import { Button, Card, Text } from 'components/shared'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Gutter, Space } from 'styles'

interface IProps {
    marginTop?: boolean
    subtitle?: string
    width?: number
    handleClose?: () => void
}

const SuccessMessage: FC<IProps> = props => {
    const { t } = useTranslation()

    return (
        <Card padding={30} width={props.width}>
            {props.marginTop && <Space bottom={20} />}
            <Gutter size={20} content="center" align="center">
                <CheckOval width={30} height={30} />
                <Text el="h2">{t('successMessage.Success')}</Text>
            </Gutter>
            <Space bottom={20} />
            <Text el="subtitle" align="center">
                {props.subtitle}
            </Text>
            <Space bottom={30} />
            <Button onClick={props.handleClose}>{t('successMessage.Done')}</Button>
            <Space bottom={20} />
        </Card>
    )
}

export default SuccessMessage
