import styled, { css } from 'styled-components'
import { Row } from 'styles'

interface IProps {
    underscore?: boolean
}

export const Root = styled(Row)<IProps>`
    ${({ underscore, theme }) =>
        !!underscore &&
        css`
            border-bottom: 1px solid ${theme.palette.primary.light};
        `}

    @media ${({ theme }) => theme.breakpoints.max.md} {
        overflow-x: scroll;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`
