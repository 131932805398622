import { FC, useCallback, useState } from 'react'
import { Input } from './Input'
import { Root } from './styles'
import { Control, useController } from 'react-hook-form'

interface IProps {
    name: string
    fields: any
    selector?: 'id' | 'name' | 'countryCode'
    label: string
    value?: string | {}
    displaySelect?: string
    control?: Control<any, object>
    rules?: any
    autoComplete?: string
    handleChange?: (name: string, values: string | number) => void
    disabled?: boolean
}

const AutocompleteWithValidation: FC<IProps> = props => {
    const [state, setState] = useState(props.value ? { name: props.value } : null)
    const controllerFields = useController(props)
    const { field } = controllerFields

    const handleChange = useCallback(
        (_: any, newValue: any) => {
            setState(newValue)
            const values = newValue?.[!!props.selector ? props.selector : 'value']
            !!props.handleChange && props.handleChange(props.name, values)
            field.onChange(values)
        },
        [field, props]
    )

    return (
        <Root
            id="combo-box"
            disablePortal
            value={state}
            onChange={handleChange}
            getOptionLabel={(option: any) => option[props.displaySelect || 'name']}
            options={props.fields}
            isOptionEqualToValue={(option: any, value: any) => {
                return option[props.displaySelect || 'name'] === value[props.displaySelect || 'name']
            }}
            disabled={props.disabled}
            renderInput={params => <Input {...params} autoComplete={props.autoComplete} name={props.name} controllerFields={controllerFields} label={props.label} />}
        />
    )
}

export default AutocompleteWithValidation
