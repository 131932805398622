import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

export const languages = ['en']

const resources = {
    en: { translation: require(`./translations/english.json`) },
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'en',
        debug: false,
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
