import { Image, Text } from 'components/shared'
import { FC } from 'react'
import { Space } from 'styles'
import { Root } from './styles'

interface IProps {
    imgHeight: number
    imgWidth: number
    image: string
    title: string
    subtitle: string
}

const LandingBenefit: FC<IProps> = props => {
    return (
        <Root>
            <Image height={props.imgHeight} width={props.imgWidth} image={props.image} />
            <Space bottom={30} />
            <Text el={'h3'} align={'center'}>
                {props.title}
            </Text>
            <Space bottom={15} />
            <Text el={'subtitle'} align={'center'}>
                {props.subtitle}
            </Text>
        </Root>
    )
}

export default LandingBenefit
