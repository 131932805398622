import React, { FC } from 'react'
import { Gutter, Row } from 'styles'
import { Text, Image } from 'components/shared'

import houseImg from 'assets/images/houseCircle.svg'

interface IProps {
    text: string
}

const HeaderWithLogo: FC<IProps> = props => {
    return (
        <Row>
            <Gutter size={10} align={'center'}>
                <Image height={32} width={32} image={houseImg} />
                <Text el="h4">{props.text}</Text>
            </Gutter>
        </Row>
    )
}

export default HeaderWithLogo
