import { dateToLocaleString } from 'utils'

interface Item {
    name: string
    value: any
}

export const flatterSendOuts = (item: ISendOutAdmin | null) => {
    const newItem = { ...item }
    const { address, createdAt } = { ...newItem }
    const { city, postCode, street, countryCode } = { ...address }
    delete newItem.address
    delete newItem.files
    delete newItem.displayName

    const flatterItems = {
        ...newItem,
        createdAt: createdAt && dateToLocaleString(createdAt),
        city,
        postCode,
        street,
        countryCode,
    }

    return Object.entries(flatterItems).reduce((acc, [name, value]): Array<Item> => {
        return [...acc, { name, value }]
    }, [] as Array<Item>)
}
