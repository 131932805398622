enum CorrespondenceTypes {
    START_GET_CORRESPONDENCES = 'START_GET_CORRESPONDENCES',
    SUCCESS_GET_CORRESPONDENCES = 'SUCCESS_GET_CORRESPONDENCES',
    FINISH_GET_CORRESPONDENCES = 'FINISH_GET_CORRESPONDENCES',
    ERROR_GET_CORRESPONDENCES = 'ERROR_GET_CORRESPONDENCES',

    START_GET_CORRESPONDENCES_TYPES = 'START_GET_CORRESPONDENCES_TYPES',
    SUCCESS_GET_CORRESPONDENCES_TYPES = 'SUCCESS_GET_CORRESPONDENCES_TYPES',
    FINISH_GET_CORRESPONDENCES_TYPES = 'FINISH_GET_CORRESPONDENCES_TYPES',
    ERROR_GET_CORRESPONDENCES_TYPES = 'ERROR_GET_CORRESPONDENCES_TYPES',

    START_GET_CORRESPONDENCES_STATUSES = 'START_GET_CORRESPONDENCES_STATUSES',
    SUCCESS_GET_CORRESPONDENCES_STATUSES = 'SUCCESS_GET_CORRESPONDENCES_STATUSES',
    FINISH_GET_CORRESPONDENCES_STATUSES = 'FINISH_GET_CORRESPONDENCES_STATUSES',
    ERROR_GET_CORRESPONDENCES_STATUSES = 'ERROR_GET_CORRESPONDENCES_STATUSES',

    START_CORRESPONDENCE_IS_DOWNLOADED = 'START_CORRESPONDENCE_IS_DOWNLOADED',
    SUCCESS_CORRESPONDENCE_IS_DOWNLOADED = 'SUCCESS_CORRESPONDENCE_IS_DOWNLOADED',
    FINISH_CORRESPONDENCE_IS_DOWNLOADED = 'FINISH_CORRESPONDENCE_IS_DOWNLOADED',
    ERROR_CORRESPONDENCE_IS_DOWNLOADED = 'ERROR_CORRESPONDENCE_IS_DOWNLOADED',
}
export default CorrespondenceTypes
