import { Text } from 'components/shared'
import React, { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { LastTableData } from '../LastTableData'
import { OuterTableData, OuterTableRow, Table, TableRow } from './styles'

interface IProps {
    isCollapsed: boolean
    collapsedData?: ICollapsedTableData
    lastDataClick?: ILastTableClick
}

const TableCollapsed: FC<IProps> = props => {
    const { t } = useTranslation()
    const isData = props.collapsedData?.body?.some(Boolean)

    if (!isData)
        return (
            <OuterTableRow isCollapsed={props.isCollapsed}>
                <td colSpan={props.collapsedData?.colSpan}>{t('collapsedTable.noResultsCouldBeFound')}</td>
            </OuterTableRow>
        )

    return (
        <OuterTableRow isCollapsed={props.isCollapsed}>
            <OuterTableData colSpan={props.collapsedData?.colSpan}>
                <Table>
                    <tbody>
                        <TableCollapsedData collapsedData={props.collapsedData} lastDataClick={props.lastDataClick} />
                    </tbody>
                </Table>
            </OuterTableData>
        </OuterTableRow>
    )
}

interface ITable {
    collapsedData?: ICollapsedTableData
    lastDataClick?: ILastTableClick
}

const TableCollapsedData: FC<ITable> = props => {
    const space = (i: number) => props.collapsedData?.body && props.collapsedData?.body.length > 1 && i < props.collapsedData?.body.length - 1 && <tr style={{ height: 10 }}></tr>

    return (
        <>
            {props.collapsedData?.body?.map((value: ITableDataBody, i: number) => (
                <Fragment key={`collapsedData${i}`}>
                    <TableRow>
                        <td>
                            <Text el={'h4'}>{value.firstTableData?.top}</Text>
                        </td>
                        {value?.mainData.map((middle, i) => (
                            <td key={`collapsedData-mainData${i}`}>{middle}</td>
                        ))}
                        {value.lastTableData?.map((data: ILastTableData, i: number) => (
                            <LastTableData key={`collapsedData-lastTableData${i}`} id={value.id} lastDataClick={props.lastDataClick} data={data} body={value}>
                                {data.value}
                            </LastTableData>
                        ))}
                    </TableRow>
                    {space(i)}
                </Fragment>
            ))}
        </>
    )
}

export default TableCollapsed
