import { History } from 'history'
import { Dispatch } from 'react'
import * as signIn from 'reducers/signIn'
import { navigationRoutes } from 'routes/Routes'
import { User } from 'services'
import { setAccessTokens, clearAccessTokens } from 'utils'

export const RedirectApi = async (dispatch: Dispatch<signIn.IReducer>, signInToken: string, history: History) => {
    try {
        clearAccessTokens()
        dispatch(signIn.startSignIn())
        const { data }: { data: ISignInResponse } = await User.signIn({ signInToken })
        setAccessTokens(data)
        dispatch(signIn.successSignIn(data))
        history.push(navigationRoutes.user.home)
    } catch (e: any) {
        clearAccessTokens()
        dispatch(signIn.finishSignIn())
        const errors: Array<IError> = e.response?.data.errors
        dispatch(signIn.errorSignIn(errors || []))
        history.push(navigationRoutes.signIn)
    }
}
