import { FC } from 'react'
import { Root } from './styles'

interface IProps {}

const Alert: FC<IProps> = props => {
    return <Root>{props.children}</Root>
}

export default Alert
