/**
 *
 * @param name
 * @param value
 * @returns
 */
export const localStorageHelper = <T = any>(name: string, value?: T) => {
    if (value) {
        localStorage.setItem(name, JSON.stringify(value))
        return value
    } else {
        return JSON.parse(localStorage.getItem(name) || '{}') as T
    }
}
