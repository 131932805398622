import styled, { css } from 'styled-components'

export const Table = styled.table`
    width: 100%;
    border-spacing: 0px;

    tr:hover {
        background-color: ${({ theme }) => theme.palette.primary.white} !important;
    }

    tr {
        border-spacing: 5em;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.palette.primary.white};
    }
`
interface IOuterTableRow {
    isCollapsed: boolean
}

export const OuterTableRow = styled.tr<IOuterTableRow>`
    ${({ isCollapsed }) =>
        isCollapsed &&
        css`
            background-color: ${({ theme }) => theme.palette.primary.background};
        `}

    &:hover {
        background-color: ${({ theme }) => theme.palette.primary.background} !important;
    }
`

export const OuterTableData = styled.td`
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.light};
`

interface ITableRow {}

export const TableRow = styled.tr<ITableRow>``
