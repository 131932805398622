import styled from 'styled-components'

export const Root = styled.div`
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${props => props.theme.palette.primary.background};

    @media ${({theme}) => theme.breakpoints.max.md} {
        padding: 20px;
    }
`