enum UtilityBillsTypes {
    START_GET_UTILITY_BILLS = 'START_GET_UTILITY_BILLS',
    SUCCESS_GET_UTILITY_BILLS = 'SUCCESS_GET_UTILITY_BILLS',
    FINISH_GET_UTILITY_BILLS = 'FINISH_GET_UTILITY_BILLS',
    ERROR_GET_UTILITY_BILLS = 'ERROR_GET_UTILITY_BILLS',

    START_GET_UTILITY_BILLS_TYPES = 'START_GET_UTILITY_BILLS_TYPES',
    SUCCESS_GET_UTILITY_BILLS_TYPES = 'SUCCESS_GET_UTILITY_BILLS_TYPES',
    FINISH_GET_UTILITY_BILLS_TYPES = 'FINISH_GET_UTILITY_BILLS_TYPES',
    ERROR_GET_UTILITY_BILLS_TYPES = 'ERROR_GET_UTILITY_BILLS_TYPES',
}
export default UtilityBillsTypes
