import styled, { css } from 'styled-components'

interface IText {
    el?: string
    align?: string
    mAlign?: string
    color?: string
    cursor?: 'pointer'
}

export const Root = styled.div<IText>`
    text-align: ${({ align }) => align || 'left'};

    @media ${({ theme }) => theme.breakpoints.max.sm} {
        text-align: ${({ align, mAlign }) => mAlign || align || 'left'};
    }

    ${props =>
        !!props.cursor &&
        css`
            cursor: ${props.cursor};
        `}

    ${props =>
        !!props.color &&
        css`
            color: ${props.color} !important;
        `}

    ${props =>
        props.el === 'h1' &&
        css`
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
        `}

    ${props =>
        props.el === 'h2' &&
        css`
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
        `}

    ${props =>
        props.el === 'h3' &&
        css`
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
        `}

    ${props =>
        props.el === 'h4' &&
        css`
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
        `}

    ${props =>
        props.el === 'subtitle' &&
        css`
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            color: ${({ theme }) => theme.palette.font.secondary};
        `}

    ${props =>
        props.el === 'alert' &&
        css`
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            color: ${({ theme }) => theme.palette.primary.main};
        `}

    ${props =>
        props.el === 'copyright' &&
        css`
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: ${({ theme }) => theme.palette.font.light};
        `}

    ${props =>
        props.el === 'link' &&
        css`
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            text-decoration: underline;
        `}
`
