import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import SendoutsTypes from './sendouts-types'

export interface IReducer {
    type: SendoutsTypes
    loading?: boolean
    sendouts?: ISendouts
    errors?: Array<IError>
    fee?: ISendOutsFee
    statuses?: ISendoutStatuses
    id?: string
}

export interface IState {
    sendouts?: ISendouts
    fee?: Partial<ISendOutsFee>
    statuses?: ISendoutStatuses
    loading: { ADD_SENDOUT: Boolean; GET_SENDOUTS: Boolean; GET_SENDOUTS_FEE: Boolean; CANCEL_SENDOUT: boolean }
    success: { ADD_SENDOUT: Boolean; GET_SENDOUTS: Boolean; GET_SENDOUTS_FEE: Boolean; CANCEL_SENDOUT: boolean }
    error?: { ADD_SENDOUT?: Array<IError>; GET_SENDOUTS?: Array<IError>; GET_SENDOUTS_FEE?: Array<IError>; CANCEL_SENDOUT?: Array<IError> }
}

const initialState = {
    sendouts: { count: 0, items: [] },
    fee: {},
    statuses: [],
    loading: { ADD_SENDOUT: false, GET_SENDOUTS: false, GET_SENDOUTS_FEE: false, CANCEL_SENDOUT: false },
    success: { ADD_SENDOUT: false, GET_SENDOUTS: false, GET_SENDOUTS_FEE: false, CANCEL_SENDOUT: false },
    error: { ADD_SENDOUT: [], GET_SENDOUTS: [], GET_SENDOUTS_FEE: [], CANCEL_SENDOUT: [] },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case SendoutsTypes.START_ADD_SENDOUT:
            return {
                ...state,
                loading: { ...state.loading, ADD_SENDOUT: true },
                error: { ...state.error, ADD_SENDOUT: [] },
            }
        case SendoutsTypes.SUCCESS_ADD_SENDOUT:
            return {
                ...state,
                loading: { ...state.loading, ADD_SENDOUT: false },
                success: { ...state.loading, ADD_SENDOUT: true },
                error: { ...state.error, ADD_SENDOUT: [] },
            }
        case SendoutsTypes.FINISH_ADD_SENDOUT:
            return {
                ...state,
                loading: { ...state.loading, ADD_SENDOUT: false },
                success: { ...state.loading, ADD_SENDOUT: false },
                error: { ...state.error, ADD_SENDOUT: [] },
            }
        case SendoutsTypes.ERROR_ADD_SENDOUT:
            return {
                ...state,
                loading: { ...state.loading, ADD_SENDOUT: false },
                error: { ...state.error, ADD_SENDOUT: action.errors },
            }
        case SendoutsTypes.START_GET_SENDOUTS:
            return {
                ...state,
                loading: { ...state.loading, GET_SENDOUTS: true },
                error: { ...state.error, GET_SENDOUTS: [] },
            }
        case SendoutsTypes.SUCCESS_GET_SENDOUTS:
            return {
                ...state,
                sendouts: action.sendouts,
                loading: { ...state.loading, GET_SENDOUTS: false },
                success: { ...state.loading, GET_SENDOUTS: true },
                error: { ...state.error, GET_SENDOUTS: [] },
            }
        case SendoutsTypes.FINISH_GET_SENDOUTS:
            return {
                ...state,
                sendouts: { count: 0, items: [] },
                loading: { ...state.loading, GET_SENDOUTS: false },
                success: { ...state.loading, GET_SENDOUTS: false },
                error: { ...state.error, GET_SENDOUTS: [] },
            }
        case SendoutsTypes.ERROR_GET_SENDOUTS:
            return {
                ...state,
                loading: { ...state.loading, GET_SENDOUTS: false },
                error: { ...state.error, GET_SENDOUTS: action.errors },
            }
        case SendoutsTypes.START_GET_SENDOUTS_FEE:
            return {
                ...state,
                loading: { ...state.loading, GET_SENDOUTS_FEE: true },
                error: { ...state.error, GET_SENDOUTS_FEE: [] },
            }
        case SendoutsTypes.SUCCESS_GET_SENDOUTS_FEE:
            return {
                ...state,
                fee: action.fee,
                loading: { ...state.loading, GET_SENDOUTS_FEE: false },
                success: { ...state.loading, GET_SENDOUTS_FEE: true },
                error: { ...state.error, GET_SENDOUTS_FEE: [] },
            }
        case SendoutsTypes.FINISH_GET_SENDOUTS_FEE:
            return {
                ...state,
                fee: {},
                loading: { ...state.loading, GET_SENDOUTS_FEE: false },
                success: { ...state.loading, GET_SENDOUTS_FEE: false },
                error: { ...state.error, GET_SENDOUTS_FEE: [] },
            }
        case SendoutsTypes.ERROR_GET_SENDOUTS_FEE:
            return {
                ...state,
                loading: { ...state.loading, GET_SENDOUTS_FEE: false },
                error: { ...state.error, GET_SENDOUTS_FEE: action.errors },
            }
        case SendoutsTypes.START_GET_SENDOUT_STATUSES:
            return {
                ...state,
                loading: { ...state.loading, GET_SENDOUT_STATUSES: true },
                error: { ...state.error, GET_SENDOUT_STATUSES: [] },
            }
        case SendoutsTypes.SUCCESS_GET_SENDOUT_STATUSES:
            return {
                ...state,
                statuses: action.statuses,
                loading: { ...state.loading, GET_SENDOUT_STATUSES: false },
                success: { ...state.loading, GET_SENDOUT_STATUSES: true },
                error: { ...state.error, GET_SENDOUT_STATUSES: [] },
            }
        case SendoutsTypes.FINISH_GET_SENDOUT_STATUSES:
            return {
                ...state,
                statuses: [],
                loading: { ...state.loading, GET_SENDOUT_STATUSES: false },
                success: { ...state.loading, GET_SENDOUT_STATUSES: false },
                error: { ...state.error, GET_SENDOUT_STATUSES: [] },
            }
        case SendoutsTypes.ERROR_GET_SENDOUT_STATUSES:
            return {
                ...state,
                loading: { ...state.loading, GET_SENDOUT_STATUSES: false },
                error: { ...state.error, GET_SENDOUT_STATUSES: action.errors },
            }
        case SendoutsTypes.START_CANCEL_SENDOUT:
            return {
                ...state,
                loading: { ...state.loading, CANCEL_SENDOUT: true },
                error: { ...state.error, CANCEL_SENDOUT: [] },
            }
        case SendoutsTypes.SUCCESS_CANCEL_SENDOUT:
            return {
                ...state,
                sendouts: sendOutCancel(state.sendouts, action.id),
                loading: { ...state.loading, CANCEL_SENDOUT: false },
                success: { ...state.loading, CANCEL_SENDOUT: true },
                error: { ...state.error, CANCEL_SENDOUT: [] },
            }
        case SendoutsTypes.FINISH_CANCEL_SENDOUT:
            return {
                ...state,
                loading: { ...state.loading, CANCEL_SENDOUT: false },
                success: { ...state.loading, CANCEL_SENDOUT: false },
                error: { ...state.error, CANCEL_SENDOUT: [] },
            }
        case SendoutsTypes.ERROR_CANCEL_SENDOUT:
            return {
                ...state,
                loading: { ...state.loading, CANCEL_SENDOUT: false },
                error: { ...state.error, CANCEL_SENDOUT: action.errors },
            }
        default:
            return state
    }
}

const sendOutCancel = (sendouts?: ISendouts, id?: string) => {
    const sendOutValues: ISendouts = JSON.parse(JSON.stringify(sendouts))
    const { items } = { ...sendOutValues }
    const item = items.find(value => value.id === id)
    if (item) item.status = 'Canceled'
    return sendOutValues
}

export const SendoutsContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const SendoutsContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <SendoutsContext.Provider value={[state, dispatch]}>{props.children}</SendoutsContext.Provider>
}
