import { FC, ReactElement } from 'react'
import { Root } from './styles'

interface IProps {
    component: ReactElement
    cardShadow?: boolean
    cardPadding?: number
}

const TabContent: FC<IProps> = props => {
    return (
        <Root cardShadow={props.cardShadow} cardPadding={props.cardPadding}>
            {props.component}
        </Root>
    )
}

export default TabContent
