import { Button, Image, Text } from 'components/shared'
import React from 'react'
import { Column, Gutter, Space } from 'styles'

import IllustrationImg from 'assets/images/Illustration.svg'
import { Root } from './styles'
import { useHistory } from 'react-router'
import { navigationRoutes } from 'routes/Routes'
import { useTranslation } from 'react-i18next'

const OrderAddressLong = () => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Root>
            <Gutter size={40} mobile mAlign="center">
                <Image width={148} height={110} image={IllustrationImg} />
                <Column mAlign="center">
                    <Text el={'h2'} align="center">
                        {t('orderAddressLong.orderNewVirtualEUAddress')}
                    </Text>
                    <Space bottom={27} />
                    <Button width={220} onClick={() => history.push(navigationRoutes.orderAddress)}>
                        {t('orderAddressLong.orderAddress')}
                    </Button>
                </Column>
            </Gutter>
        </Root>
    )
}

export default OrderAddressLong
