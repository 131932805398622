import { Dispatch, useReducer, createContext, Reducer, FC } from 'react'
import CorrespondencesTypes from './correspondences-types'

export interface IReducer {
    correspondences?: ICorrespondencesAdmin
    type: CorrespondencesTypes
    errors?: Array<IError>
}

export interface IState {
    correspondences?: ICorrespondencesAdmin
    loading: { GET_CORRESPONDENCES: boolean; UPLOAD_CORRESPONDENCES: boolean; EDIT_CORRESPONDENCES: boolean; DELETE_CORRESPONDENCES: boolean }
    errors: { GET_CORRESPONDENCES?: Array<IError>; UPLOAD_CORRESPONDENCES?: Array<IError>; EDIT_CORRESPONDENCES?: Array<IError>; DELETE_CORRESPONDENCES?: Array<IError> }
    success: { GET_CORRESPONDENCES: boolean; UPLOAD_CORRESPONDENCES: boolean; EDIT_CORRESPONDENCES: boolean; DELETE_CORRESPONDENCES: boolean }
}

const initialState = {
    correspondences: { count: 0, items: [] },
    loading: { GET_CORRESPONDENCES: false, UPLOAD_CORRESPONDENCES: false, EDIT_CORRESPONDENCES: false, DELETE_CORRESPONDENCES: false },
    errors: { GET_CORRESPONDENCES: [], UPLOAD_CORRESPONDENCES: [], EDIT_CORRESPONDENCES: [], DELETE_CORRESPONDENCES: [] },
    success: { GET_CORRESPONDENCES: false, UPLOAD_CORRESPONDENCES: false, EDIT_CORRESPONDENCES: false, DELETE_CORRESPONDENCES: false },
}

const reducer: Reducer<IState, IReducer> = (state, action) => {
    switch (action.type) {
        case CorrespondencesTypes.START_GET_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES: true },
            }
        case CorrespondencesTypes.SUCCESS_GET_CORRESPONDENCES:
            return {
                ...state,
                correspondences: action.correspondences,
                loading: { ...state.loading, GET_CORRESPONDENCES: false },
                success: { ...state.success, GET_CORRESPONDENCES: true },
            }
        case CorrespondencesTypes.FINISH_GET_CORRESPONDENCES:
            return {
                ...state,
                correspondences: { count: 0, items: [] },
                loading: { ...state.loading, GET_CORRESPONDENCES: false },
                errors: { ...state.errors, GET_CORRESPONDENCES: [] },
                success: { ...state.success, GET_CORRESPONDENCES: false },
            }
        case CorrespondencesTypes.ERROR_GET_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, GET_CORRESPONDENCES: false },
                errors: { ...state.errors, GET_CORRESPONDENCES: action.errors },
                success: { ...state.success, GET_CORRESPONDENCES: false },
            }
        case CorrespondencesTypes.START_UPLOAD_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, UPLOAD_CORRESPONDENCES: true },
            }
        case CorrespondencesTypes.SUCCESS_UPLOAD_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, UPLOAD_CORRESPONDENCES: false },
                success: { ...state.success, UPLOAD_CORRESPONDENCES: true },
            }
        case CorrespondencesTypes.FINISH_UPLOAD_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, UPLOAD_CORRESPONDENCES: false },
                errors: { ...state.errors, UPLOAD_CORRESPONDENCES: [] },
                success: { ...state.success, UPLOAD_CORRESPONDENCES: false },
            }
        case CorrespondencesTypes.ERROR_UPLOAD_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, UPLOAD_CORRESPONDENCES: false },
                errors: { ...state.errors, UPLOAD_CORRESPONDENCES: action.errors },
                success: { ...state.success, UPLOAD_CORRESPONDENCES: false },
            }
        case CorrespondencesTypes.START_EDIT_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, EDIT_CORRESPONDENCES: true },
            }
        case CorrespondencesTypes.SUCCESS_EDIT_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, EDIT_CORRESPONDENCES: false },
                success: { ...state.success, EDIT_CORRESPONDENCES: true },
            }
        case CorrespondencesTypes.FINISH_EDIT_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, EDIT_CORRESPONDENCES: false },
                errors: { ...state.errors, EDIT_CORRESPONDENCES: [] },
                success: { ...state.success, EDIT_CORRESPONDENCES: false },
            }
        case CorrespondencesTypes.ERROR_EDIT_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, EDIT_CORRESPONDENCES: false },
                errors: { ...state.errors, EDIT_CORRESPONDENCES: action.errors },
                success: { ...state.success, EDIT_CORRESPONDENCES: false },
            }
        case CorrespondencesTypes.START_DELETE_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, DELETE_CORRESPONDENCES: true },
            }
        case CorrespondencesTypes.SUCCESS_DELETE_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, DELETE_CORRESPONDENCES: false },
                success: { ...state.success, DELETE_CORRESPONDENCES: true },
            }
        case CorrespondencesTypes.ERROR_DELETE_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, DELETE_CORRESPONDENCES: false },
                errors: { ...state.errors, DELETE_CORRESPONDENCES: [] },
                success: { ...state.success, DELETE_CORRESPONDENCES: false },
            }
        case CorrespondencesTypes.FINISH_DELETE_CORRESPONDENCES:
            return {
                ...state,
                loading: { ...state.loading, DELETE_CORRESPONDENCES: false },
                errors: { ...state.errors, DELETE_CORRESPONDENCES: action.errors },
                success: { ...state.success, DELETE_CORRESPONDENCES: false },
            }
        default:
            return state
    }
}

export const AdminCorrespondencesContext = createContext<[IState, Dispatch<IReducer>]>([initialState, () => {}])

export const AdminCorrespondencesContextProvider: FC = props => {
    const [state, dispatch] = useReducer(reducer, initialState)

    // console.log('state', state)

    return <AdminCorrespondencesContext.Provider value={[state, dispatch]}>{props.children}</AdminCorrespondencesContext.Provider>
}
