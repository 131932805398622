import DownloadTypes from './download-types'

export const startFileDownload = () => ({
    type: DownloadTypes.START_FILE_DOWNLOAD,
})

export const successFileDownload = () => ({
    type: DownloadTypes.SUCCESS_FILE_DOWNLOAD,
})

export const errorFileDownload = () => ({
    type: DownloadTypes.ERROR_FILE_DOWNLOAD,
})

export const finishFileDownload = () => ({
    type: DownloadTypes.FINISH_FILE_DOWNLOAD,
})
