import { Card, ErrorAlert, TableWithHorizontalDisplay } from 'components/shared'
import { HeaderFooter } from 'layouts'
import React, { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { VerifyDocumentContext } from 'reducers/verifyDocument'
import { Row, Space } from 'styles'
import { themeProperties } from 'theme'
import { combineArrays } from 'utils'
import { flatterItems, startVerify } from './Verify-state'
import { Text } from 'components/shared'

interface IVerifyParams {
    id: string
}

const Verify = () => {
    const [state, dispatch] = useContext(VerifyDocumentContext)
    let { id } = useParams<IVerifyParams>()

    useEffect(() => {
        startVerify(dispatch, id)
    }, [dispatch, id])

    const items = useMemo(() => flatterItems(state.verifyDocument), [state.verifyDocument])

    const determineError = state.errors && (
        <Text el="h4" align="center" color={themeProperties.palette.alert.red}>
            <Space bottom={20} />
            <ErrorAlert errors={combineArrays(state.errors)} />
        </Text>
    )

    return (
        <HeaderFooter loading={state.loading.GET_INFO}>
            <Row align="center" content="center">
                <Card padding={15} width={700} content="center">
                    <Space bottom={30} />
                    <TableWithHorizontalDisplay title={'Info'} items={items} />
                    <Space bottom={30} />
                </Card>
            </Row>
            {determineError}
        </HeaderFooter>
    )
}

export default Verify
