import { Dispatch } from 'react'
import * as sendoutsAdmin from 'reducers/admin/sendouts'
import { UserSubscriptionsAdmin, SendOutsAdmin } from 'services'
import documentCircleImg from 'assets/images/documentCircle.svg'
import { dateAndTimeToLocaleString } from 'utils'

export const startGetSendouts = async (dispatch: Dispatch<sendoutsAdmin.IReducer>, id?: string, query?: string) => {
    try {
        dispatch(sendoutsAdmin.startGetSendoutsAdmin())
        const { data }: { data: Array<ISendOutAdmin> } = await UserSubscriptionsAdmin.sendOuts(id, query)
        dispatch(sendoutsAdmin.successGetSendoutsAdmin(data))
    } catch (e) {
        dispatch(sendoutsAdmin.errorGetSendoutsAdmin())
    }
}
export const startMakeAsSend = async (dispatch: Dispatch<sendoutsAdmin.IReducer>, sendOut: ISendOutAdmin) => {
    try {
        dispatch(sendoutsAdmin.startMakeAsSend())
        await SendOutsAdmin.sendSendout(sendOut.id, sendOut)
        dispatch(sendoutsAdmin.successMakeAsSend(sendOut.id))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(sendoutsAdmin.errorMakeAsSend(errors))
    }
}

export const completeSendOut = (sendOut: ISendOutAdmin): ISendOutAdmin => {
    const newSendOut = { ...sendOut }
    return {
        ...newSendOut,
        status: 'Completed',
    }
}

export const parseSendouts = (data: Array<ISendOutAdmin> = [], header: Array<string>): ITableData => {
    return {
        header,
        body: body(data),
    }
}

const body = (data: Array<ISendOutAdmin> = []): Array<ITableDataBody<ISendOutAdmin>> => {
    return data.reduce((acc, cur, i) => {
        return [
            ...acc,
            {
                id: cur.id,
                files: cur.files,
                status: cur.status,
                originalData: cur,
                firstTableData: {
                    image: documentCircleImg,
                    top: cur.displayName,
                    bottom: dateAndTimeToLocaleString(cur?.createdAt),
                },
                mainData: [cur.receiverName, `${cur?.address.street} ${cur?.address.countryCode} ${cur?.address.city} ${cur?.address.countryCode}`],
                lastTableData: [
                    { type: 'status', value: cur.status },
                    { type: 'buttonWithColorClick', value: 'Mark as send', hide: cur.status !== 'InProgress' },
                ],
            },
        ]
    }, [] as Array<ITableDataBody<ISendOutAdmin>>)
}
