import React, { ElementType, FC, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { SignInContext } from 'reducers/signIn'

interface IProps {
    Component: ElementType
    path: string
    exact?: boolean
}

export const RouteHandler: FC<IProps> = ({ Component, ...props }) => {
    const [state] = useContext(SignInContext)

    return <Route {...props} render={props => (state.signedIn ? <Component {...props} /> : <Redirect to="/sign-in" />)} />
}

export const RouteAdminHandler: FC<IProps> = ({ Component, ...props }) => {
    const [state] = useContext(SignInContext)

    return <Route {...props} render={props => (state.signedIn && state.isAdmin ? <Component {...props} /> : <Redirect to="/sign-in" />)} />
}

export const RouteLandingHandler: FC<IProps> = ({ Component, ...props }) => {
    const [state] = useContext(SignInContext)

    return <Route {...props} render={props => (state.signedIn ? <Redirect to="/user/home" /> : <Component {...props} />)} />
}

export const MagicHandler: FC<IProps> = ({ Component, ...props }) => {
    const [state] = useContext(SignInContext)

    return <Route {...props} render={props => (state.signedIn ? <Component {...props} /> : <Component {...props} />)} />
}
