import { MainTabs } from 'components/MainTabs'
import { adminSubscriptionsTabComponents } from 'components/MainTabs/AdminSubscriptionTabComponents'
import { Card, Text } from 'components/shared'
import { HeaderCenter } from 'layouts'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { AdminSubscriptionsContext } from 'reducers/admin/subscriptions'
import { navigationRoutes } from 'routes/Routes'
import { Space } from 'styles'
import { localStorageHelper } from 'utils'

const Subscription = () => {
    const { t } = useTranslation()
    const location = useLocation<{ subscriptionId: string }>()
    const [state] = useContext(AdminSubscriptionsContext)

    useEffect(() => {
        !!location.state?.subscriptionId && localStorageHelper('subscriptionId', location.state?.subscriptionId)
    }, [location, location.state?.subscriptionId])

    return (
        <HeaderCenter loading={state.loading?.GET_SUBSCRIPTIONS} goBackRoute={navigationRoutes.admin.addresses}>
            <Space bottom={50} />
            <Card padding={30} mPadding={10}>
                <Space bottom={30} />
                <Text el={'h2'}>{state.subscriptions?.subscriptionName || t('subscription.Subscription')}</Text>
                <Text el={'subtitle'}>{location.state?.subscriptionId || localStorageHelper('subscriptionId')}</Text>
                <Space bottom={30} />
                <MainTabs showTabs={true} tabComponents={adminSubscriptionsTabComponents} page={'adminSubscription'} options={{ underscore: true }} />
            </Card>
        </HeaderCenter>
    )
}

export default Subscription
