import { LoadMoreButton, Progress, Table, TableFilters } from 'components/shared'
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { Space } from 'styles'
import * as payments from 'reducers/payments'
import { parsePayments, startCancelPayment, startGetPayments, startGetPaymentsBalances, startGetPaymentsStatuses } from './Payments-state'
import { makeQueryParamsString } from 'utils'
import { VirtualAddressesContext } from 'reducers/virtualAddresses'
import { getSubscriptions } from '../Home/Home-state'
import { downloadFile } from 'components/common'
import { DownloadContext } from 'reducers/download'
import { PayNow } from 'components/promos'
import PayNowModal from 'components/Forms/PayNow/PayNow-modal'
import { useTranslation } from 'react-i18next'

const LIMIT = 10

const Payments: FC = () => {
    const { t } = useTranslation()
    const [paymentsContextState, paymentsContextDispatch] = useContext(payments.PaymentsContext)
    const [virtualAddressesState, virtualAddressesDispatch] = useContext(VirtualAddressesContext)
    const [downloadState, downloadDispatch] = useContext(DownloadContext)
    const filterResults = useRef<Object>()
    const filterLimit = useRef<number>(LIMIT)
    const paymentBalance = paymentsContextState.paymentsBalances?.[0]

    useEffect(() => {
        const queries = makeQueryParamsString({ limit: filterLimit.current })
        startGetPayments(paymentsContextDispatch, queries)
        startGetPaymentsStatuses(paymentsContextDispatch)
        startGetPaymentsBalances(paymentsContextDispatch)
        getSubscriptions(virtualAddressesDispatch)

        return () => {
            paymentsContextDispatch(payments.finishGetPayments())
        }
    }, [paymentsContextDispatch, virtualAddressesDispatch])

    const items: ITableData = useMemo(
        () =>
            parsePayments(paymentsContextState.payments?.items, [
                t('tabComponentsPayments.Invoices'),
                t('tabComponentsPayments.Address'),
                t('tabComponentsPayments.Amount'),
                t('tabComponentsPayments.Status'),
                '',
            ]),
        [paymentsContextState.payments?.items, t]
    )

    const handleLoadMore = useCallback(
        query => {
            startGetPayments(paymentsContextDispatch, query)
        },
        [paymentsContextDispatch]
    )

    const handleDownloadClick = useCallback(
        (body?: ITableDataBody, type?: LastTableDataClickType) => {
            if (type === 'ONE') {
                startCancelPayment(paymentsContextDispatch, body?.id)
            } else if (type === 'TWO') {
                body?.fileId && downloadFile(downloadDispatch, body?.fileId)
            }
        },
        [downloadDispatch, paymentsContextDispatch]
    )

    const handleFilterClick = useCallback(
        (values: any) => {
            filterResults.current = values
            const queries = makeQueryParamsString({ ...values, limit: filterLimit.current })
            startGetPayments(paymentsContextDispatch, queries)
        },
        [paymentsContextDispatch]
    )

    const downloadClickActions = useMemo(
        () => ({
            action: handleDownloadClick,
            loading: !!downloadState.loading?.FILE_DOWNLOAD,
        }),
        [downloadState.loading?.FILE_DOWNLOAD, handleDownloadClick]
    )

    const tableItems = useMemo(
        () => ({
            data: items,
            loading: !!paymentsContextState.loading.GET_PAYMENTS,
        }),
        [items, paymentsContextState.loading.GET_PAYMENTS]
    )

    const filerClickActions = useMemo(
        () => ({
            action: handleFilterClick,
            loading: !!paymentsContextState.loading.GET_PAYMENTS,
        }),
        [handleFilterClick, paymentsContextState.loading.GET_PAYMENTS]
    )

    const filterFields = useMemo(
        () => [
            { label: t('tabComponentsPayments.Addresses'), name: 'addressId', fields: virtualAddressesState.addresses, selector: 'id' },
            { label: t('tabComponentsPayments.Status'), name: 'status', fields: paymentsContextState.paymentsStatuses, selector: 'name' },
        ],
        [paymentsContextState.paymentsStatuses, virtualAddressesState.addresses, t]
    ) as Array<IFilterTableFields>

    const balanceToPay = paymentBalance && paymentBalance.balance > 0 && (
        <>
            <Space bottom={30} />
            <PayNow payNowModal={PayNowModal} />
        </>
    )

    return (
        <div>
            {!!downloadState.loading?.FILE_DOWNLOAD && <Progress />}
            {balanceToPay}
            <Space bottom={30} />
            <TableFilters fields={filterFields} filterClick={filerClickActions} dateRange />
            <Space bottom={30} />
            <Table items={tableItems} lastDataClick={downloadClickActions} />
            <Space bottom={30} />
            <LoadMoreButton
                onClick={handleLoadMore}
                LIMIT={LIMIT}
                limit={filterLimit}
                count={paymentsContextState.payments?.count}
                loading={!!paymentsContextState.loading.GET_PAYMENTS}
                filterResults={filterResults}
            />
            <Space bottom={30} />
        </div>
    )
}

export default Payments
