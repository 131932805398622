import { Dispatch, SetStateAction } from 'react'
import * as download from 'reducers/download'
import { FilesAdmin } from 'services'

export const downloadFiles = async (dispatch: Dispatch<download.IReducer>, fileItems: Array<IFile> | undefined = [], setFiles: Dispatch<SetStateAction<Array<IBlob>>>) => {
    const files: Array<IBlob> = []
    try {
        dispatch(download.startFileDownload())
        for (const file of fileItems) {
            const { data } = await FilesAdmin.download(file.id)
            files.push(data)
        }
        setFiles(files)
        dispatch(download.successFileDownload())
    } catch (e) {
        dispatch(download.errorFileDownload())
    }
}
