export const parseAddress = (data: Array<IUserSubscription>): Array<IAddress> => {
    return data?.reduce((acc, cur) => {
        return [...acc, { id: cur.address.id, name: cur.address.name }]
    }, [] as Array<IAddress>)
}

export const parseAddressWithSubscriptionId = (data: Array<IUserSubscription>) => {
    return data?.reduce((acc, cur) => {
        return [...acc, { id: cur.id, name: cur.address.name }]
    }, [] as Array<IAddress>)
}
