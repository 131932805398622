import React, { FC } from 'react'
// import * as providers from 'reducers/providers'

const ProvidersRenderer: FC = props => {
    return (
        <>
            {/* {Object.values(providers).map(Provider => (
                <Provider></Provider>
            ))} */}
        </>
    )
}

export default ProvidersRenderer
