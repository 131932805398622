import React, { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { Gutter } from 'styles'
import { Image, Text } from 'components/shared'
import dropZoneImg from 'assets/images/dropZoneUpload.svg'
import { Root } from './styles'
import { useTranslation } from 'react-i18next'

interface IProps {
    onDrop: (acceptedFiles: File[]) => void
}

const DropZone: FC<IProps> = props => {
    const { t } = useTranslation()

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop: props.onDrop,
        multiple: false,
        maxFiles: 1,
        accept: ['.doc,', '.docx', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    })

    return (
        <Root isDragActive={isDragActive} isDragReject={isDragReject}>
            <Gutter size={20} {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Gutter size={20} align="center">
                    <Image image={dropZoneImg} width={85} height={60} minWidth={85} minHeight={60} />
                    <Text el="h4">{isDragReject ? t('dropZone.fileNotSupported') : t('dropZone.attachOrDropFilesHere')}</Text>
                </Gutter>
            </Gutter>
        </Root>
    )
}

export default DropZone
