import styled, { css } from 'styled-components'

export const Root = styled.table`
    width: 100%;
    border-spacing: 0px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary.light};
    height: 62px;

    td,
    th {
        padding: 8px 15px;
    }
    thead,
    tbody {
    }

    thead tr th {
        border-bottom: 1px solid ${({ theme }) => theme.palette.primary.light};
        font-weight: 600;
    }

    tbody tr {
        height: 50px;
    }

    tbody tr:hover {
        background-color: ${({ theme }) => theme.palette.primary.background};
    }
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: ${({ theme }) => theme.palette.font.main};
    }
`

export const Wrapper = styled.div`
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    ::-webkit-scrollbar {
        display: none;
    }
`

interface ITableRow {
    isCollapsed: boolean
}

export const TableRow = styled.tr<ITableRow>`
    ${({ isCollapsed }) =>
        isCollapsed &&
        css`
            background-color: ${({ theme }) => theme.palette.primary.background};

            td {
                border-top: 1px solid ${({ theme }) => theme.palette.primary.light};
            }
        `}
`

export const TableCollapseData = styled.div``
