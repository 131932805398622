import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { getCorrespondences, getCorrespondencesStatuses, getCorrespondencesTypes, parseCorrespondences } from './Correspondence-state'
import * as correspondences from 'reducers/correspondence'
import { LoadMoreButton, Progress, Table, TableFilters } from 'components/shared'
import { DownloadContext } from 'reducers/download'
import { VirtualAddressesContext } from 'reducers/virtualAddresses'
import { Space } from 'styles'
import { downloadFile } from './Correspondence-state'
import { getSubscriptions } from '../Home/Home-state'
import { makeQueryParamsString } from 'utils'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

const LIMIT = 10

const Correspondence = () => {
    const { t } = useTranslation()
    const location = useLocation<{ addressId: string; addressName: string }>()
    const [correspondenceState, correspondenceDispatch] = useContext(correspondences.CorrespondenceTypesContext)
    const [downloadState, downloadDispatch] = useContext(DownloadContext)
    const [virtualAddressesState, virtualAddressesDispatch] = useContext(VirtualAddressesContext)
    const filterResults = useRef<Object>()
    const filterLimit = useRef<number>(LIMIT)

    const addressId = location.state?.addressId
    const addressName = location.state?.addressName

    useEffect(() => {
        const queries = makeQueryParamsString({ limit: LIMIT, addressId })
        getCorrespondences(correspondenceDispatch, queries)
        getCorrespondencesTypes(correspondenceDispatch)
        getCorrespondencesStatuses(correspondenceDispatch)
        getSubscriptions(virtualAddressesDispatch)

        return () => {
            correspondenceDispatch(correspondences.finishGetCorrespondences())
        }
    }, [addressId, correspondenceDispatch, virtualAddressesDispatch])

    const items: ITableData = useMemo(() => parseCorrespondences(correspondenceState.correspondences?.items), [correspondenceState.correspondences?.items])

    const handleFilterClick = useCallback(
        (values: any) => {
            filterResults.current = values
            const queries = makeQueryParamsString({ ...values, limit: filterLimit.current })
            getCorrespondences(correspondenceDispatch, queries)
        },
        [correspondenceDispatch]
    )

    const handleDownloadClick = useCallback(
        (body?: ITableDataBody) => {
            downloadFile(correspondenceDispatch, downloadDispatch, body?.files, body?.id)
        },
        [correspondenceDispatch, downloadDispatch]
    )

    const handleLoadMore = useCallback(
        query => {
            getCorrespondences(correspondenceDispatch, query)
        },
        [correspondenceDispatch]
    )

    const downloadClickActions = {
        action: handleDownloadClick,
        loading: !!downloadState.loading?.FILE_DOWNLOAD,
    }

    const filterClickActions = {
        action: handleFilterClick,
        loading: !!correspondenceState.loading.GET_CORRESPONDENCES,
    }

    const tableItems = {
        data: items,
        loading: !!correspondenceState.loading.GET_CORRESPONDENCES,
    }

    const filterFields = [
        { label: t('tabComponentsCorrespondence.Addresses'), name: 'addressId', fields: virtualAddressesState.addresses, selector: 'id', value: addressName },
        { label: t('tabComponentsCorrespondence.Type'), name: 'type', fields: correspondenceState.correspondencesTypes, selector: 'name' },
        { label: t('tabComponentsCorrespondence.Status'), name: 'status', fields: correspondenceState.correspondencesStatuses, selector: 'name' },
    ] as Array<IFilterTableFields>

    return (
        <>
            {!!downloadState.loading?.FILE_DOWNLOAD && <Progress />}
            <Space bottom={30} />
            <TableFilters fields={filterFields} filterClick={filterClickActions} dateRange />
            <Space bottom={30} />
            <Table items={tableItems} lastDataClick={downloadClickActions} />
            <Space bottom={30} />
            <LoadMoreButton
                onClick={handleLoadMore}
                limit={filterLimit}
                LIMIT={LIMIT}
                count={correspondenceState.correspondences?.count}
                loading={!!correspondenceState.loading.GET_CORRESPONDENCES}
                filterResults={filterResults}
            />
            <Space bottom={30} />
        </>
    )
}

export default Correspondence
