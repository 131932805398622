import { CheckOval } from 'assets/svg/CheckOval'
import { Button, Card, ErrorAlert, Text } from 'components/shared'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Gutter, Space } from 'styles'
import { themeProperties } from 'theme'

interface IProps {
    marginTop?: boolean
    errors?: Array<IError>
    width?: number
    handleClose?: () => void
}

const FailureMessage: FC<IProps> = props => {
    const { t } = useTranslation()

    return (
        <Card padding={30} width={props.width}>
            {props.marginTop && <Space bottom={20} />}
            <Gutter size={20} content="center" align="center">
                <CheckOval width={30} height={30} color={themeProperties.palette.alert.red} />
                <Text el="h2">{t('failureMessage.Failure')}</Text>
            </Gutter>
            <Space bottom={20} />
            <Text el="subtitle" align="center">
                <ErrorAlert errors={props.errors} />
            </Text>
            <Space bottom={30} />
            <Button onClick={props.handleClose}>{t('failureMessage.Done')}</Button>
            <Space bottom={20} />
        </Card>
    )
}

export default FailureMessage
