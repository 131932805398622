import styled, { css } from 'styled-components'

interface ISpace {
    top?: number
    bottom?: number
    left?: number
    right?: number
    mTop?: number
    mBottom?: number
    mLeft?: number
    mRight?: number
}

export const Space = styled.div<ISpace>`
    ${({ top, bottom, left, right }) => css`
        ${!!top ? `margin-top:${top}px;` : ''}
        ${!!bottom ? `height:${bottom}px;` : ''}
        ${!!left ? `margin-left:${left}px;` : ''}
        ${!!right ? `margin-right:${right}px;` : ''}
    `}

    @media ${({ theme }) => theme.breakpoints.max.md} {
        ${({ top, bottom, left, right, mTop, mBottom, mLeft, mRight }) => css`
            ${!!mTop || top ? `margin-top:${mTop || top}px;` : ''}
            ${!!mBottom || bottom ? `height:${mBottom || bottom}px;` : ''}
            ${!!mLeft || left ? `margin-left:${mLeft || left}px;` : ''}
            ${!!mRight || right ? `margin-right:${mRight || right}px;` : ''}
        `}
    }
`
