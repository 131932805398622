import sendoutsTypes from './sendouts-types'

export const startGetSendoutsAdmin = () => ({
    type: sendoutsTypes.START_GET_SEND_OUTS_ADMIN,
})
export const successGetSendoutsAdmin = (data: Array<ISendOutAdmin>) => ({
    type: sendoutsTypes.SUCCESS_GET_SEND_OUTS_ADMIN,
    sendouts: data,
})
export const errorGetSendoutsAdmin = () => ({
    type: sendoutsTypes.ERROR_GET_SEND_OUTS_ADMIN,
})
export const finishGetSendoutsAdmin = () => ({
    type: sendoutsTypes.FINISH_GET_SEND_OUTS_ADMIN,
})

export const startMakeAsSend = () => ({
    type: sendoutsTypes.START_MAKE_AS_SEND,
})
export const successMakeAsSend = (id: string) => ({
    type: sendoutsTypes.SUCCESS_MAKE_AS_SEND,
    id,
})
export const errorMakeAsSend = (error: Array<IError>) => ({
    type: sendoutsTypes.ERROR_MAKE_AS_SEND,
    error,
})
export const finishMakeAsSend = () => ({
    type: sendoutsTypes.FINISH_MAKE_AS_SEND,
})
