import { Dispatch } from 'react'
import * as adminCorrespondences from 'reducers/admin/correspondences'
import * as adminSubscriptions from 'reducers/admin/subscriptions'
import * as correspondences from 'reducers/correspondence'
import { CorrespondencesAdmin, Correspondences } from 'services'

export const uploadCorrespondence = async (dispatchAdminCorrespondence: Dispatch<adminCorrespondences.IReducer>, dispatchAdminSubscriptions: Dispatch<adminSubscriptions.IReducer>, data: FormData) => {
    try {
        dispatchAdminCorrespondence(adminCorrespondences.startUploadCorrespondences())
        const res = await CorrespondencesAdmin.uploadCorrespondence(data)
        if (res?.status === 204) {
            dispatchAdminCorrespondence(adminCorrespondences.successUploadCorrespondences())
            dispatchAdminSubscriptions(adminSubscriptions.successIncreaseCorrespondenceCount())
        }
    } catch (e: any) {
        // console.log('e.response?.data?.errors', e.response?.data?.errors)
        const errors: Array<IError> = e.response?.data?.errors
        dispatchAdminCorrespondence(adminCorrespondences.errorUploadCorrespondences(errors))
    }
}

export const getCorrespondencesTypes = async (dispatch: Dispatch<correspondences.IReducer>) => {
    try {
        dispatch(correspondences.startGetCorrespondencesTypes())
        const { data }: { data: Array<ICorrespondenceTypes> } = await Correspondences.correspondencesTypes()
        dispatch(correspondences.successGetCorrespondencesTypes(data))
    } catch (e) {
        dispatch(correspondences.errorGetCorrespondencesTypes())
    }
}
