import { Dispatch } from 'react'
import * as signIn from 'reducers/signIn'
import { clearAccessTokens } from 'utils'
import { finishSignIn } from 'reducers/signIn'
import { User } from 'services'
import { navigationRoutes } from 'routes/Routes'
import { History } from 'history'

export const singOut = async (dispatch: Dispatch<signIn.IReducer>, history: History) => {
    try {
        await User.signOut()
        dispatch(finishSignIn())
        clearAccessTokens()
        history.push(navigationRoutes.signIn)
    } catch (e) {}
}
