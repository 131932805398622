import { Alert, Button, ErrorAlert, Text } from 'components/shared'
import Card from 'components/shared/Card/Card'
import { Input } from 'components/shared/Input'
import { useCallback, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { finishMagicLink, SignInContext } from 'reducers/signIn'
import { Row, Space } from 'styles'
import { themeProperties } from 'theme'
import { combineArrays } from 'utils'
import { magicLinks } from './SignInForm-state'

const SignIn = () => {
    const { t } = useTranslation()
    const [state, dispatch] = useContext(SignInContext)
    const { handleSubmit, control } = useForm({ defaultValues: { email: '' }, mode: 'onChange' })

    useEffect(
        () => () => {
            dispatch(finishMagicLink())
        },
        [dispatch]
    )

    const onSubmit = useCallback(
        data => {
            magicLinks(dispatch, data)
        },
        [dispatch]
    )

    const determineButton = (
        <>
            {state.success?.MAGIC_LINK ? (
                <Alert>{t('signInForm.weJustSentYouAMagicLogInLinkPleaseCheckYourInboxAndClickOnTheLinkToLogInToYourAccount')}.</Alert>
            ) : (
                <Button type="submit" full loading={state.loading.MAGIC_LINK} disabled={state.loading.MAGIC_LINK}>
                    {t('signInForm.logInWithEmail')}
                </Button>
            )}
        </>
    )

    const determineError = (
        <>
            <Space bottom={20} />
            <ErrorAlert errors={combineArrays(state.errors?.SIGN_IN, state.errors?.MAGIC_LINK)} />
        </>
    )

    return (
        <Card width={507} padding={30}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Space bottom={30} />
                <Text el={'h1'} align={'center'}>
                    {t('signInForm.signIn')}
                </Text>
                <Space bottom={30} />
                <Row content="center">
                    <Input name="email" type="email" label={t('signInForm.Email')} fullWidth rules={{ required: t('signInForm.emailIsRequired') }} control={control} />
                </Row>
                <Space bottom={30} />
                <Row content="center">{determineButton}</Row>
                <Text el={'h4'} align={'center'} color={themeProperties.palette.alert.red}>
                    {determineError}
                </Text>
                <Space bottom={40} />
            </form>
        </Card>
    )
}

export default SignIn
