import styled from 'styled-components'

export const Root = styled.div`
    width: 354px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${({ theme }) => theme.breakpoints.max.sm} {
        width: 300px;
    }
`
