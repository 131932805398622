import { SignInContextProvider } from 'reducers/signIn'
import { SignUpContextProvider } from 'reducers/signUp'
import { MainTabsContextProvider } from 'reducers/mainTabs'
import { VirtualAddressesContextProvider } from 'reducers/virtualAddresses'
import { UtilityBillsTypesContextProvider } from 'reducers/utilityBills'
import { Routes } from './routes'
import ProvidersRenderer from 'components/common/ProvidersRenderer'
import { DownloadContextProvider } from 'reducers/download'
import { CorrespondenceTypesContextProvider } from 'reducers/correspondence'
import { SubscriptionsContextProvider } from 'reducers/subscriptions'
import { AddressesContextProvider } from 'reducers/admin/addresses'
import { AdminSubscriptionsContextProvider } from 'reducers/admin/subscriptions'
import { AdminCorrespondencesContextProvider } from 'reducers/admin/correspondences'
import { AdminUtilityBillsContextProvider } from 'reducers/admin/utilityBills'
import { ScrollToTop } from 'components/common'
import { SendoutsContextProvider } from 'reducers/sendouts'
import { CountriesContextProvider } from 'reducers/countries'
import { AdminSendoutsContextProvider } from 'reducers/admin/sendouts'
import { PaymentsContextProvider } from 'reducers/payments'
import { PaymentsAdminContextProvider } from 'reducers/admin/payments'
import { VerifyDocumentContextProvider } from 'reducers/verifyDocument'

function App() {
    return (
        <VerifyDocumentContextProvider>
            <PaymentsAdminContextProvider>
                <PaymentsContextProvider>
                    <AdminSendoutsContextProvider>
                        <CountriesContextProvider>
                            <SendoutsContextProvider>
                                <AdminUtilityBillsContextProvider>
                                    <AdminCorrespondencesContextProvider>
                                        <AdminSubscriptionsContextProvider>
                                            <AddressesContextProvider>
                                                <SubscriptionsContextProvider>
                                                    <CorrespondenceTypesContextProvider>
                                                        <DownloadContextProvider>
                                                            <UtilityBillsTypesContextProvider>
                                                                <VirtualAddressesContextProvider>
                                                                    <MainTabsContextProvider>
                                                                        <SignInContextProvider>
                                                                            <SignUpContextProvider>
                                                                                <ProvidersRenderer />
                                                                                <ScrollToTop>
                                                                                    <Routes />
                                                                                </ScrollToTop>
                                                                            </SignUpContextProvider>
                                                                        </SignInContextProvider>
                                                                    </MainTabsContextProvider>
                                                                </VirtualAddressesContextProvider>
                                                            </UtilityBillsTypesContextProvider>
                                                        </DownloadContextProvider>
                                                    </CorrespondenceTypesContextProvider>
                                                </SubscriptionsContextProvider>
                                            </AddressesContextProvider>
                                        </AdminSubscriptionsContextProvider>
                                    </AdminCorrespondencesContextProvider>
                                </AdminUtilityBillsContextProvider>
                            </SendoutsContextProvider>
                        </CountriesContextProvider>
                    </AdminSendoutsContextProvider>
                </PaymentsContextProvider>
            </PaymentsAdminContextProvider>
        </VerifyDocumentContextProvider>
    )
}

export default App
