import { LandingSignUp, LandingBenefits, Details } from 'components/promos'
import { HeaderFooter } from 'layouts'
import { Gutter, Space } from 'styles'

import buildingsImg from 'assets/images/buildings.svg'
import notePadImg from 'assets/images/notePad.svg'
import { themeProperties } from 'theme'
import { useTranslation } from 'react-i18next'

const Landing = () => {
    const { t } = useTranslation()

    return (
        <HeaderFooter color={themeProperties.palette.primary.white}>
            <LandingSignUp />
            <Space bottom={80} />
            <LandingBenefits />
            <Space bottom={80} />
            <Gutter size={30} mobile>
                <Details mainTitle={t('Landing.companyDetails')} firstSubtitle={'UAB "Tetera'} secondSubtitle={'Company code 305706375'} image={buildingsImg} imgWidth={105} imgHeight={82} />
                <Details
                    mainTitle={t('Landing.contactUs')}
                    firstSubtitle={'Savanorių pr. 219, LT-02300 Vilnius'}
                    secondSubtitle={'uabtetera@gmail.com'}
                    image={notePadImg}
                    imgWidth={82}
                    imgHeight={80}
                />
            </Gutter>
        </HeaderFooter>
    )
}

export default Landing
