import { Alert, Button, ErrorAlert, Text } from 'components/shared'
import Card from 'components/shared/Card/Card'
import { Input } from 'components/shared/Input'
import { useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SignUpContext } from 'reducers/signUp'
import { Gutter, Row, Space } from 'styles'
import { themeProperties } from 'theme'
import { signUpUser } from './SignUpForm-state'

const SignUp = () => {
    const { t } = useTranslation()
    const [state, dispatch] = useContext(SignUpContext)
    const { handleSubmit, control } = useForm({ defaultValues: { email: '', firstName: '', lastName: '' }, mode: 'onChange' })

    const onSubmit = useCallback(
        data => {
            signUpUser(dispatch, data)
        },
        [dispatch]
    )

    const determineButton = (
        <>
            {state.success?.SIGN_UP ? (
                <Alert>{t('signUpForm.weJustSentYouAMagicSignUpLinkPleaseCheckYourInboxAndClickOnTheLinkToCreateAccount')}.</Alert>
            ) : (
                <Button full type="submit" loading={state.loading?.SIGN_UP} disabled={state.loading?.SIGN_UP}>
                    {t('signUpForm.signUpWithEmail')}
                </Button>
            )}
        </>
    )

    const determineError = (
        <>
            <Space bottom={20} />
            <ErrorAlert errors={state.errors?.SIGN_UP} />
        </>
    )

    return (
        <Card width={507} padding={30}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Space bottom={30} />
                <Text el={'h1'} align={'center'}>
                    {t('signUpForm.signUp')}
                </Text>
                <Space bottom={30} />
                <Row content="center">
                    <Gutter size={15} mobile fullWidth>
                        <Input name="firstName" type="text" fullWidth label={t('signUpForm.firstName')} rules={{ required: t('signUpForm.firstNameIsRequired') }} control={control} />
                        <Space bottom={5} />
                        <Input name="lastName" type="text" fullWidth label={t('signUpForm.lastName')} rules={{ required: t('signUpForm.lastNameIsRequired') }} control={control} />
                    </Gutter>
                </Row>
                <Space bottom={30} />
                <Row content="center">
                    <Input name="email" type="email" label={t('signUpForm.Email')} fullWidth rules={{ required: t('signUpForm.emailIsRequired') }} control={control} />
                </Row>
                <Space bottom={30} />
                <Row content="center">{determineButton}</Row>
                <Text el={'h4'} align={'center'} color={themeProperties.palette.alert.red}>
                    {determineError}
                </Text>
                <Space bottom={40} />
            </form>
        </Card>
    )
}

export default SignUp
