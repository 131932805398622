import { SubscriptionDetails } from 'components/admin/SubscriptionDetails'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { AdminSubscriptionsContext } from 'reducers/admin/subscriptions'
import { localStorageHelper } from 'utils'
import { getUserSubscriptions } from './SubscriptionOverview-state'

const SubscriptionOverview = () => {
    const location = useLocation<{ subscriptionId: string }>()
    const [state, dispatch] = useContext(AdminSubscriptionsContext)

    const subscriptionId = location.state?.subscriptionId || localStorageHelper('subscriptionId')

    useEffect(() => {
        getUserSubscriptions(dispatch, subscriptionId)
    }, [dispatch, subscriptionId])

    if (state.loading?.GET_SUBSCRIPTIONS) return <></>

    return <SubscriptionDetails {...state.subscriptions} />
}

export default SubscriptionOverview
