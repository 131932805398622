enum SendoutsTypes {
    START_ADD_SENDOUT = 'START_ADD_SENDOUT',
    SUCCESS_ADD_SENDOUT = 'SUCCESS_ADD_SENDOUT',
    FINISH_ADD_SENDOUT = 'FINISH_ADD_SENDOUT',
    ERROR_ADD_SENDOUT = 'ERROR_ADD_SENDOUT',

    START_GET_SENDOUTS = 'START_GET_SENDOUTS',
    SUCCESS_GET_SENDOUTS = 'SUCCESS_GET_SENDOUTS',
    FINISH_GET_SENDOUTS = 'FINISH_GET_SENDOUTS',
    ERROR_GET_SENDOUTS = 'ERROR_GET_SENDOUTS',

    START_GET_SENDOUTS_FEE = 'START_GET_SENDOUTS_FEE',
    SUCCESS_GET_SENDOUTS_FEE = 'SUCCESS_GET_SENDOUTS_FEE',
    FINISH_GET_SENDOUTS_FEE = 'FINISH_GET_SENDOUTS_FEE',
    ERROR_GET_SENDOUTS_FEE = 'ERROR_GET_SENDOUTS_FEE',

    START_GET_SENDOUT_STATUSES = 'START_GET_SENDOUT_STATUSES',
    SUCCESS_GET_SENDOUT_STATUSES = 'SUCCESS_GET_SENDOUT_STATUSES',
    FINISH_GET_SENDOUT_STATUSES = 'FINISH_GET_SENDOUT_STATUSES',
    ERROR_GET_SENDOUT_STATUSES = 'ERROR_GET_SENDOUT_STATUSES',

    START_CANCEL_SENDOUT = 'START_CANCEL_SENDOUT',
    SUCCESS_CANCEL_SENDOUT = 'SUCCESS_CANCEL_SENDOUT',
    FINISH_CANCEL_SENDOUT = 'FINISH_CANCEL_SENDOUT',
    ERROR_CANCEL_SENDOUT = 'ERROR_CANCEL_SENDOUT',
}
export default SendoutsTypes
