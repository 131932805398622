import { UserSubscriptions } from 'services'
import * as subscriptions from 'reducers/subscriptions'
import { Dispatch } from 'react'

export const getClientSecret = async (dispatch: Dispatch<subscriptions.IReducer>, id: string) => {
    try {
        dispatch(subscriptions.startGetClientSecret())
        const res = await UserSubscriptions.clientSecret({ subscriptionId: id })
        dispatch(subscriptions.successGetClientSecret(res?.data?.clientSecret))
    } catch (e: any) {
        const errors: Array<IError> = e.response?.data.errors
        dispatch(subscriptions.errorGetClientSecret(errors))
    }
}
