/**
 * 
 * @param values takes object and checks for it values validity
 * @returns boolean
 */
export const allFieldsFilled = (values: {}) => !!Object.values(values).every(Boolean)

/**
 * 
 * @param values takes arrays
 * @returns boolean array of passed arrays
 */
export const combineArrays = <T = any>(...arrays: Array<Array<T> | undefined>): Array<T> | undefined => {
   return arrays?.reduce((cur = [], acc = []) => [...cur, ...acc],[] as Array<T> | undefined)
}