import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { ConfirmCardPaymentData, loadStripe, PaymentIntentResult } from '@stripe/stripe-js'
import { Button, Card, CheckBoxWithTitle, ErrorAlert, InputNoValid, SuccessMessage, Text } from 'components/shared'
import { StripeTextFieldCVC, StripeTextFieldExpiry, StripeTextFieldNumber } from 'components/shared/StripeTextField/StripeTextField'
import { ChangeEvent, FC, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import * as subscriptions from 'reducers/subscriptions'
import { SubscriptionsContext } from 'reducers/subscriptions'
import { navigationRoutes } from 'routes/Routes'
import { Grid, Gutter, Row, Space } from 'styles'
import { themeProperties } from 'theme'
import { combineArrays, config } from 'utils'
import { getClientSecret } from './PayWithCard-state'

const stripePromise = loadStripe(config.stripeKey)
interface IProps {
    next?: () => void
    back?: () => void
    addresses: { addressData: Array<ISubscription>; loading: boolean }
}

const PayWithCard: FC<IProps> = props => {
    return (
        <Elements stripe={stripePromise}>
            <Form {...props} />
        </Elements>
    )
}

export default PayWithCard

const Form: FC<IProps> = props => {
    const { t } = useTranslation()
    const history = useHistory()
    const stripe = useStripe()
    const elements = useElements()
    const [subscriptionsContextState, subscriptionsContextDispatch] = useContext(SubscriptionsContext)
    const address = props.addresses.addressData[subscriptionsContextState.subscriptionCarouselIndex || 0]
    const [paymentValues, setPaymentValues] = useState<IBuySubscriptionState>({ name: '', checked: false, clientSecret: '', errors: [] })
    const [stripePayload, setStripePayload] = useState<IStripePaymentState<PaymentIntentResult>>({ startPayment: false })

    const [state, setState] = useState({
        cardNumberComplete: false,
        expiredComplete: false,
        cvcComplete: false,
        cardNumberError: '',
        expiredError: '',
        cvcError: '',
    })

    useEffect(() => {
        getClientSecret(subscriptionsContextDispatch, address.id)
        return () => {
            subscriptionsContextDispatch(subscriptions.finishGetClientSecret())
        }
    }, [address.id, subscriptionsContextDispatch])

    const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setPaymentValues({ ...paymentValues, [name]: value })
    }

    const onElementChange = useCallback(
        (field: any, errorField: any) =>
            ({ complete, error = { message: null } }: { complete: boolean; error: any }) => {
                setState({ ...state, [field]: complete, [errorField]: error.message })
            },
        [state]
    )

    const { cardNumberError, expiredError, cvcError } = state

    const onCheck = useCallback(
        (name: string, value: boolean) => {
            setPaymentValues({ ...paymentValues, [name]: value })
        },
        [paymentValues]
    )

    const handleSubmit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault()
            if (!stripe || !elements) return

            setStripePayload({ ...stripePayload, startPayment: true })
            const secret = subscriptionsContextState.secret || ''
            try {
                const payload = await stripe.confirmCardPayment(secret, {
                    payment_method: {
                        type: 'card',
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: paymentValues.name,
                        },
                    },
                } as ConfirmCardPaymentData | undefined)
                setStripePayload({ ...stripePayload, payload, startPayment: false })
            } catch (e: any) {
                console.error('stripe payload error:', e)
            }
        },
        [elements, paymentValues.name, stripe, stripePayload, subscriptionsContextState.secret]
    )

    const determineError = subscriptionsContextState.errors?.GET_CLIENT_SECRET && (
        <Text el="h4" align="center" color={themeProperties.palette.alert.red}>
            <Space bottom={20} />
            <ErrorAlert errors={combineArrays(subscriptionsContextState.errors?.GET_CLIENT_SECRET)} />
        </Text>
    )
    const determineStripeError = stripePayload.payload?.error && (
        <Text el="h4" align="center" color={themeProperties.palette.alert.red}>
            <Space bottom={20} />
            {stripePayload.payload.error.message}
        </Text>
    )

    if (!!stripePayload.payload?.paymentIntent)
        return (
            <Row content="center">
                <SuccessMessage marginTop width={570} subtitle={t('payWithCard.paymentSuccessfullyCompleted')} handleClose={() => history.push(navigationRoutes.user.home)} />
            </Row>
        )

    return (
        <Row content="center">
            <Card width={817} padding={30}>
                <form onSubmit={handleSubmit}>
                    <Space bottom={30} />
                    <Text el={'h2'}>{t('payWithCard.payWithCard')}</Text>
                    <Space bottom={20} />
                    <Text el={'subtitle'}>{t('payWithCard.enterYourCreditCardDetails')}</Text>
                    <Space bottom={30} />
                    <Grid elInRow={2} gap={30}>
                        <InputNoValid name="name" type="text" label={t('payWithCard.nameOnCard')} onChange={inputChange} placeholder={t('payWithCard.nameOnCard')} InputLabelProps={{ shrink: true }} />
                        <StripeTextFieldNumber error={Boolean(cardNumberError)} labelErrorMessage={cardNumberError} onChange={onElementChange('cardNumberComplete', 'cardNumberError')} />
                        <StripeTextFieldExpiry error={Boolean(expiredError)} labelErrorMessage={expiredError} onChange={onElementChange('expiredComplete', 'expiredError')} />
                        <StripeTextFieldCVC error={Boolean(cvcError)} labelErrorMessage={cvcError} onChange={onElementChange('cvcComplete', 'cvcError')} />
                    </Grid>
                    <Space bottom={30} />
                    <CheckBoxWithTitle
                        name="checked"
                        label={`${t('payWithCard.iAgreeToPay')} ${address.price} ${t('payWithCard.forEUVirtualAddressAndConfirmThatIHaveReadTermsOfUse')}.`}
                        onCheck={onCheck}
                    />
                    {determineError}
                    {determineStripeError}
                    <Space bottom={55} />
                    <Gutter equalChildSize size={30} mobile>
                        <Button bordered onClick={() => props.back && props.back()}>
                            {t('payWithCard.previousStep')}
                        </Button>
                        <Button type="submit" disabled={!paymentValues.checked || stripePayload.startPayment} loading={stripePayload.startPayment}>
                            {t('payWithCard.payWithCard')}
                        </Button>
                    </Gutter>
                    <Space bottom={30} />
                </form>
            </Card>
        </Row>
    )
}
