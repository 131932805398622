import { ChangeEventHandler, FC } from 'react'
import { Root } from './styles'
import { Control, useController } from 'react-hook-form'

interface IProps {
    name: string
    label?: string
    fullWidth?: boolean
    type?: string
    value?: string
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
    placeholder?: string
    InputLabelProps?: any
    control?: Control<any, object>
    rules?: any
    disabled?: boolean
}

const Input: FC<IProps> = props => {
    const { field, fieldState } = useController(props)
    const helperText = fieldState.invalid ? fieldState.error?.message : null
    return <Root {...props} {...field} error={fieldState.invalid} helperText={helperText} variant="outlined" disabled={props.disabled} />
}

export default Input
