/**
 *
 * @param value is string with space and any capital letters
 * @returns trimmed and lower cased string
 */
export const trimAndLowercase = (value: string): string => value.replace(/\s/g, '').toLowerCase() as string

/**
 *
 * @param values takes ITab
 * @param name string
 * @returns name from ITabs that matches if exists
 */
export const findNameFromTabs = <T extends ITab = any>(values: Array<T>, name: string) => values.find((value: T) => trimAndLowercase(value.name) === name)?.name

/**
 * Takes object and return query values for params data
 * @param values Object
 * @returns string
 */
export const makeQueryParamsString = (values: Object): string => {
    const availableQueriesString: string = Object.entries(values)
        .map(([key, value]) => (!!value ? `${key}=${value || ''}` : ''))
        .filter(v => v)
        .join('&')
    return `?${availableQueriesString}`
}

export const toLocaleStringWithCurrency = (value: number, currencyCode: string) => {
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}

export const camelToText = (value: string) => {
    const result = value.replace(/([A-Z])/g, ' $1')
    return result.charAt(0).toUpperCase() + result.slice(1)
}
