import { downloadFile } from 'components/common'
import { startGetPaymentsStatuses } from 'components/MainTabs/TabComponents/Payments/Payments-state'
import { LoadMoreButton, Progress, Table, TableFilters } from 'components/shared'
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentsAdminContext } from 'reducers/admin/payments'
import { DownloadContext } from 'reducers/download'
import { PaymentsContext } from 'reducers/payments'
import { Space } from 'styles'
import { localStorageHelper, makeQueryParamsString } from 'utils'
import { parsePayments, startGetPayments } from './Payments-state'

const LIMIT = 10

const Payments = () => {
    const { t } = useTranslation()
    const [paymentsAdminState, paymentsAdminDispatch] = useContext(PaymentsAdminContext)
    const [paymentsContextState, paymentsContextDispatch] = useContext(PaymentsContext)
    const [downloadState, downloadDispatch] = useContext(DownloadContext)
    const subscriptionId = localStorageHelper('subscriptionId')
    const queries = makeQueryParamsString({ limit: LIMIT })
    const filterResults = useRef<Object>()
    const filterLimit = useRef<number>(LIMIT)

    useEffect(() => {
        startGetPayments(paymentsAdminDispatch, subscriptionId, queries)
        startGetPaymentsStatuses(paymentsContextDispatch)
    }, [paymentsAdminDispatch, paymentsContextDispatch, queries, subscriptionId])

    const items: ITableData = useMemo(
        () => parsePayments(paymentsAdminState.payments?.items, [t('adminTabComponentsPayments.Invoices'), t('adminTabComponentsPayments.Amount'), t('adminTabComponentsPayments.Status'), '']),
        [paymentsAdminState.payments, t]
    )

    const handleLoadMore = useCallback(
        query => {
            startGetPayments(paymentsAdminDispatch, subscriptionId, query)
        },
        [paymentsAdminDispatch, subscriptionId]
    )

    const handleFilterClick = useCallback(
        (values: any) => {
            filterResults.current = values
            const queries = makeQueryParamsString({ ...values, limit: filterLimit.current })
            startGetPayments(paymentsAdminDispatch, subscriptionId, queries)
        },
        [paymentsAdminDispatch, subscriptionId]
    )

    const handleDownloadClick = useCallback(
        (body?: ITableDataBody) => {
            body?.fileId && downloadFile(downloadDispatch, body?.fileId)
        },
        [downloadDispatch]
    )

    const downloadClickActions = useMemo(
        () => ({
            action: handleDownloadClick,
            loading: !!downloadState.loading?.FILE_DOWNLOAD,
        }),
        [downloadState.loading?.FILE_DOWNLOAD, handleDownloadClick]
    )
    const tableItems = {
        data: items,
        loading: paymentsAdminState.loading.GET_PAYMENTS,
    }

    const filerClickActions = {
        action: handleFilterClick,
        loading: !!paymentsAdminState.loading.GET_PAYMENTS,
    }

    const filterFields = [{ label: t('adminTabComponentsPayments.Status'), name: 'status', fields: paymentsContextState.paymentsStatuses, selector: 'name' }] as Array<IFilterTableFields>

    return (
        <div>
            {!!downloadState.loading?.FILE_DOWNLOAD && <Progress />}
            <Space bottom={30} />
            <TableFilters fields={filterFields} filterClick={filerClickActions} dateRange />
            <Space bottom={30} />
            <Table items={tableItems} lastDataClick={downloadClickActions} />
            <Space bottom={30} />
            <LoadMoreButton
                onClick={handleLoadMore}
                limit={filterLimit}
                LIMIT={LIMIT}
                count={paymentsAdminState.payments?.count}
                loading={!!paymentsAdminState.loading.GET_PAYMENTS}
                filterResults={filterResults}
            />
            <Space bottom={30} />
        </div>
    )
}

export default Payments
